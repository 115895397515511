<template>
  <Layout>
    <Card class="w-full stock">
      <template slot="header">
        <div class>
          <h3 class="is-size-4 has-text-weight-bold">
            Transferir Puntos Weeare
          </h3>
        </div>
      </template>
      <template slot="content">
        <div>
          <div class="filterContainer">
            <b-field :type="{ 'is-danger': $v.name.$error }">
              <b-input
                placeholder="Nombre de la campaña"
                class="inputSearch"
                v-model="name"
              >
              </b-input>
            </b-field>
          </div>
          <section class="transfer-container mb-5">
            <div class="p-3">
              <h3 class="is-size-6 has-text-weight-semibold">
                Puntos Disponibles
              </h3>
              <div>
                <b-select
                  placeholder="Puntos"
                  v-model="pointSelected"
                  expanded
                  style="width: 180px;"
                  class="mt-3"
                >
                  <option
                    v-for="point in pointList"
                    :key="point.id"
                    :value="point"
                  >
                    <span v-if="point == pointSelected">
                      {{ point.points_available }}
                    </span>
                    <span v-else>
                      {{ point.points_available }} ({{
                        formatDate(point.expiration_date)
                      }})
                    </span>
                  </option>
                </b-select>
                <small>
                  {{ formatDate(pointSelected.expiration_date) }}
                </small>
              </div>
            </div>
            <div class="p-3">
              <h3 class="is-size-6 has-text-weight-semibold">
                Clientes seleccionados
              </h3>
              <p class="is-size-3">
                {{ totalClientsSelected }}
              </p>
            </div>
            <div class="p-3">
              <h3 class="is-size-6 has-text-weight-semibold">
                Puntos por cliente
              </h3>
              <div>
                <b-field :type="{ 'is-danger': $v.pointsPerClient.$error }">
                  <b-input
                    placeholder="Puntos"
                    v-model="pointsPerClient"
                    class="points mt-3"
                    style="width: 160px;"
                    :disabled="checkedRows.length === 0"
                  />
                  <p v-if="checkedRows.length > 0">
                    <small
                      v-if="
                        $v.pointsPerClient.$error && !$v.pointsPerClient.integer
                      "
                      class="has-text-danger is-size-7"
                    >
                      Debes ingresar un valor entero
                    </small>
                    <small v-else class="has-text-danger is-size-7">
                      Máximo disponible: {{ maxAvailable }}
                    </small>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="p-3">
              <h3 class="is-size-6 has-text-weight-semibold">
                Total de puntos a asignar
              </h3>
              <div
                class="is-flex is-justify-content-space-between is-align-items-center"
              >
                <p class="is-size-3">{{ totalPoints }}</p>
                <div>
                  <Button
                    @click.native="sendPointsToClients"
                    tag="button"
                    variant="main"
                    :disabled="
                      checkedRows.length === 0 ||
                        totalPoints === 0 ||
                        totalPoints > pointSelected.points_available
                    "
                  >
                    Transferir
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <b-tabs type="is-boxed" :animated="false" v-model="activeTab">
          <b-tab-item label="Todos los clientes" class="p-5 border">
            <div class="mb-5">
              <b-notification type="is-info" has-icon :closable="false">
                <span>
                  {{ totalClientsSelected }}
                  clientes seleccionados de un total de {{ pages.count }}
                </span>
                <br />
                <b-tooltip
                  v-if="!isAllChecked"
                  position="is-bottom"
                  label="Esto te permitirá
                    elegir todos los clientes, pudiendo excluir algunos de
                    la lista."
                >
                  <a @click="toggleSelectAll">
                    ¿Quieres seleccionar todos los clientes?
                  </a>
                </b-tooltip>
                <b-tooltip
                  v-else
                  position="is-bottom"
                  label="Esto implica que
                    tendrás que seleccionar a cada cliente individualmente."
                >
                  <a @click="toggleSelectAll">
                    ¿Quieres deseleccionar todos los clientes?
                  </a>
                </b-tooltip>
              </b-notification>
            </div>
            <div class="filterContainer">
              <b-field>
                <b-input
                  placeholder="Buscar por nombre, DNI"
                  class="inputSearch"
                  v-model="query.search"
                  type="search"
                  icon="search"
                  @keyup.native="searchInput"
                  :disabled="isAllChecked"
                >
                </b-input>
              </b-field>
            </div>
            <b-table
              :loading="isLoading"
              checkable
              :checked-rows.sync="checkedRows"
              hoverable
              striped
              :data="results"
              detail-key="id"
              :is-row-checkable="row => isRowChecked(row)"
              :custom-is-checked="(a, b) => a.id === b.id"
              @check="handleCheck"
            >
              <b-table-column field="name" label="Nombre" v-slot="props">
                <router-link
                  :to="{
                    name: 'puntos-clientes-actualizar',
                    params: { id: props.row.id }
                  }"
                  class="has-text-weight-semibold"
                  >{{ props.row.client.first_name }}</router-link
                >
              </b-table-column>
              <b-table-column
                field="last_name"
                label="Apellidos"
                v-slot="props"
              >
                <span>{{ props.row.client.last_name }}</span>
              </b-table-column>
              <b-table-column
                field="num_document"
                label="Documento"
                v-slot="props"
              >
                <span>{{ props.row.client.num_document }}</span>
              </b-table-column>
              <b-table-column
                field="points"
                label="Puntos Weeare"
                v-slot="props"
              >
                <span>{{ props.row.points }}</span>
              </b-table-column>
              <LoadingTable slot="empty" :isLoading="isLoading" />
            </b-table>
            <hr style="margin-top: 0;" />
            <Page
              :links="links"
              :pages="pages"
              v-if="pages"
              @set-link="setLink"
              @set-page="setPage"
            ></Page>
          </b-tab-item>
          <b-tab-item
            :label="`Seleccionados ${totalClientsSelected}`"
            class="p-5 border"
          >
            <b-table
              :loading="isLoading"
              hoverable
              striped
              :data="selectedResults"
              detail-key="id"
              icon-pack="fas"
              @select="handleCheckSelected"
            >
              <div class="mb-5" v-if="selectedResults.length > 0">
                <b-notification type="is-info" has-icon :closable="false">
                  <span class="pt-5">
                    Clic en un cliente para deseleccionar
                  </span>
                  <br />
                  <b-tooltip
                    v-if="isAllChecked"
                    position="is-bottom"
                    label="Esto implica que
                      tendrás que seleccionar a cada cliente individualmente."
                  >
                    <a @click="toggleSelectAll">
                      ¿Quieres deseleccionar todos los clientes?
                    </a>
                  </b-tooltip>
                </b-notification>
              </div>
              <b-table-column field="close">
                <font-awesome-icon
                  :icon="['fas', 'times']"
                  class="has-text-danger"
                />
              </b-table-column>
              <b-table-column field="name" label="Nombre" v-slot="props">
                {{ props.row.client.first_name }}
              </b-table-column>
              <b-table-column
                field="last_name"
                label="Apellidos"
                v-slot="props"
              >
                <span>{{ props.row.client.last_name }}</span>
              </b-table-column>
              <b-table-column
                field="num_document"
                label="Documento"
                v-slot="props"
              >
                <span>{{ props.row.client.num_document }}</span>
              </b-table-column>
              <b-table-column
                field="points"
                label="Puntos Weeare"
                v-slot="props"
              >
                <span>{{ props.row.points }}</span>
              </b-table-column>
              <LoadingTable slot="empty" :isLoading="isLoading" />
            </b-table>
            <hr style="margin-top: 0;" />
            <Page
              :links="selectedLinks"
              :pages="selectedPages"
              v-if="selectedPages"
              @set-link="setSelectedLink"
              @set-page="setSelectedPage"
            ></Page>
          </b-tab-item>
        </b-tabs>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import ClientPointService from "@/services/puntos/ClientPointService";
import PointService from "@/services/puntos/PointService";
import Page from "@/components/Pages";
import LoadingTable from "@/components/base/LoadingTable";
import Button from "@/components/form/Button";
import { required, numeric, minValue, integer } from "vuelidate/lib/validators";
export default {
  name: "StockTransfer",
  components: {
    Layout,
    Card,
    Page,
    LoadingTable,
    Button
  },
  data() {
    return {
      activeTab: 0,
      results: [],
      links: {},
      pages: {},
      selectedResults: [],
      selectedLinks: {},
      selectedPages: {},
      isLoading: true,
      checkedRows: [],
      checkedRowsSelected: [],
      includedRows: [],
      excludedRows: [],
      isAllChecked: false,
      pointList: [],
      pointSelected: {
        id: 0,
        points_available: 0,
        expiration_date: ""
      },
      pointsPerClient: 0,
      name: "",
      query: {
        is_active: true,
        search: null,
        page: 1
      },
      selectedQuery: {
        is_active: true,
        page: 1,
        include: [],
        exclude: []
      },
      body: {
        points_per_client: 0,
        name: "",
        description: "",
        bag_point_id: 0,
        receiver_data: [],
        count_clients: 0,
        filter_type: "default",
        exclude_receivers: []
      }
    };
  },
  validations: {
    name: { required },
    pointsPerClient: { required, numeric, minValue: minValue(1), integer }
  },
  created() {
    this.getList();
    this.getListPointBag();
  },
  computed: {
    maxAvailable() {
      if (this.checkedRows.length === 0) {
        return 0;
      }
      return (
        this.pointSelected.points_available / this.totalClientsSelected
      ).toFixed(0);
    },
    totalPoints() {
      return (this.totalClientsSelected * this.pointsPerClient || 0).toFixed(0);
    },
    totalClientsSelected() {
      return this.isAllChecked
        ? this.pages.count - this.selectedQuery.exclude.length
        : this.checkedRows.length;
    }
  },
  watch: {
    activeTab(newValue) {
      if (newValue === 1) {
        this.getListFiltered();
      }
    },
    checkedRows() {
      if (this.checkedRows.length === this.pages.count && !this.query.search) {
        this.isAllChecked = false;
        this.toggleSelectAll();
      }
    }
  },
  methods: {
    async sendPointsToClients() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Debes completar los campos requeridos"
        });
        return;
      }
      try {
        this.body.points_per_client = Number(this.pointsPerClient);
        this.body.name = this.name;
        this.body.description = this.name;
        this.body.bag_point_id = this.pointSelected.id;
        this.body.receiver_data = this.isAllChecked
          ? []
          : this.selectedQuery.include;
        this.body.count_clients = this.totalClientsSelected;
        this.body.filter_type = this.isAllChecked ? "select_all" : "default";
        this.body.exclude_receivers = this.selectedQuery.exclude;
        const response = await PointService.postTransferPoints(this.body);
        console.log(response);
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "S",
          msg: "Se transfirieron los puntos correctamente"
        });
        this.$router.push({ name: "puntos-weeare-stock" });
      } catch (error) {
        console.log(error);
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error al transferir los puntos"
        });
      }
    },
    handleCheck(rows) {
      if (!this.isAllChecked) {
        this.selectedQuery.include = [];
        rows.forEach(item => {
          this.selectedQuery.include.push(item.id);
        });
      } else {
        const lastRow = rows[rows.length - 1];
        this.selectedQuery.exclude = this.selectedQuery.exclude.filter(
          item => item !== lastRow.id
        );
      }
    },
    handleCheckSelected(row) {
      const id = row.id;
      this.selectedQuery.page = 1;
      if (this.isAllChecked) {
        this.selectedQuery.include = [];
        this.selectedQuery.exclude.push(id);
        this.getListFiltered();
      } else {
        this.selectedQuery.exclude = [];
        this.selectedQuery.include = this.selectedQuery.include.filter(
          item => item !== id
        );
        if (this.selectedQuery.include.length > 0) {
          this.getListFiltered();
        } else {
          this.selectedResults = [];
          this.selectedPages = {};
          this.selectedLinks = {};
        }
      }
      this.checkedRows = this.checkedRows.filter(item => item.id !== id);
    },
    async getListFiltered() {
      this.isLoading = true;
      try {
        if (this.checkedRows.length > 0) {
          const response = await PointService.getClientListFilter(
            this.selectedQuery
          );
          this.selectedResults = response.data.results;
          this.selectedPages = response.data.pages;
          this.selectedLinks = response.data.links;
        } else {
          this.selectedResults = [];
          this.selectedPages = {};
          this.selectedLinks = {};
        }
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(fechaOriginal) {
      if (fechaOriginal) {
        const partes = fechaOriginal.split(" ");
        const fecha = partes[0];

        const partesFecha = fecha.split("-");
        const dia = partesFecha[0];
        const mes = partesFecha[1];
        const año = partesFecha[2];
        return `vence: ${mes}/${dia}/${año}`;
      }
      return "vence: ";
    },
    async getListPointBag() {
      try {
        const response = await PointService.getPointBagList();
        this.pointList = response.data;
        this.pointSelected = this.pointList[0];
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      }
    },
    async getList() {
      this.isLoading = true;
      try {
        const response = await ClientPointService.getList(this.query);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;

        if (this.isAllChecked) {
          const newResults = this.results.filter(item => {
            return !this.selectedQuery.exclude.includes(item.id);
          });
          const newRows = newResults.filter(
            item =>
              !this.checkedRows.some(
                i =>
                  i.id === item.id &&
                  !this.selectedQuery.exclude.includes(item.id)
              )
          );
          newRows.forEach(item => {
            this.checkedRows.push(item);
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getList();
    },
    setPage(page) {
      this.query.page = page;
      this.getList();
    },
    setSelectedLink() {
      this.getListFiltered();
    },
    setSelectedPage(page) {
      this.selectedQuery.page = page;
      this.getListFiltered();
    },
    searchInput() {
      if (this.query.search !== null) {
        if (this.query.search.length >= 3) {
          this.getList();
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.getList();
        }
      }
    },
    isRowChecked(row) {
      if (this.isAllChecked) {
        return this.selectedQuery.exclude.some(i => i === row.id);
      }
      return true;
    },
    async toggleSelectAll() {
      this.isAllChecked = !this.isAllChecked;
      if (this.isAllChecked && this.query.search) {
        this.query.search = null;
        await this.getList();
      }
      this.results.forEach(item => {
        if (this.isAllChecked) {
          if (!this.checkedRows.some(i => i.id === item.id)) {
            this.checkedRows.push(item);
          }
        } else {
          this.checkedRows = [];
        }
      });
      const checkboxCabecera = document.querySelector(
        "thead .b-checkbox input[type='checkbox']"
      );
      if (checkboxCabecera.checked && this.isAllChecked) {
        checkboxCabecera.checked = false;
      }
      setTimeout(() => {
        checkboxCabecera.checked = this.isAllChecked;
      }, 0);

      this.selectedQuery.include = this.isAllChecked
        ? []
        : this.selectedQuery.include;
      this.selectedQuery.exclude = this.isAllChecked
        ? this.selectedQuery.exclude
        : [];
      this.activeTab = this.isAllChecked && this.query.page === 1 ? 1 : 0;
    }
  }
};
</script>

<style lang="scss">
.stock {
  .tab-content {
    padding: 0 !important;
    margin-top: -1px;
    .border {
      border-color: #dbdbdb;
      border-top: transparent !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
  .tabs {
    li:not(.is-active) {
      opacity: 0.5;
      background-color: #dbdbdb;
    }
  }
}
.transfer-container {
  display: grid;
  grid-template-columns: 0.5fr 0.7fr 0.5fr 1fr;
  margin-bottom: 1rem;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  & > div:not(:first-child) {
    border-left: 1px solid #dbdbdb;
  }
  .points input {
    height: 40px;
  }
}
.disabled-table {
  pointer-events: none; /* Evitar eventos de puntero */
  opacity: 0.5; /* Opacidad reducida para indicar deshabilitado */
}
.disabled {
  display: none;
}
</style>
