var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"is-flex"},[_c('Card',{staticClass:"w-full"},[_c('template',{slot:"header"},[_c('div',{},[_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v("Agregar Sitio")])])]),_c('template',{slot:"content"},[_c('section',[_c('b-tabs',{attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("General")])]},proxy:true}])},[_c('div',[_c('b-field',{attrs:{"label":"Activo"}},[_c('b-switch',{attrs:{"type":"is-success"},model:{value:(_vm.data.is_active),callback:function ($$v) {_vm.$set(_vm.data, "is_active", $$v)},expression:"data.is_active"}})],1),_c('b-field',{attrs:{"label":"Nombre","type":{ 'is-danger': _vm.$v.data.name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.name.required && _vm.$v.data.name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('b-field',{attrs:{"label":"Título SEO","type":{ 'is-danger': _vm.$v.data.meta_title.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.meta_title.required &&
                        _vm.$v.data.meta_title.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.meta_title),callback:function ($$v) {_vm.$set(_vm.data, "meta_title", $$v)},expression:"data.meta_title"}})],1),_c('b-field',{attrs:{"label":"Descripción SEO","type":{
                    'is-danger': _vm.$v.data.meta_description.$error
                  },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.meta_description.required &&
                        _vm.$v.data.meta_description.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.meta_description),callback:function ($$v) {_vm.$set(_vm.data, "meta_description", $$v)},expression:"data.meta_description"}})],1),_c('b-field',{attrs:{"label":"ID Google Analytics (Opcional)"}},[_c('b-input',{model:{value:(_vm.data.analytics),callback:function ($$v) {_vm.$set(_vm.data, "analytics", $$v)},expression:"data.analytics"}})],1),_c('b-field',{attrs:{"label":"Subdominio","type":{ 'is-danger': _vm.$v.data.subdomain.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.subdomain.required &&
                        _vm.$v.data.subdomain.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.subdomain),callback:function ($$v) {_vm.$set(_vm.data, "subdomain", $$v)},expression:"data.subdomain"}})],1)],1)]),_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("Apariencia")])]},proxy:true}])},[_c('div',[_c('b-field',{attrs:{"label":"Tema"}},[_c('b-select',{staticClass:"selectForm",attrs:{"placeholder":"Seleccione una opción","expanded":""},model:{value:(_vm.data.theme),callback:function ($$v) {_vm.$set(_vm.data, "theme", $$v)},expression:"data.theme"}},[_c('option',{attrs:{"value":"L"}},[_vm._v("Claro (Light)")]),_c('option',{attrs:{"value":"D"}},[_vm._v("Oscuro (Dark)")])])],1),_c('b-field',{attrs:{"label":"Color Corporativo"}},[_c('b-input',{staticStyle:{"width":"150px"},attrs:{"type":"color"},model:{value:(_vm.data.color),callback:function ($$v) {_vm.$set(_vm.data, "color", $$v)},expression:"data.color"}}),_c('b-input',{staticStyle:{"width":"100%"},model:{value:(_vm.data.color),callback:function ($$v) {_vm.$set(_vm.data, "color", $$v)},expression:"data.color"}})],1),_c('b-field',{attrs:{"label":"Logo","custom-class":_vm.$v.data.logo_company.$error ? 'has-text-danger' : '',"type":{ 'is-danger': _vm.$v.data.logo_company.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.logo_company.required &&
                        _vm.$v.data.logo_company.$error
                    }
                  ]}},[_c('Upload',{class:_vm.$v.data.logo_company.$error ? 'required' : '',attrs:{"image":_vm.data.logo_company},on:{"change":_vm.uploadFileLogo}})],1),_c('b-field',{attrs:{"label":"Favicon"}},[_c('Upload',{attrs:{"image":_vm.data.icon_company},on:{"change":_vm.uploadFileIcon}})],1),_c('b-field',{attrs:{"label":"Imagen Login Desktop","custom-class":_vm.$v.data.banner.$error ? 'has-text-danger' : '',"type":{ 'is-danger': _vm.$v.data.banner.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.banner.required && _vm.$v.data.banner.$error
                    }
                  ]}},[_c('Upload',{class:_vm.$v.data.banner.$error ? 'required' : '',attrs:{"image":_vm.data.banner},on:{"change":_vm.uploadFileBanner}})],1),_c('b-field',{attrs:{"label":"Imagen Login Mobile","custom-class":_vm.$v.data.banner_mobile.$error ? 'has-text-danger' : '',"type":{ 'is-danger': _vm.$v.data.banner_mobile.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.banner_mobile.required &&
                        _vm.$v.data.banner_mobile.$error
                    }
                  ]}},[_c('Upload',{class:_vm.$v.data.banner_mobile.$error ? 'required' : '',attrs:{"image":_vm.data.banner_mobile},on:{"change":_vm.uploadFileBannerMobile}})],1)],1)])],1)],1)])],2),_c('Aside',[_c('Button',{staticClass:"mb-2",attrs:{"variant":"main","spinner":_vm.loading,"block":""},nativeOn:{"click":function($event){return _vm.postPage()}}},[_vm._v(" Guardar ")]),_c('Button',{attrs:{"tag":"router-link","to":{ name: 'page_list' },"variant":"gray","block":""}},[_vm._v(" Cancelar ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }