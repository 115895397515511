import { API_CODIGO as API } from "@/api";
import axios from "axios";
class PageService {
  getList() {
    const response = axios.get(API.landing_list);
    return response;
  }

  postPage(data) {
    const response = axios.post(API.landing_add, data);
    return response;
  }

  getPageDetail(id) {
    const response = axios.get(API.landing_update(id));
    return response;
  }

  putPageDetail(id, data) {
    const response = axios.put(API.landing_update(id), data);
    return response;
  }

  deletePage(id) {
    const response = axios.delete(API.landing_delete(id));
    return response;
  }
}

const instance = new PageService();
export default instance;
