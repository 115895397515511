<template>
  <Layout>
    <Card class="w-full">
      <template slot="content">
        <div class="dashboard">
          <div class="graphics">
            <GraphicOrder />
          </div>
          <div class="graphics">
            <GraphicCategory />
          </div>
          <div class="graphics">
            <GraphicCompany />
          </div>
          <div class="graphics">
            <GraphicCodes />
          </div>
        </div>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import GraphicOrder from "@/components/dashboard/GraphicOrder.vue";
import GraphicCategory from "@/components/dashboard/GraphicCategory.vue";
import GraphicCompany from "@/components/dashboard/GraphicCompany.vue";
import GraphicCodes from "@/components/dashboard/GraphicCodes.vue";
export default {
  name: "Home",
  components: {
    Layout,
    Card,
    GraphicOrder,
    GraphicCategory,
    GraphicCompany,
    GraphicCodes
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.dashboard {
  width: 100%;
}
</style>
