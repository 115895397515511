<template>
  <div class="card">
    <div class="card-header">
      <slot name="header"></slot>
    </div>
    <div class="card-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>

<style lang="scss">
.card {
  box-shadow: none;

  &__title {
    font-size: 28px;
    margin: 0 !important;
  }
  &-header {
    padding: 40px 40px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none !important;
  }
  &-content {
    padding: 0px 40px 30px;
  }
}
</style>
