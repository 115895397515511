const API_BASE_CODIGO = "/regalos/api";
export const API_CODIGO = {
  login: "/api/login-univeral/",
  company_config: `${API_BASE_CODIGO}/company/`,
  code_list: `${API_BASE_CODIGO}/group-code/`,
  code_list_simple: `${API_BASE_CODIGO}/group-code-simple/`,
  code_download: id => `${API_BASE_CODIGO}/group-code/${id}/download`,
  code_detail: id => `${API_BASE_CODIGO}/group-code/${id}/`,
  code_detail_codes: id => `${API_BASE_CODIGO}/group-code/${id}/codes/`,
  code_detail_products: id => `${API_BASE_CODIGO}/group-code/${id}/products/`,
  customer_list: `${API_BASE_CODIGO}/customers/`,
  customer_add: id => `${API_BASE_CODIGO}/codes/${id}/add-customer/`,
  customer_products: id => `${API_BASE_CODIGO}/customers/${id}/products/`,
  customer_codes: id => `${API_BASE_CODIGO}/customers/${id}/codes/`,
  graphic_order: `${API_BASE_CODIGO}/graphic/orders/`,
  graphic_category: `${API_BASE_CODIGO}/graphic/best/category/`,
  graphic_company: `${API_BASE_CODIGO}/graphic/best/company-service/`,
  graphic_codes: `${API_BASE_CODIGO}/graphic/codes/total/`,
  order_list: `${API_BASE_CODIGO}/orders/`,
  order_detail: id => `${API_BASE_CODIGO}/orders/${id}`,
  order_excel: `${API_BASE_CODIGO}/orders/download-csv/`,
  landing_list: `${API_BASE_CODIGO}/landing/list/`,
  landing_add: `${API_BASE_CODIGO}/landing/create/`,
  landing_update: id => `${API_BASE_CODIGO}/landing/${id}/update/`,
  landing_delete: id => `${API_BASE_CODIGO}/landing/${id}/delete/`,
  message_list: `${API_BASE_CODIGO}/message/`,
  message_add: `${API_BASE_CODIGO}/message/add/`,
  message_update: id =>
    `${API_BASE_CODIGO}/template_message_update/message/${id}/update/`,
  message_delete: id =>
    `${API_BASE_CODIGO}/template_message_update/message/${id}/delete/`,
  code_message_list: `${API_BASE_CODIGO}/group-code/?page_size=5`,
  template_message_list: `${API_BASE_CODIGO}/template-message/?page_size=4`,
  template_message_add: `${API_BASE_CODIGO}/template-message/add/`,
  template_message_update: id =>
    `${API_BASE_CODIGO}/template_message_update/template-message/${id}/update/`,
  template_message_delete: id =>
    `${API_BASE_CODIGO}/template_message_update/template-message/${id}/delete/`
};

const API_BASE_VALIDADOR = "/api/validate";
export const API_VALIDADOR = {
  local_list: `${API_BASE_VALIDADOR}/locals-select/`,
  locals: `${API_BASE_VALIDADOR}/locals/`,
  billing_list: `${API_BASE_VALIDADOR}/billing/`,
  billing_pdf: `${API_BASE_VALIDADOR}/billing-pdf/`,
  billing_update: id => `${API_BASE_VALIDADOR}/billing/${id}/invoice/`,
  order_list: `${API_BASE_VALIDADOR}/orders/`,
  order_products: `${API_BASE_VALIDADOR}/orders-products/`,
  delivery_list: `${API_BASE_VALIDADOR}/orders/delivery/`,
  delivery_detail: id => `${API_BASE_VALIDADOR}/orders/delivery/${id}/`,
  delivery_change_status: id =>
    `${API_BASE_VALIDADOR}/orders/delivery/${id}/change-status/`,
  delivery_cancelled: id =>
    `${API_BASE_VALIDADOR}/orders/delivery/${id}/cancelled/`,
  delivery_pdf: `${API_BASE_VALIDADOR}/order/delivery-shipping-pdf/`,
  delivery_csv: `${API_BASE_VALIDADOR}/order/delivery-shipping-csv/`,
  company_provider_list: `${API_BASE_VALIDADOR}/company-provider-list/`,
  validate_num_document: `${API_BASE_VALIDADOR}/validate-num-document/`,
  registrar_usuario: `${API_BASE_VALIDADOR}/registrar-usuario/`,
  products: `${API_BASE_VALIDADOR}/products/`,
  validate_type_product: `${API_BASE_VALIDADOR}/validate-type-product/`,
  calculate: `${API_BASE_VALIDADOR}/calculate/`,
  validate_mount: `${API_BASE_VALIDADOR}/validate-mount/`
};

const API_BASE_ADMIN = "/api/admin-provider";
export const API_ADMIN = {
  clients: `${API_BASE_ADMIN}/clients/`,
  client_detail: id => `${API_BASE_ADMIN}/clients/${id}/`,
  company: `${API_BASE_ADMIN}/company/`
};

const API_BASE_PUNTOS = "/api/admin-provider";
export const API_PUNTOS = {
  clients_point: `${API_BASE_PUNTOS}/point/clients/`,
  clients_point_transfer: `${API_BASE_PUNTOS}/clients/point-transfer/`,
  clients_point_validate_document: `${API_BASE_PUNTOS}/point/clients/validate-document/`,
  company_group: `${API_BASE_PUNTOS}/company-group/`,
  company_group_logo: `${API_BASE_PUNTOS}/company-group/logo`,
  point_company: `${API_BASE_PUNTOS}/point/company/`,
  point_bag_list: `${API_BASE_PUNTOS}/point/movements/points/available`,
  point_movements: `${API_BASE_PUNTOS}/point/movements/points`,
  point_transfer: `${API_BASE_PUNTOS}/point/movements/points/transfer/`,
  order_points: `${API_BASE_PUNTOS}/order/points/`,
  landing_list: `${API_BASE_PUNTOS}/landing/list/`,
  landing_add: `${API_BASE_PUNTOS}/landing/create/`,
  landing_update: id => `${API_BASE_PUNTOS}/landing/${id}/update/`,
  landing_delete: id => `${API_BASE_PUNTOS}/landing/${id}/delete/`
};
