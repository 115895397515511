import { API_CODIGO as API } from "@/api";
import axios from "axios";
class MessageService {
  getList(params) {
    const response = axios.get(API.message_list, {
      params
    });
    return response;
  }

  postNew(data) {
    const response = axios.post(API.message_add, data);
    return response;
  }

  getListTemplate(params) {
    const response = axios.get(API.template_message_list, {
      params
    });
    return response;
  }

  getListCodes(params) {
    const response = axios.get(API.code_message_list, {
      params
    });
    return response;
  }

  postNewTemplate(data) {
    const response = axios.post(API.template_message_add, data);
    return response;
  }

  updateTemplate(id, data) {
    const response = axios.put(API.template_message_update(id), data);
    return response;
  }

  deleteTemplate(id) {
    const response = axios.delete(API.template_message_delete(id));
    return response;
  }
}

const instance = new MessageService();
export default instance;
