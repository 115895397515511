<template>
  <div class="wrapper container is-fluid">
    <SidebarMenu></SidebarMenu>
    <AlertMessage :alert="alert" v-if="alert.isAlert"></AlertMessage>
    <div class="wrapper__content" :class="{ 'is-loading': loading }">
      <HeaderMenu></HeaderMenu>
      <main class="main">
        <transition name="fade">
          <Loading v-if="loading"></Loading>
        </transition>
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SidebarMenu from "@/components/SidebarMenu";
import HeaderMenu from "@/components/HeaderMenu";
import AlertMessage from "@/components/base/AlertMessage";
import Loading from "@/components/Loading";
import { ROLES } from "@/utils/constants";

export default {
  name: "Layout",
  components: {
    SidebarMenu,
    HeaderMenu,
    AlertMessage,
    Loading
  },
  computed: {
    ...mapState("settings", ["loading", "alert"])
  },
  mounted() {
    const roles = JSON.parse(localStorage.getItem("roles"));
    this.SET_ROLES(roles);

    const currentRole = this.$localStorage.get("current_role");
    if (currentRole) {
      this.SET_CURRENT_ROLE(currentRole);
    }
    const currentSubRole = this.$localStorage.get("current_sub_role");
    if (currentSubRole) {
      this.SET_CURRENT_SUB_ROLE(currentSubRole);
    }

    // obtener path de url
    const path = this.$route.path;
    const rolGroup = path.split("/")[1];
    const formatRoles = {
      puntos: ROLES.COMPANY_POINT,
      validador: ROLES.SERVICE_USER,
      regalos: ROLES.CLIENT_CODE
    };

    if (
      !roles ||
      roles.length === 0 ||
      !roles.includes(formatRoles[rolGroup])
    ) {
      this.$router.push({ name: "not-allowed" }).catch(() => {});
    }

    if (rolGroup === "puntos") {
      this.SET_CURRENT_ROLE(ROLES.COMPANY_GROUP);
      this.SET_CURRENT_SUB_ROLE(ROLES.COMPANY_POINT);
    } else if (rolGroup === "validador") {
      this.SET_CURRENT_ROLE(ROLES.SERVICE_USER);
    } else if (rolGroup === "regalos") {
      this.SET_CURRENT_ROLE(ROLES.COMPANY_GROUP);
      this.SET_CURRENT_SUB_ROLE(ROLES.CLIENT_CODE);
    } else {
      this.$router.push({ name: "not-allowed" }).catch(() => {});
    }
  },
  methods: {
    ...mapMutations("settings", [
      "SET_ROLES",
      "SET_CURRENT_ROLE",
      "SET_CURRENT_SUB_ROLE"
    ])
  }
};
</script>

<style lang="scss">
.wrapper {
  display: flex;
  background-color: $color-bg;
  min-height: 100vh;
  max-width: 1500px;
  padding: 0 25px 60px !important;
  &__content {
    width: 100%;
    position: relative;
    &.is-loading {
      height: calc(100vh - 80px);
      overflow: hidden;
    }
  }
}
</style>
