<template>
  <div class="">
    <b-loading :is-full-page="isFullPage" v-model="isLoading">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-spin"
      ></b-icon>
      <span>Cargando</span>
    </b-loading>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Loading",
  data() {
    return {
      isLoading: true,
      isFullPage: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.SET_LOADING(false);
    }, 1000);
  },
  methods: {
    ...mapMutations("settings", ["SET_LOADING"])
  }
};
</script>

<style lang="scss" scope>
.loading-full-page {
  .loading-overlay .loading-background {
    background: $color-bg;
  }
}
</style>
