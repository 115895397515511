<template>
  <section>
    <div class="column is-two-fifths border p-5" style="margin: 0 auto;">
      <h3 class="mb-5 has-text-weight-bold has-text-centered">
        <font-awesome-icon
          :icon="['fas', 'user-check']"
          custom-size="default"
          class="mr-1"
        />
        {{ title }}
      </h3>
      <form v-if="type_discount == 1 || type_discount == 2">
        <b-field
          label="Monto en soles S/."
          :type="{ 'is-danger': $v.mount.$error }"
          :message="[
            {
              'Requerido. Ingresa un monto válido':
                !$v.mount.required && $v.mount.$error
            },
            {
              'Incorrecto. Ingresa un monto válido':
                !$v.mount.decimal && $v.mount.$error
            }
          ]"
        >
          <b-input
            v-model="mount"
            placeholder="0"
            type="numeric"
            name="mount"
            @keyup.native="getCalculateMount"
          />
        </b-field>
        <div class="has-text-centered mt-5">
          <span>Total a pagar</span>
          <p class="has-text-weight-bold is-size-3">
            S/. {{ discount.toFixed(2) }}
          </p>
        </div>
      </form>
      <form v-else-if="type_discount == 3">
        <div class="has-text-centered mt-3">
          <b-icon icon="gift" size="is-large" />
        </div>
      </form>
      <form v-else-if="type_discount == 5">
        <div class="has-text-centered mt-3">
          <b-icon icon="cart-plus" size="is-large" />
        </div>
      </form>
      <hr />
      <div class="is-flex is-justify-content-center is-align-items-center">
        <Button :spinner="isLoading" variant="gray" @click.native="goBack">
          Regresar
        </Button>
        <Button
          :spinner="isLoading"
          variant="main"
          class="ml-2"
          style="width: 115px;"
          @click.native="postValidateMount"
        >
          Validar
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";
import ValidateService from "@/services/validador/ValidateService";
import Button from "@/components/form/Button";
export default {
  components: {
    Button
  },
  props: {
    document: String,
    option: Number,
    idProduct: Number,
    local: String
  },
  data() {
    return {
      mount: "",
      type_discount: null,
      discount: 0,
      isLoading: false,
      title: ""
    };
  },
  validations: {
    mount: { required, decimal }
  },
  created() {
    this.getValidateTypeProduct();
  },
  methods: {
    goBack() {
      this.$emit("goBack", 4);
    },
    async getValidateTypeProduct() {
      try {
        const response = await ValidateService.getValidateTypeProduct({
          product_variant_id: this.idProduct,
          num_document: this.document,
          local: this.local,
          company_id: this.option
        });
        this.type_discount = response.data.type_discount;
        this.title =
          this.type_discount === 3
            ? "¿Desea validar el regalo?"
            : this.type_discount === 5
            ? "¿Desea validar el consumo?"
            : "Ingrese el monto total de la cuenta";
        if (this.type_discount === 3) {
          this.mount = 0;
        } else if (this.type_discount === 5) {
          this.mount = response.data.mount_total;
        }
      } catch (error) {
        this.errorMessage();
        this.$emit("goBack", 4);
      }
    },
    async getCalculateMount() {
      try {
        if (!this.mount) return;
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const response = await ValidateService.getCalculateMount({
            num_document: this.document,
            mount: this.mount,
            product_variant_id: this.idProduct,
            company_id: this.option
          });
          this.discount = response.data.discount;
        }
      } catch (error) {
        this.discount = 0;
        for (let i = 0; i < error.response.data.error.name.length; i++) {
          this.errorMessage(error.response.data.error.name[i]);
        }
      }
    },
    async postValidateMount() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (
            this.discount === 0 &&
            (this.type_discount === 2 || this.type_discount === 1)
          ) {
            return;
          }
          this.isLoading = true;
          await ValidateService.postValidateMount({
            num_document: this.document,
            mount: this.mount,
            product_variant_id: this.idProduct,
            local: this.local,
            company_id: this.option
          });
          this.$emit("goBack", 6);
        }
      } catch (error) {
        this.errorMessage(error.response.data.error);
        this.isLoading = false;
      }
    },
    errorMessage(msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type: "D",
        msg
      });
    }
  }
};
</script>
