import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLocalStorage from "vue-localstorage";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import setupAxios from "./utils/interceptors";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Buefy from "buefy";
/* import "buefy/dist/buefy.css"; */
import "@/assets/styles/_main.scss";
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(fab);
library.add(fas);
Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
  defaultToastDuration: 5000
});

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://weeare-api.localhost:8000"
    : "https://weeare-api.weeare.pe";
Vue.use(VueAxios, axios);
setupAxios();

Vue.use(Vuelidate);
Vue.use(VueLocalStorage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
