export const STATUS_ORDER = {
  B: "En Factura",
  O: "Pedido",
  P: "Pagado"
};

export const ROLES = {
  SERVICE_USER: "SERVICE_USER", // Validador - PRINCIPAL
  COMPANY_GROUP: "COMPANY_GROUP", // Empresa - PRINCIPAL
  COMPANY_POINT: "COMPANY_POINT", // Empresa Grupos
  CLIENT_CODE: "CLIENT_CODE" // Regalos
};

export const TRANSACTION_TYPE = {
  RECHARGE: "R",
  TRANSFER: "T"
};

export const TRANSACTION_STATUS = {
  COMPLETED: "C",
  IN_PROGRESS: "I",
  REJECTED: "R"
};
