import { API_CODIGO as API } from "@/api";
import axios from "axios";
class CodeService {
  getList(params) {
    const response = axios.get(API.code_list, {
      params: params
    });
    return response;
  }

  getListSimple() {
    const response = axios.get(API.code_list_simple);
    return response;
  }

  getDetail(id) {
    const response = axios.get(API.code_detail(id));
    return response;
  }

  getCodes(id, params) {
    const response = axios.get(API.code_detail_codes(id), {
      params: params
    });
    return response;
  }

  getProducts(id, params) {
    const response = axios.get(API.code_detail_products(id), {
      params: params
    });
    return response;
  }

  addCustomer(id, data) {
    const response = axios.post(API.customer_add(id), {
      num_document: data.num_document,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name
    });
    return response;
  }

  downloadExcel(id) {
    const response = axios.get(API.code_download(id), { responseType: "blob" });
    return response;
  }
}

const instance = new CodeService();
export default instance;
