<template>
  <div>
    <label class="upload control is-full is-block">
      <div class="upload-draggable is-primary is-full py-2">
        <section class="upload-content">
          <div class="content has-text-centered">
            <p class="upload-help">
              <span class="icon mr-3 mt-1">
                <b-icon icon="upload" />
              </span>
              Clic para elegir {{ anotherFile }} archivo
              <span>{{ formato.split("/")[1] }}</span>
            </p>
          </div>
        </section>
      </div>
      <input
        :accept="formato"
        type="file"
        ref="fileInput"
        @change="uploadFile($event)"
      />
    </label>
    <div class="has-text-centered">
      <span v-if="name !== ''" class="has-text-weight-medium has-text-primary">
        {{ name }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "File",
  props: {
    file: String,
    formato: String
  },
  data() {
    return {
      baseFile: this.file,
      name: ""
    };
  },
  computed: {
    anotherFile() {
      return this.name ? "otro" : "un";
    }
  },
  watch: {
    file() {
      this.baseFile = this.file;
    }
  },
  methods: {
    uploadFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      this.name = files[0].name;
      if (files?.[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.baseFile = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("change", files[0]);
      }
    }
  }
};
</script>
