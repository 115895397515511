<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div class>
          <h3 class="is-size-4 has-text-weight-bold">Puntos Weeare</h3>
        </div>
      </template>
      <template slot="content">
        <div
          class="mb-6 is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-5">
            Total puntos disponibles:
            <span>0</span>
          </div>
          <Button
            tag="router-link"
            :to="{ name: 'puntos-weeare-stock-transfer' }"
            variant="main"
          >
            Transferir
          </Button>
        </div>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          detail-key="uuid"
          icon-pack="fas"
        >
          <b-table-column field="name" label="Nombre" v-slot="props">
            <span>{{ props.row.name }}</span>
          </b-table-column>
          <b-table-column field="created_date" label="Fecha" v-slot="props">
            <span>{{ props.row.created_date }}</span>
          </b-table-column>
          <b-table-column field="total_points" label="Puntos" v-slot="props">
            <span>{{ props.row.total_points }}</span>
          </b-table-column>
          <b-table-column
            field="transaction_type"
            label="Movimiento"
            v-slot="props"
          >
            <span>{{ getTransactionType(props.row.transaction_type) }}</span>
          </b-table-column>
          <b-table-column field="status" label="Estado" v-slot="props">
            <b-tag :type="getTransactionStatusClass(props.row.status)">
              {{ getTransactionStatus(props.row.status) }}
            </b-tag>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Button from "@/components/form/Button";
import PointService from "@/services/puntos/PointService";
import Page from "@/components/Pages";
import LoadingTable from "@/components/base/LoadingTable";
import { TRANSACTION_TYPE, TRANSACTION_STATUS } from "@/utils/constants";
export default {
  name: "StockList",
  components: {
    Layout,
    Card,
    Button,
    Page,
    LoadingTable
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getTransactionType(type) {
      switch (type) {
        case TRANSACTION_TYPE.RECHARGE:
          return "Recarga";
        case TRANSACTION_TYPE.TRANSFER:
          return "Transferencia";
        default:
          return "";
      }
    },
    getTransactionStatus(status) {
      switch (status) {
        case TRANSACTION_STATUS.COMPLETED:
          return "Completado";
        case TRANSACTION_STATUS.IN_PROGRESS:
          return "En proceso";
        case TRANSACTION_STATUS.REJECTED:
          return "Rechazado";
        default:
          return "";
      }
    },
    getTransactionStatusClass(status) {
      switch (status) {
        case TRANSACTION_STATUS.COMPLETED:
          return "is-success is-light";
        case TRANSACTION_STATUS.IN_PROGRESS:
          return "is-link is-light";
        case TRANSACTION_STATUS.REJECTED:
          return "is-success is-light";
        default:
          return "";
      }
    },
    async getList() {
      this.isLoading = true;
      try {
        const response = await PointService.getMovementsPoint();
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getList();
    },
    setPage(page) {
      this.query.page = page;
      this.getList();
    }
  }
};
</script>
