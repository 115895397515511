<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div>
            <h3 class="is-size-4 has-text-weight-bold">Mi Empresa</h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>Información</span>
                </template>
                <div>
                  <b-field
                    label="Nombre Empresa"
                    :type="{ 'is-danger': $v.data.name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.name.required && $v.data.name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.name"></b-input>
                  </b-field>
                  <b-field
                    label="Razón Social"
                    :type="{ 'is-danger': $v.data.business_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.business_name.required &&
                          $v.data.business_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.business_name"></b-input>
                  </b-field>
                  <b-field label="Teléfono (Opcional)">
                    <b-input v-model="data.phone"></b-input>
                  </b-field>
                  <b-field
                    label="Nombre de la estrella"
                    :type="{ 'is-danger': $v.data.star_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.star_name.required &&
                          $v.data.star_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.star_name"></b-input>
                  </b-field>
                  <!-- create a select with three icons and save the name of the icon -->
                  <b-field
                    label="Ícono de puntos"
                    :type="{ 'is-danger': $v.data.star_icon.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.star_icon.required &&
                          $v.data.star_icon.$error
                      }
                    ]"
                  >
                    <div
                      class="selectStarIcon"
                      :class="$v.data.star_icon.$error ? 'required' : ''"
                    >
                      <div
                        v-for="icon in icons"
                        :key="icon"
                        class="iconSelect"
                        :class="data.star_icon === icon ? 'selected' : ''"
                        @click="data.star_icon = icon"
                      >
                        <font-awesome-icon :icon="['fas', icon]" />
                        <p>
                          {{
                            icon === "coins"
                              ? "Moneda"
                              : icon === "trophy"
                              ? "Trofeo"
                              : "Estrella"
                          }}
                        </p>
                      </div>
                    </div>
                  </b-field>
                  <b-field
                    label="Logo"
                    :custom-class="$v.data.logo.$error ? 'has-text-danger' : ''"
                    :type="{ 'is-danger': $v.data.logo.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.logo.required && $v.data.logo.$error
                      }
                    ]"
                  >
                    <Upload
                      :image="data.logo"
                      :class="$v.data.logo.$error ? 'required' : ''"
                      @change="uploadLogo"
                    ></Upload>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          :spinner="loading"
          @click.native="updateInfo()"
          block
          >Guardar</Button
        >
      </Aside>
    </div>
  </Layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import CompanyService from "@/services/admin/CompanyService";
import Upload from "@/components/base/Upload";
export default {
  name: "CompanyInfo",
  components: {
    Layout,
    Card,
    Aside,
    Button,
    Upload
  },
  data() {
    return {
      icons: ["star", "trophy", "coins"],
      activeTab: 0,
      data: {
        business_name: "",
        type_company: "",
        star_name: "",
        star_icon: "",
        star_points: 0,
        name: "",
        phone: "",
        logo: ""
      },
      loading: false,
      formData: new FormData()
    };
  },
  validations: {
    data: {
      business_name: { required },
      star_name: { required },
      star_icon: { required },
      name: { required },
      logo: { required }
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    uploadLogo(file) {
      this.formData.append("logo", file);
      this.data.logo = "ok";
    },
    async updateInfo() {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.formData.append("business_name", this.data.business_name);
          this.formData.append("star_name", this.data.star_name);
          this.formData.append("name", this.data.name);
          this.formData.append("phone", this.data.phone);
          this.formData.append("star_icon", this.data.star_icon);
          await CompanyService.updateInfo(this.formData);
          this.loading = false;
          this.showMessage("S", "Se actualizó la información.");
        } catch (error) {
          this.loading = false;
          this.showMessage();
        }
      } else {
        this.loading = false;
        this.showMessage("D", "Complete los campos requeridos.");
      }
    },
    async getInfo() {
      try {
        const response = await CompanyService.getInfo();
        this.data = response.data;
      } catch (error) {
        this.showMessage("D", "No se pudo obtener la información.");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.selectStarIcon {
  display: flex;
  justify-content: space-between;
  .iconSelect {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px 0;
    &:hover {
      background-color: #dbdbdb;
    }
    &.selected {
      background-color: #3fb06f;
      color: white;
    }
  }
  &.required > .iconSelect {
    border-color: #ff3860 !important;
  }
}
</style>
