const settings = {
  namespaced: true,
  state: {
    loading: false,
    alert: {
      type: "S",
      msg: "Se procesó correctamente",
      isAlert: false
    },
    roles: [],
    currentRole: "",
    currentSubRole: ""
  },
  mutations: {
    SET_LOADING(state, status) {
      state.loading = status;
    },
    SET_ALERT(state, value) {
      state.alert.type = value.type;
      state.alert.msg = value.msg;
      state.alert.isAlert = value.isAlert;
    },
    SET_ROLES(state, value) {
      state.roles = value;
    },
    SET_CURRENT_ROLE(state, value) {
      state.currentRole = value;
      localStorage.setItem("current_role", value);
    },
    SET_CURRENT_SUB_ROLE(state, value) {
      state.currentSubRole = value;
      localStorage.setItem("current_sub_role", value);
    }
  },
  actions: {}
};

export default settings;
