import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routesCodigo from "./codigo.router";
import routesValidador from "./validador.router";
import routesAdmin from "./admin.router";
import routesPuntos from "./puntos.router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  ...routesCodigo,
  ...routesValidador,
  ...routesAdmin,
  ...routesPuntos,
  // not found page
  {
    path: "/not-allowed",
    name: "not-allowed",
    component: () => import("../views/NotAllowed.vue")
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !Vue.localStorage.get("token")) {
    next({ name: "login" });
  } else {
    if (to.name === "home") {
      store.commit("settings/SET_LOADING", true);
    }
    next();
  }
  // if the user is not authenticated, `next` is called twice
});
export default router;
