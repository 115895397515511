import { API_VALIDADOR as API } from "@/api";
import axios from "axios";

class ValidateService {
  getValidateDocument(params) {
    const response = axios.get(API.validate_num_document, {
      params
    });
    return response;
  }

  postRegisterUser(data) {
    const response = axios.post(API.registrar_usuario, data);
    return response;
  }

  getProductList(params) {
    const response = axios.get(API.products, {
      params
    });
    return response;
  }

  getValidateTypeProduct(params) {
    const response = axios.get(API.validate_type_product, {
      params
    });
    return response;
  }

  getCalculateMount(params) {
    const response = axios.get(API.calculate, {
      params
    });
    return response;
  }

  postValidateMount(data) {
    const response = axios.post(API.validate_mount, data);
    return response;
  }
}

const instance = new ValidateService();
export default instance;
