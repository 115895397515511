<template>
  <b-modal v-model="modalCancel" :width="468" scroll="keep">
    <div class="card">
      <div class="card-content">
        <h3 class="is-size-4 has-text-weight-bold my-5">
          Cancelar Pedido
        </h3>
        <hr />
        <div
          class="is-flex is-justify-content-flex-end w-full is-align-items-center"
        >
          <div>
            <Button variant="gray" @click.native="modalCancel = false"
              >Cancelar</Button
            >
          </div>
          <div class="ml-3">
            <Button
              variant="danger"
              :spinner="isLoading"
              @click.native="postOrderCancelled"
            >
              Sí, Cancelar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from "@/components/form/Button";
import DeliveryService from "@/services/validador/DeliveryService";
export default {
  components: {
    Button
  },
  data() {
    return {
      modalCancel: true,
      isLoading: false
    };
  },
  watch: {
    modalCancel() {
      this.$emit("hidden");
    }
  },
  methods: {
    async postOrderCancelled() {
      try {
        this.isLoading = true;
        await DeliveryService.postOrderCancelled(this.$route.params.id);
        this.$router.push({ name: "delivery" });
      } catch (error) {
        this.isLoading = false;
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      }
    }
  }
};
</script>
