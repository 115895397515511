export default {
  methods: {
    basicFormatDate(date) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString("es-PE", options);
    },
    addDaysDate(basicDate) {
      const date = this.basicFormatDate(basicDate).toString();
      const day = parseInt(date.substr(0, 2)) + 25;
      const month = date.substr(3, 2) - 1;
      const year = date.substr(6, 4);
      const newDate = new Date(year, month, day);
      return this.basicFormatDate(newDate);
    },
    formatDateReverse(date) {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 101).toString().substring(1);
      const day = (date.getDate() + 100).toString().substring(1);
      return year + "-" + month + "-" + day; // YYYY-MM-DD
    },
    formateBasicDate(date) {
      const year = date.substr(0, 4);
      const month = date.substr(5, 2) - 1;
      const day = date.substr(8, 2);
      return new Date(year, month, day);
    },
    formatDateToString(date) {
      const dateFormat = new Date(date);
      const dia = dateFormat.getDate();
      const mes = dateFormat.getMonth() + 1;
      const año = dateFormat.getFullYear();
      const diaFormateado = (dia < 10 ? "0" : "") + dia;
      const mesFormateado = (mes < 10 ? "0" : "") + mes;
      return `${diaFormateado}-${mesFormateado}-${año}`;
    }, // Input Thu Feb 08 2024 00:00:00 GMT-0500 (Peru Standard Time)  Output 08-02-2024
    formatStringToDate(date) {
      const arrayDate = date.split("-");
      const day = arrayDate[0];
      const month = arrayDate[1] - 1;
      const year = arrayDate[2];
      return new Date(year, month, day);
    } // Input 08-02-2024  Output Thu Feb 08 2024 00:00:00 GMT-0500 (Peru Standard Time)
  }
};
