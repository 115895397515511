var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"is-flex"},[_c('Card',{staticClass:"w-full"},[_c('template',{slot:"header"},[_c('div',{},[_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v("Editar Cliente")])])]),_c('template',{slot:"content"},[_c('section',[_c('b-tabs',{attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("Información")])]},proxy:true}])},[_c('div',[_c('b-field',{attrs:{"label":"Activo"}},[_c('b-switch',{attrs:{"type":"is-success"},model:{value:(_vm.data.is_active),callback:function ($$v) {_vm.$set(_vm.data, "is_active", $$v)},expression:"data.is_active"}})],1),_c('b-field',{attrs:{"label":"Nombre","type":{ 'is-danger': _vm.$v.data.first_name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.first_name.required &&
                        _vm.$v.data.first_name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.first_name),callback:function ($$v) {_vm.$set(_vm.data, "first_name", $$v)},expression:"data.first_name"}})],1),_c('b-field',{attrs:{"label":"Apellido","type":{ 'is-danger': _vm.$v.data.last_name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.last_name.required &&
                        _vm.$v.data.last_name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.last_name),callback:function ($$v) {_vm.$set(_vm.data, "last_name", $$v)},expression:"data.last_name"}})],1),_c('b-field',{attrs:{"label":"N° Documento","type":{ 'is-danger': _vm.$v.data.num_document.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.num_document.required &&
                        _vm.$v.data.num_document.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.num_document),callback:function ($$v) {_vm.$set(_vm.data, "num_document", $$v)},expression:"data.num_document"}})],1),_c('b-field',{attrs:{"label":"Fecha de Cumpleaños (Opcional)"}},[_c('b-datepicker',{attrs:{"placeholder":"Elige una fecha"},model:{value:(_vm.birth_date),callback:function ($$v) {_vm.birth_date=$$v},expression:"birth_date"}})],1),_c('b-field',{attrs:{"label":"Email (Opcional)","type":{ 'is-danger': _vm.$v.data.email.$error },"message":[
                    {
                      'Email inválido':
                        !_vm.$v.data.email.email && _vm.$v.data.email.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('b-field',{attrs:{"label":"Teléfono (Opcional)"}},[_c('b-input',{model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1)],1)])],1)],1)])],2),_c('Aside',[_c('Button',{staticClass:"mb-2",attrs:{"variant":"main","spinner":_vm.loading,"block":""},nativeOn:{"click":function($event){return _vm.updateClient()}}},[_vm._v(" Guardar ")]),_c('Button',{attrs:{"tag":"router-link","to":{ name: 'clients' },"variant":"gray","block":""}},[_vm._v(" Cancelar ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }