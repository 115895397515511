var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"is-half column border p-5",staticStyle:{"margin":"0 auto"}},[_c('div',[_c('div',{staticClass:"has-text-centered py-2 mb-3 is-full"},[_c('img',{attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('h3',{staticClass:"mb-3 has-text-weight-bold"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'credit-card'],"custom-size":"default"}}),_vm._v(" Ingrese su N° Documento ")],1),_c('hr'),_c('b-field',{attrs:{"label":"DNI","type":{ 'is-danger': _vm.$v.document.$error },"message":[
        {
          'Requerido. Ingresa tu N° Documento':
            !_vm.$v.document.required && _vm.$v.document.$error
        },
        {
          'Incorrecto. Ingresa tu N° Documento':
            !_vm.$v.document.numeric && _vm.$v.document.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"N° Documento","name":"document"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateDocument($event)}},model:{value:(_vm.document),callback:function ($$v) {_vm.document=$$v},expression:"document"}})],1),_c('hr'),_c('Button',{attrs:{"spinner":_vm.isLoading,"variant":"gray"},nativeOn:{"click":function($event){return _vm.goBack($event)}}},[_vm._v(" Regresar ")]),_c('Button',{staticClass:"ml-2",attrs:{"variant":"main","spinner":_vm.isLoading},nativeOn:{"click":function($event){return _vm.validateDocument($event)}}},[_vm._v(" Continuar ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }