<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    title: {
      type: String,
      default: ""
    },
    use: {
      type: Number,
      default: 0
    },
    free: {
      type: Number,
      default: 0
    },
    values: {
      type: Array,
      default: () => [40, 20]
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: [`Libres (${this.free})`, `Usados (${this.use})`],
        datasets: [
          {
            backgroundColor: ["#3fb06f", "#e04747", "#4BB578"],
            data: this.values
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title
        }
      }
    );
  }
};
</script>
