<template>
  <div>
    <h4 class="has-text-centered is-size-6 has-text-weight-semibold mb-5">
      Redenciones por mes
    </h4>
    <div class="filterContainer">
      <b-field label="Fecha desde: " class="column">
        <b-datepicker
          type="month"
          size="is-regular"
          v-model="date_from"
          locale="es-PE"
          placeholder="Elige una fecha"
          icon="calendar-day"
          @input="filterQuery"
          :max-date="date_to"
        >
        </b-datepicker>
      </b-field>
      <b-field label="Fecha hasta: " class="column">
        <b-datepicker
          type="month"
          size="is-regular"
          v-model="date_to"
          locale="es-PE"
          placeholder="Elige una fecha"
          icon="calendar-day"
          @input="filterQuery"
          :max-date="currentDate"
        >
        </b-datepicker>
      </b-field>
      <b-field label="Stock de códigos" class="column">
        <b-select
          placeholder="Seleccione una opción"
          v-model="query.code"
          icon="stream"
          @input="filterQuery"
          size="is-regular"
        >
          <option :value="null">Todos</option>
          <option
            v-for="item in generateCodeList"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div v-if="mountGraphic">
      <LineChart :information="months" :values="values" label="Redenciones" />
    </div>
    <div v-else class="has-text-centered">
      Cargando gráfico de redenciones ...
    </div>
    <hr />
  </div>
</template>

<script>
import LineChart from "@/components/graphics/LineChart";
import GraphicService from "@/services/GraphicService";
import formatDate from "@/mixins/formatDate";
import CodeService from "@/services/CodeService";
export default {
  components: {
    LineChart
  },
  mixins: [formatDate],
  data() {
    const today = new Date();
    return {
      firstDate: new Date(today.getFullYear(), today.getMonth() - 3, 1),
      currentDate: new Date(today.getFullYear(), today.getMonth(), 1),
      results: [],
      months: [],
      values: [],
      mountGraphic: false,
      query: {
        date_from: null,
        date_to: null,
        code: null
      },
      date_from: null,
      date_to: null,
      generateCodeList: []
    };
  },
  mounted() {
    this.date_from = this.firstDate;
    this.date_to = this.currentDate;
    this.filterQuery();
    this.getListCodes();
  },
  methods: {
    async getData(params) {
      try {
        this.mountGraphic = false;
        this.results = [];
        this.months = [];
        this.values = [];
        const response = await GraphicService.getOrder(params);
        this.results = response.data;
        for (let i = 0; i < this.results.length; i++) {
          this.values.push(this.results[i].total);
          this.months.push(this.results[i].name);
        }
        setTimeout(() => {
          this.mountGraphic = true;
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    async getListCodes() {
      try {
        const response = await CodeService.getListSimple();
        this.generateCodeList = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    filterQuery() {
      const dateTo = this.addDaysDate(this.date_to);
      this.query.date_to = dateTo;
      const dateFrom = this.basicFormatDate(this.date_from);
      this.query.date_from = dateFrom;
      this.getData({ ...this.query });
    }
  }
};
</script>
