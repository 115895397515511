<template>
  <div class="aside">
    <div class="aside__contenedor">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Aside"
};
</script>
<style lang="scss">
.aside {
  width: 240px;
  padding-left: 20px;
  position: relative;
  &__contenedor {
    min-width: 100%;
    position: -webkit-sticky;
    position: sticky !important;
    top: 25px;
  }
}
</style>
