import { API_PUNTOS as API } from "@/api";
import axios from "axios";

class CompanyService {
  getInfo() {
    const response = axios.get(API.company_group);
    return response;
  }

  updateInfo(data) {
    const response = axios.post(API.company_group, data);
    return response;
  }

  updateLogo(data) {
    const response = axios.put(API.company_group_logo, data);
    return response;
  }
}

const instance = new CompanyService();
export default instance;
