import { API_ADMIN as API } from "@/api";
import axios from "axios";
class CompanyService {
  getInfo() {
    const response = axios.get(API.company);
    return response;
  }

  updateInfo(data) {
    const response = axios.post(API.company, data);
    return response;
  }
}

const instance = new CompanyService();
export default instance;
