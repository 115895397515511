<template>
  <Layout>
    <Card class="w-full">
      <template slot="content">
        <div class="not-found">
          <h1>404</h1>
          <p>Página no encontrada</p>
        </div>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
export default {
  name: "NotFound",
  components: {
    Layout,
    Card
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.not-found {
  text-align: center;
  padding: 0 20px 70px;
  h1 {
    font-size: 100px;
    margin: 0;
  }
  p {
    font-size: 20px;
    margin: 0;
  }
}
</style>
