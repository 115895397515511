<template>
  <b-modal v-model="showModal" :width="650" scroll="keep">
    <div class="card">
      <div class="card-content py-5">
        <h3 class="is-size-4 has-text-weight-bold mb-5">
          Seleccione un template
        </h3>
        <hr />
        <div>
          <b-table
            class="templateList"
            :data="isEmpty ? [] : templateList"
            :loading="loading"
          >
            <b-table-column
              field="description"
              label="Descripción"
              v-slot="props"
            >
              <b-input
                type="textarea"
                v-model="props.row.description"
                maxlength="500"
                :disabled="active !== props.row.id"
              />
            </b-table-column>
            <b-table-column field="button" label="" v-slot="props">
              <div class="has-text-right">
                <b-button
                  type="is-primary"
                  size="is-small"
                  v-if="active !== props.row.id"
                  @click="selectTemplate(props.row)"
                >
                  Seleccionar
                </b-button>
                <b-button
                  type="is-warning"
                  size="is-small"
                  class="ml-1"
                  v-if="active !== props.row.id && type_template === 'SMS'"
                  @click="active = props.row.id"
                >
                  <font-awesome-icon :icon="['fas', 'pen']" />
                </b-button>
                <b-button
                  type="is-primary"
                  size="is-small"
                  class="ml-1"
                  v-else-if="active === props.row.id && type_template === 'SMS'"
                  @click="saveTemplate(props.row.id, props.row.description)"
                >
                  Guardar
                  <font-awesome-icon :icon="['fas', 'save']" class="ml-1" />
                </b-button>
                <b-button
                  type="is-danger"
                  size="is-small"
                  class="ml-1"
                  v-if="active !== props.row.id"
                  @click="deleteTemplate(props.row.id)"
                >
                  <font-awesome-icon :icon="['fas', 'trash']" />
                </b-button>
              </div>
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">Sin datos</div>
            </template>
          </b-table>
          <Pages
            :links="links"
            :pages="pages"
            :per_page="4"
            v-if="pages"
            @set-link="setLink"
            @set-page="setPage"
          ></Pages>
          <hr />
          <div
            class="is-flex is-justify-content-flex-end w-full is-align-items-center"
          >
            <Button variant="gray" @click.native="showModal = false">
              Cerrar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from "@/components/form/Button";
import MessageService from "@/services/MessageService";
import Pages from "@/components/Pages";
export default {
  components: {
    Button,
    Pages
  },
  props: {
    type_template: {
      type: String,
      default: "SMS"
    }
  },
  data() {
    return {
      showModal: true,
      loading: false,
      templateList: [],
      isEmpty: false,
      links: {},
      pages: {},
      active: null
    };
  },
  watch: {
    showModal() {
      this.$emit("hidden");
    }
  },
  mounted() {
    this.getInfo({ type_template: this.type_template });
  },
  methods: {
    async getInfo(params) {
      try {
        this.loading = true;
        const response = await MessageService.getListTemplate(params);
        this.templateList = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
        this.pages.per_page = 4;
        this.isEmpty = this.templateList.length === 0;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error."
        });
        this.showModal = false;
      }
    },
    setLink() {
      this.getInfo({ type_template: this.type_template });
    },
    setPage(page) {
      this.getInfo({ page: page, type_template: this.type_template });
    },
    selectTemplate(data) {
      this.$emit("getTemplate", data);
      this.showModal = false;
    },
    async saveTemplate(id, description) {
      try {
        this.loading = true;
        await MessageService.updateTemplate(id, { description: description });
        this.active = null;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "No se pudo actualizar."
        });
      }
    },
    async deleteTemplate(id) {
      try {
        this.loading = true;
        await MessageService.deleteTemplate(id);
        await this.getInfo({ type_template: this.type_template });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "No se pudo eliminar."
        });
      }
    }
  }
};
</script>
