<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">
              Detalle
            </h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>Productos</span>
                </template>
                <div class="products">
                  <div
                    class="products__container"
                    v-for="(p, index) in products"
                    :key="index"
                  >
                    <CardProduct :product="p" />
                  </div>
                </div>
                <hr style="margin-top: 0;" />
                <Page
                  :links="links"
                  :pages="pages"
                  v-if="pages"
                  @set-link="setLink"
                  @set-page="setPage"
                ></Page>
              </b-tab-item>
              <b-tab-item>
                <template #header>
                  <span>Códigos</span>
                </template>
                <b-table :data="codes" detail-key="id" icon-pack="fas">
                  <b-table-column field="code" label="Código" v-slot="props">
                    <span>{{ props.row.code }}</span>
                  </b-table-column>
                  <b-table-column
                    field="date_activate"
                    label="Fecha de activación"
                    v-slot="props"
                  >
                    <span>{{ props.row.date_activate }}</span>
                  </b-table-column>
                  <b-table-column
                    field="segment"
                    label="Segmento"
                    v-slot="props"
                  >
                    <span>
                      {{ formatSegments(props.row.segments) }}
                    </span>
                  </b-table-column>
                  <b-table-column field="is_use" label="Usado" v-slot="props">
                    <font-awesome-icon
                      v-if="props.row.is_use"
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </b-table-column>
                </b-table>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          tag="router-link"
          :to="{ name: 'customer_list' }"
          variant="gray"
          block
          class="mt-2"
          >Regresar</Button
        >
      </Aside>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import CardProduct from "@/components/base/CardProduct";
import CustomerService from "@/services/CustomerService";
import Page from "@/components/Pages";
export default {
  name: "CodeUpdate",
  components: {
    Layout,
    Card,
    Aside,
    CardProduct,
    Button,
    Page
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      products: [],
      links: {},
      pages: {},
      codes: [],
      detailModal: false
    };
  },
  mounted() {
    this.getProducts();
    this.getCodes();
  },
  methods: {
    formatSegments(params) {
      let segments = [];
      for (let i = 0; i < params.length; i++) {
        segments.push(params[i].name);
      }
      return segments.join();
    },
    async getProducts(params) {
      try {
        const response = await CustomerService.getProducts(
          this.$route.params.id,
          params
        );
        this.products = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$router.push({ name: "customer_list" });
      }
    },
    setLink() {
      this.getProducts({});
    },
    setPage(page) {
      this.getProducts({ page: page });
    },
    async getCodes(params) {
      try {
        const response = await CustomerService.getCodes(
          this.$route.params.id,
          params
        );
        this.codes = response.data;
      } catch (error) {
        this.$router.push({ name: "customer_list" });
      }
    }
  }
};
</script>
<style lang="scss">
.products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  @media (max-width: 1040px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  &__container {
    border: 1px solid $color-border;
    border-radius: 0.5rem;
    overflow: hidden;
    .productImg {
      object-fit: cover;
      width: 100%;
      height: 130px;
      @media (max-width: 1040px) {
        height: 180px;
      }
      @media (max-width: 768px) {
        height: 200px;
      }
    }
    .productText {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 260px;
    }
  }
}
.companyImg {
  width: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
</style>
