<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div class="header-container">
          <div class="is-size-4 has-text-weight-bold">
            Pedido #{{ results.code }}
          </div>
          <div class="button-container">
            <Button
              v-if="results.status != 'CP' && results.status != 'CN'"
              :spinner="isLoading"
              variant="main"
              @click.native="sendData"
              >{{ textButton }}</Button
            >
            <Button
              v-if="results.status == 'PE'"
              :spinner="isLoading"
              variant="danger"
              @click.native="modalCancel = true"
              >Cancelar</Button
            >
            <Button
              :spinner="isLoading"
              tag="button"
              @click.native="$router.go(-1)"
              variant="gray"
              >Regresar</Button
            >
          </div>
        </div>
      </template>
      <template slot="content">
        <section>
          <b-steps
            v-model="currentStep"
            :rounded="true"
            :has-navigation="false"
            class="border p-5 mb-5"
          >
            <b-step-item
              step="1"
              :label="results.status == 'CN' ? 'Cancelado' : 'Pendiente'"
              :clickable="false"
            >
            </b-step-item>
            <b-step-item step="2" label="Confirmado" :clickable="false">
            </b-step-item>
            <b-step-item
              step="3"
              :visible="true"
              label="Enviado"
              :clickable="false"
            >
            </b-step-item>
            <b-step-item
              :step="true ? '4' : '3'"
              label="Completado"
              :clickable="false"
            >
            </b-step-item>
          </b-steps>
          <div class="columns tile is-parent" style="gap: 1.5rem">
            <div class="border p-5 column is-two-fifths">
              <h3 class="mb-5">
                <font-awesome-icon
                  :icon="['fas', 'user']"
                  custom-size="default"
                  class="mr-3"
                />
                Cliente
              </h3>
              <hr />
              <b-field
                class="has-text-weight-bold"
                label="Nombre"
                v-if="results.full_name"
              >
                <p>{{ results.full_name }}</p>
              </b-field>
              <b-field class="has-text-weight-bold" label="N° Documento">
                <p>{{ results.order.num_document }}</p>
              </b-field>
              <b-field class="has-text-weight-bold" label="E-mail">
                <p>{{ results.email }}</p>
              </b-field>
              <b-field class="has-text-weight-bold" label="Teléfono">
                <p>{{ results.telephone }}</p>
              </b-field>

              <b-field
                class="has-text-weight-bold"
                label="Departamento"
                v-if="results.department"
              >
                <p>{{ results.department }}</p>
              </b-field>
              <b-field
                class="has-text-weight-bold"
                label="Provincia"
                v-if="results.province"
              >
                <p>{{ results.province }}</p>
              </b-field>
              <b-field
                class="has-text-weight-bold"
                label="Distrito"
                v-if="results.district"
              >
                <p>{{ results.district }}</p>
              </b-field>

              <b-field class="has-text-weight-bold" label="Dirección">
                <p>{{ results.address }}</p>
              </b-field>
              <b-field class="has-text-weight-bold" label="Referencia">
                <p>{{ results.reference }}</p>
              </b-field>
              <b-field class="has-text-weight-bold" label="Número de Casa">
                <p>{{ results.nro }}</p>
              </b-field>
              <b-field
                class="has-text-weight-bold"
                label="Departamento"
                v-if="results.dpto"
              >
                <p>{{ results.dpto }}</p>
              </b-field>
              <b-field
                class="has-text-weight-bold"
                label="Mapa"
                v-if="google_api_key"
              >
                <img
                  alt="Mapa"
                  class="card"
                  :src="
                    'https://maps.googleapis.com/maps/api/staticmap?center=' +
                      results.lat +
                      ',' +
                      results.lng +
                      '&zoom=16&size=400x300&markers=color:red%7Clabel:C%7C' +
                      results.lat +
                      ',' +
                      results.lng +
                      '&key=' +
                      google_api_key
                  "
                />
              </b-field>
            </div>
            <div class="column border p-5">
              <h3 class="mb-5">
                <font-awesome-icon
                  :icon="['fas', 'box']"
                  custom-size="default"
                  class="mr-3"
                />
                Producto
              </h3>
              <hr />
              <div class="has-text-centered">
                <img
                  class="card"
                  :src="results.order.product_variant_image"
                  alt="Imagen del producto"
                />
              </div>
              <b-field :label="results.order.product_variant_name">
                <div
                  class="delivery-product"
                  v-html="results.order.product_variant_description"
                ></div>
              </b-field>
              <div v-if="results.note">
                <hr />
                <b-field label="Nota">
                  <p>{{ results.note }}</p>
                </b-field>
              </div>
              <hr />
              <b-field horizontal label="Subtotal" class="has-text-weight-bold">
                <p>S/. {{ results.order.price }}</p>
              </b-field>
              <b-field
                horizontal
                label="Descuento"
                class="has-text-weight-bold"
              >
                <p>S/. {{ results.order.discount }}</p>
              </b-field>
              <b-field horizontal label="IGV" class="has-text-weight-bold">
                <p>S/. {{ results.order.igv }}</p>
              </b-field>
              <b-field
                horizontal
                label="Total Pagado"
                class="has-text-weight-bold"
              >
                <p>S/. {{ results.order.total }}</p>
              </b-field>
            </div>
          </div>
        </section>
      </template>
    </Card>
    <ModalConfirm
      v-if="modalConfirm"
      :currentDate="currentDate"
      @hidden="modalConfirm = false"
      @reload="getDeliveryDetail"
    />
    <ModalCancel v-if="modalCancel" @hidden="modalCancel = false" />
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Button from "@/components/form/Button";
import DeliveryService from "@/services/validador/DeliveryService";
import ModalCancel from "./modal/ModalCancel";
import ModalConfirm from "./modal/ModalConfirm";
export default {
  name: "DeliveryDetail",
  components: {
    Layout,
    Card,
    Button,
    ModalCancel,
    ModalConfirm
  },
  data() {
    return {
      currentStep: 0,
      isLoading: false,
      google_api_key: null,
      textButton: "Confirmar",
      currentDate: "",
      modalConfirm: false,
      modalCancel: false,
      results: {
        code: "",
        status: "CP",
        email: "",
        address: "",
        nro: "",
        dpto: "",
        telephone: "",
        order: {
          product_variant_description: "",
          product_variant_name: "",
          product_variant_image: ""
        }
      },
      status: [
        { id: "PE", name: "Pendiente" },
        { id: "CF", name: "Confirmado" },
        { id: "EN", name: "Enviado" },
        { id: "CP", name: "Completado" },
        { id: "CN", name: "Cancelado" }
      ]
    };
  },
  created() {
    this.getDeliveryDetail();
  },
  mounted() {
    this.google_api_key = "AIzaSyBN7v9L9OiTGFib9o67NzSw1IVDH8UKOaE";
  },
  methods: {
    errorMessage() {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type: "D",
        msg: "Ocurrió un error"
      });
    },
    getStatusStep(status) {
      switch (status) {
        case "PE":
          this.currentStep = 0;
          this.textButton = "Confirmar";
          break;
        case "CF":
          this.currentStep = 1;
          this.textButton = "Enviar";
          break;
        case "EN":
          this.currentStep = 2;
          this.textButton = "Completar";
          break;
        case "CP":
          this.currentStep = 3;
          break;
        default:
          this.currentStep = 0;
      }
    },
    async getDeliveryDetail() {
      try {
        this.isLoading = true;
        const response = await DeliveryService.getDeliveryDetail(
          this.$route.params.id
        );
        this.results = response.data;
        const splitDate = response.data.today.split("-");
        this.currentDate = new Date(
          splitDate[0],
          splitDate[1] - 1,
          splitDate[2]
        );
        this.getStatusStep(response.data.status);
      } catch (error) {
        this.errorMessage();
        this.$router.push({ name: "delivery" });
      }
      this.isLoading = false;
    },
    async sendData() {
      if (this.results.status === "PE") {
        this.modalConfirm = true;
      } else {
        try {
          this.isLoading = true;
          await DeliveryService.postChangeStatus(this.$route.params.id);
          this.getDeliveryDetail();
        } catch (error) {
          this.errorMessage();
        }
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss">
// Style steps - Buefy
.b-steps {
  .step-marker {
    height: 5rem !important;
    width: 5rem !important;
  }
  .step-item::before,
  .step-item::after {
    top: 2.5rem !important;
  }
  .is-active {
    .step-marker {
      background-color: #00b16c !important;
      border-color: white !important;
      color: white !important;
    }
  }
  .step-content {
    display: none !important;
  }
}
.delivery-product {
  li {
    list-style: disc inside;
  }
}
</style>
