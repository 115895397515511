<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Historial</div>
        </div>
      </template>
      <template slot="content">
        <div class="filterContainer">
          <div class="column px-0">
            <b-field label="Local:">
              <b-select
                placeholder="Seleccione una opción"
                v-model="query.local"
                icon="home"
                size="is-regular"
                @input="filterOrderList"
              >
                <option
                  v-for="local in locals"
                  :key="local.id"
                  :value="local.id"
                  >{{ local.name }}</option
                >
              </b-select>
            </b-field>
          </div>
          <div class="column px-0">
            <b-field label="Promoción:">
              <b-select
                placeholder="Seleccione una opción"
                v-model="query.product"
                icon="box"
                size="is-regular"
                @input="filterOrderList"
              >
                <option :value="null">Todos</option>
                <option
                  v-for="product in products"
                  :key="product.id"
                  :value="product.id"
                >
                  {{ product.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="specialContainer">
            <div class="column px-0">
              <b-field label="Fecha Desde">
                <b-datepicker
                  placeholder="Elige una fecha"
                  v-model="dateFilter.date_from"
                  @input="formatDate('date_from')"
                  icon="calendar"
                >
                  <button
                    class="button is-danger"
                    @click="clearDate('date_from')"
                  >
                    <span>Limpiar</span>
                  </button>
                </b-datepicker>
              </b-field>
            </div>
            <div class="column px-0">
              <b-field label="Fecha Hasta">
                <b-datepicker
                  placeholder="Elige una fecha"
                  v-model="dateFilter.date_to"
                  @input="formatDate('date_to')"
                  icon="calendar"
                  :mobile-native="false"
                >
                  <button
                    class="button is-danger"
                    @click="clearDate('date_to')"
                  >
                    <span>Limpiar</span>
                  </button>
                </b-datepicker>
              </b-field>
            </div>
            <div class="column px-0">
              <b-field label="Estado:">
                <b-select
                  placeholder="Seleccione una opción"
                  v-model="query.status"
                  icon="check"
                  size="is-regular"
                  @input="filterOrderList"
                >
                  <option :value="null">Todos</option>
                  <option
                    v-for="value in statusOrderList"
                    :key="value[0]"
                    :value="value[0]"
                  >
                    {{ value[1] }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="custom-card">
          <span class="has-text-weight-bold">
            Total asumido por Weeare S/.
            {{ total.toFixed(2) }}
          </span>
          <p v-if="date.from && date.to">
            Del {{ date.from }} al {{ date.to }}
          </p>
        </div>
        <b-table
          :loading="isLoading"
          :data="results"
          hoverable
          striped
          detail-key="id"
          icon-pack="fas"
        >
          <b-table-column
            label="Promoción"
            field="product_variant_name"
            sortable
            v-slot="props"
          >
            {{ props.row.product_variant_name }}
          </b-table-column>
          <b-table-column label="Fecha" field="creation" v-slot="props">
            {{ props.row.creation }}
          </b-table-column>
          <b-table-column label="Documento" field="num_document" v-slot="props">
            {{ props.row.num_document }}
          </b-table-column>
          <b-table-column label="Total" field="price" v-slot="props">
            S/. {{ props.row.price }}
          </b-table-column>
          <b-table-column label="Descuento" field="discount" v-slot="props">
            S/. {{ props.row.discount }}
          </b-table-column>
          <b-table-column label="Pagado" field="total" v-slot="props">
            S/. {{ props.row.total }}
          </b-table-column>
          <b-table-column
            label="Asumido por el comercio"
            field="total_store"
            width="110"
            v-slot="props"
          >
            S/. {{ props.row.total_store }}
          </b-table-column>
          <b-table-column
            label="Asumido por Weeare"
            field="total_ecommerce"
            width="110"
            v-slot="props"
          >
            S/. {{ props.row.total_ecommerce }}
          </b-table-column>
          <b-table-column
            label="Estado"
            field="status"
            width="90"
            v-slot="props"
          >
            <b-tag :type="getClassStatusOrder(props.row.status)">
              {{ getNameStatusOrder(props.row.status) }}
            </b-tag>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
      </template>
    </Card>
    <ModalBilling
      v-if="modaBilling"
      :idBilling="billing.id"
      :numInvoice="billing.num_invoice"
      @hidden="modaBilling = false"
      @reload="getOrderList"
    />
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import LocalService from "@/services/validador/LocalService";
import OrderService from "@/services/validador/OrderService";
import LoadingTable from "@/components/base/LoadingTable";
import ModalBilling from "@/views/validador/billing/modal/ModalBilling";
import formatDate from "@/mixins/formatDate";
import { STATUS_ORDER } from "../../../utils/constants";
export default {
  name: "OrderList",
  components: {
    Layout,
    Card,
    LoadingTable,
    ModalBilling
  },
  mixins: [formatDate],
  data() {
    return {
      locals: [],
      results: [],
      billing: {},
      products: [],
      total: 0,
      date: {
        from: null,
        to: null
      },
      dateFilter: {
        date_from: null,
        date_to: null
      },
      modaBilling: false,
      isLoading: true,
      query: {
        local: null,
        date_from: null,
        date_to: null,
        product: null,
        status: null
      }
    };
  },
  computed: {
    statusOrderList() {
      return Object.entries(STATUS_ORDER);
    }
  },
  created() {
    this.getLocals();
    this.getProductList();
  },
  methods: {
    getNameStatusOrder(status) {
      return STATUS_ORDER[status];
    },
    getClassStatusOrder(status) {
      switch (status) {
        case "B":
          return "is-warning";
        case "O":
          return "is-primary";
        case "P":
          return "is-twitter";
        default:
          return "is-warning";
      }
    },
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async getLocals() {
      this.isLoading = true;
      try {
        const response = await LocalService.getLocalList();
        this.locals = response.data;
        this.query.local = this.locals[0].id;
        await this.getOrderList();
      } catch (error) {
        this.showMessage("D", "No se pudo obtener la lista de locales");
      } finally {
        this.isLoading = false;
      }
    },
    async getProductList() {
      try {
        const response = await OrderService.getOrderProductList();
        this.products = response.data;
      } catch (error) {
        this.showMessage("D", "No se pudo obtener la lista de productos");
      }
    },
    filterOrderList() {
      this.getOrderList();
    },
    async getOrderList() {
      this.isLoading = true;
      try {
        const response = await OrderService.getOrderList(this.query);
        this.results = response.data.result;
        this.date = response.data.fecha;
        this.total = response.data.total ? response.data.total : 0;
      } catch (error) {
        this.showMessage();
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(value) {
      this.query[value] = this.formatDateReverse(this.dateFilter[value]);
      this.filterOrderList();
    },
    clearDate(value) {
      this.dateFilter[value] = null;
      this.query[value] = null;
      this.filterOrderList();
    }
  }
};
</script>

<style lang="scss">
.b-table .table th.is-sortable {
  border-color: rgba(34, 43, 59, 0.05);
}
.custom-card {
  border: 1px solid #dbdbdb;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}
.specialContainer {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  @media (max-width: 860px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-template-columns: 1fr;
  }
}
</style>
