<template>
  <section class="is-half column border p-5" style="margin: 0 auto;">
    <div title="" icon="card-text" :image="logo">
      <div class="has-text-centered py-2 mb-3 is-full">
        <img :src="logo" alt="logo" class="" />
      </div>
      <h3 class="mb-3 has-text-weight-bold">
        <font-awesome-icon
          :icon="['fas', 'credit-card']"
          custom-size="default"
          class="mr-1"
        />
        Ingrese los siguientes datos
      </h3>
      <hr />
      <b-field
        label="Email"
        :type="{ 'is-danger': $v.data.email.$error }"
        :message="[
          {
            'Requerido. Ingresa tu email':
              !$v.data.email.required && $v.data.email.$error
          },
          {
            'Incorrecto. Ingresa un email válido':
              !$v.data.email.email && $v.data.email.$error
          }
        ]"
      >
        <b-input
          v-model="data.email"
          placeholder="Ingresa tu email"
          name="email"
        />
      </b-field>
      <b-field
        label="Nombres"
        :type="{ 'is-danger': $v.data.first_name.$error }"
        :message="[
          {
            'Requerido. Ingresa tu nombre':
              !$v.data.first_name.required && $v.data.first_name.$error
          }
        ]"
      >
        <b-input
          v-model="data.first_name"
          placeholder="Ingresa tu nombre"
          name="first_name"
        />
      </b-field>
      <b-field
        label="Apellidos"
        :type="{ 'is-danger': $v.data.last_name.$error }"
        :message="[
          {
            'Requerido. Ingresa tu apellido':
              !$v.data.last_name.required && $v.data.last_name.$error
          }
        ]"
      >
        <b-input
          v-model="data.last_name"
          placeholder="Ingresa tu apellido"
          name="last_name"
        />
      </b-field>
      <b-field label="Documento">
        <b-input :value="document" disabled></b-input>
      </b-field>
      <hr />
      <Button :spinner="isLoading" variant="gray" @click.native="goBack">
        Regresar
      </Button>
      <Button
        variant="main"
        :spinner="isLoading"
        class="ml-2"
        @click.native="validateDocument"
      >
        Continuar
      </Button>
    </div>
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Button from "@/components/form/Button";
import ValidateService from "@/services/validador/ValidateService";
export default {
  components: {
    Button
  },
  props: {
    option: Number,
    document: String,
    logo: String
  },
  data() {
    return {
      isLoading: false,
      data: {
        email: "",
        first_name: "",
        last_name: "",
        num_document: "",
        company_provider: ""
      }
    };
  },
  validations: {
    data: {
      email: { required, email },
      first_name: { required },
      last_name: { required }
    }
  },
  created() {
    this.data.num_document = this.document;
    this.data.company_provider = this.option;
  },
  methods: {
    goBack() {
      this.$emit("goBack", 0);
    },
    async validateDocument() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true;
          await ValidateService.postRegisterUser(this.data);
          this.$emit("goBack", 4);
        } catch (error) {
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: error.response.data.error
          });
        }
        this.isLoading = false;
      }
    }
  }
};
</script>
