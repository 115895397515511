<template>
  <b-modal v-model="showModal" :width="550" scroll="keep">
    <div class="card">
      <div class="card-content py-5">
        <h3 class="is-size-4 has-text-weight-bold mb-5">
          Crear Template - Mensaje de Texto
        </h3>
        <hr />
        <b-notification type="is-info" aria-close-label="Close notification">
          <small>
            Puedes usar los siguientes campos dinámicos: {first_name},
            {last_name}, {full_name}.
          </small>
          <br />
          <small>
            Estos templates solo están disponibles para
            <strong>Mensajes de Texto</strong>.
          </small>
        </b-notification>
        <div>
          <b-field
            label="Mensaje"
            :type="{ 'is-danger': $v.data.description.$error }"
            :message="[
              {
                'Campo requerido':
                  !$v.data.description.required && $v.data.description.$error
              }
            ]"
          >
            <b-input
              maxlength="500"
              type="textarea"
              v-model="data.description"
              placeholder="Hola {full_name}, tienes un regalo."
            >
            </b-input>
          </b-field>
          <hr />
          <div
            class="is-flex is-justify-content-flex-end w-full is-align-items-center"
          >
            <div>
              <Button variant="gray" @click.native="showModal = false">
                Cerrar
              </Button>
            </div>
            <div class="ml-3">
              <Button
                variant="main"
                :spinner="loading"
                @click.native="saveInfo"
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Button from "@/components/form/Button";
import MessageService from "@/services/MessageService";
export default {
  components: {
    Button
  },
  data() {
    return {
      showModal: true,
      loading: false,
      data: {
        description: ""
      }
    };
  },
  validations: {
    data: {
      description: { required }
    }
  },
  watch: {
    showModal() {
      this.$emit("hidden");
    }
  },
  methods: {
    async saveInfo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          await MessageService.postNewTemplate(this.data);
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "S",
            msg: "Se guardó correctamente."
          });
          this.showModal = false;
        } catch (error) {
          this.loading = false;
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: "Ocurrió un error."
          });
        }
      }
    }
  }
};
</script>
