<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Códigos</div>
        </div>
      </template>
      <template slot="content">
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          detail-key="id"
          icon-pack="fas"
        >
          <b-table-column field="name" label="Nombre" v-slot="props">
            <router-link
              :to="{ name: 'code_detail', params: { id: props.row.id } }"
              class="has-text-weight-semibold"
              >{{ props.row.name }}</router-link
            >
          </b-table-column>
          <b-table-column field="quantity" label="Total" v-slot="props">
            <span>{{ props.row.quantity }}</span>
          </b-table-column>
          <b-table-column field="use" label="Usado" v-slot="props">
            <span>{{ props.row.use }}</span>
          </b-table-column>
          <b-table-column field="free" label="Libre" v-slot="props">
            <span>{{ props.row.free }}</span>
          </b-table-column>
          <b-table-column
            field="created"
            label="Fecha de creación"
            v-slot="props"
          >
            <span>{{ props.row.created }}</span>
          </b-table-column>
          <b-table-column
            field="date_max"
            label="Fecha límite de uso"
            v-slot="props"
          >
            <span>{{ props.row.date_max }}</span>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import CodeService from "@/services/CodeService";
import Page from "@/components/Pages";
import LoadingTable from "@/components/base/LoadingTable";
export default {
  name: "CodeList",
  components: {
    Layout,
    Card,
    Page,
    LoadingTable
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true
    };
  },
  created() {
    this.getDataAPI({});
  },
  methods: {
    async getDataAPI(params) {
      this.isLoading = true;
      try {
        const response = await CodeService.getList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getDataAPI({});
    },
    setPage(page) {
      this.getDataAPI({ page: page });
    }
  }
};
</script>
