import { API_VALIDADOR as API } from "@/api";
import axios from "axios";

class CompanyService {
  getCompanyList() {
    const response = axios.get(API.company_provider_list);
    return response;
  }
}

const instance = new CompanyService();
export default instance;
