var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"is-half column border p-5",staticStyle:{"margin":"0 auto"}},[_c('div',{attrs:{"title":"","icon":"card-text","image":_vm.logo}},[_c('div',{staticClass:"has-text-centered py-2 mb-3 is-full"},[_c('img',{attrs:{"src":_vm.logo,"alt":"logo"}})]),_c('h3',{staticClass:"mb-3 has-text-weight-bold"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'credit-card'],"custom-size":"default"}}),_vm._v(" Ingrese los siguientes datos ")],1),_c('hr'),_c('b-field',{attrs:{"label":"Email","type":{ 'is-danger': _vm.$v.data.email.$error },"message":[
        {
          'Requerido. Ingresa tu email':
            !_vm.$v.data.email.required && _vm.$v.data.email.$error
        },
        {
          'Incorrecto. Ingresa un email válido':
            !_vm.$v.data.email.email && _vm.$v.data.email.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu email","name":"email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('b-field',{attrs:{"label":"Nombres","type":{ 'is-danger': _vm.$v.data.first_name.$error },"message":[
        {
          'Requerido. Ingresa tu nombre':
            !_vm.$v.data.first_name.required && _vm.$v.data.first_name.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu nombre","name":"first_name"},model:{value:(_vm.data.first_name),callback:function ($$v) {_vm.$set(_vm.data, "first_name", $$v)},expression:"data.first_name"}})],1),_c('b-field',{attrs:{"label":"Apellidos","type":{ 'is-danger': _vm.$v.data.last_name.$error },"message":[
        {
          'Requerido. Ingresa tu apellido':
            !_vm.$v.data.last_name.required && _vm.$v.data.last_name.$error
        }
      ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu apellido","name":"last_name"},model:{value:(_vm.data.last_name),callback:function ($$v) {_vm.$set(_vm.data, "last_name", $$v)},expression:"data.last_name"}})],1),_c('b-field',{attrs:{"label":"Documento"}},[_c('b-input',{attrs:{"value":_vm.document,"disabled":""}})],1),_c('hr'),_c('Button',{attrs:{"spinner":_vm.isLoading,"variant":"gray"},nativeOn:{"click":function($event){return _vm.goBack($event)}}},[_vm._v(" Regresar ")]),_c('Button',{staticClass:"ml-2",attrs:{"variant":"main","spinner":_vm.isLoading},nativeOn:{"click":function($event){return _vm.validateDocument($event)}}},[_vm._v(" Continuar ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }