<template>
  <section class="section">
    <div class="content has-text-grey has-text-centered">
      <template v-if="isLoading">
        <p>
          <b-icon icon="spinner" size="is-large" custom-class="fa-spin" />
        </p>
        <p>Cargando Datos</p>
      </template>
      <template v-else>
        <p>
          <b-icon icon="box-open" size="is-large" />
        </p>
        <p>No hay nada que mostrar</p>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isLoading: Boolean
  }
};
</script>
