<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">Configuración</h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>General</span>
                </template>
                <div class>
                  <b-field
                    label="Razon Social"
                    :type="{ 'is-danger': $v.data.business_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.business_name.required &&
                          $v.data.business_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.business_name"></b-input>
                  </b-field>
                  <b-field
                    label="Nombre"
                    :type="{ 'is-danger': $v.data.name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.name.required && $v.data.name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.name"></b-input>
                  </b-field>
                  <b-field
                    label="RUC"
                    :type="{ 'is-danger': $v.data.ruc.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.ruc.required && $v.data.ruc.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.ruc"></b-input>
                  </b-field>
                  <b-field label="Logo">
                    <Upload :image="data.logo" @change="updateLogo"></Upload>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          :spinner="loading"
          @click.native="updateInfo()"
          block
          >Guardar</Button
        >
      </Aside>
    </div>
  </Layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import CompanyService from "@/services/puntos/CompanyService";
import Upload from "@/components/base/Upload";
export default {
  name: "CompanyInfo",
  components: {
    Layout,
    Card,
    Aside,
    Button,
    Upload
  },
  data() {
    return {
      activeTab: 0,
      data: {
        business_name: "",
        name: "",
        ruc: "",
        logo: ""
      },
      formData: new FormData(),
      loading: false,
      currentPath: this.$route.path.split("/")[1]
    };
  },
  validations: {
    data: {
      business_name: { required },
      name: { required },
      ruc: { required }
    }
  },
  created() {
    this.getInfo();
    console.log(this.currentPath);
  },
  methods: {
    showAlert(type, msg) {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async updateInfo() {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await CompanyService.updateInfo({
            business_name: this.data.business_name,
            name: this.data.name,
            ruc: this.data.ruc
          });
          this.showAlert("S", "Se actualizó la información correctamente.");
        } catch (error) {
          this.showAlert("D", "Ocurrió un error.");
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
        this.showAlert("D", "Complete los campos requeridos.");
      }
    },
    async getInfo() {
      try {
        const { data } = await CompanyService.getInfo();
        this.data = data;
      } catch (error) {
        this.showAlert("D", "Ocurrió un error.");
      }
    },
    async updateLogo(file) {
      this.formData.append("logo", file);
      try {
        this.loading = true;
        const { data } = await CompanyService.updateLogo(this.formData);
        this.data.logo = data.logo;
        this.$buefy.toast.open({
          message: "Se actualizó el logo correctamente.",
          type: "is-success"
        });
      } catch (error) {
        this.showAlert("D", "Ocurrió un error.");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
