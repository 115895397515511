<template>
  <div class="company-list">
    <div class="border p-5 w-full" v-for="(item, index) in 3" :key="index">
      <div class="has-table has-mobile-sort-spaced">
        <div class="columns is-align-items-center">
          <div class="column">
            <figure class="image is-64x64">
              <b-skeleton width="64px" height="64px"></b-skeleton>
            </figure>
          </div>
          <div class="column">
            <b-skeleton width="100%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="scss">
.company-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
}
</style>
