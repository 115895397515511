const BASE_URL = "/puntos";

const routesPuntos = [
  {
    path: `${BASE_URL}/propios`,
    name: "puntos-propios",
    component: () => import("../views/puntos/PointsView.vue"),
    meta: {
      title: "Puntos Propios",
      icon: "cubes",
      sidebar: true,
      hasChildren: true
    },
    children: [
      {
        path: "stock",
        name: "puntos-propios-stock",
        meta: { title: "Stock", icon: "box", sidebar: true },
        component: () =>
          import("../views/puntos/own-points/stock/StockList.vue")
      },
      {
        path: "catalogo",
        name: "puntos-propios-catalogo",
        meta: { title: "Catálogo", icon: "box", sidebar: true },
        component: () =>
          import("../views/puntos/own-points/catalogue/CatalogueList.vue")
      }
      // {
      //   path: "redenciones",
      //   name: "puntos-propios-rendenciones",
      //   meta: { title: "Redenciones", icon: "box", sidebar: true },
      //   component: () =>
      //     import("../views/puntos/own-points/redemption/RedemptionList.vue")
      // }
    ]
  },
  {
    path: `${BASE_URL}/weeare`,
    name: "puntos-weeare",
    component: () => import("../views/puntos/PointsView.vue"),
    meta: {
      title: "Puntos Weeare",
      icon: "shapes",
      sidebar: true,
      hasChildren: true
    },
    children: [
      {
        path: "stock",
        name: "puntos-weeare-stock",
        meta: { title: "Stock", icon: "box", sidebar: true },
        component: () =>
          import("../views/puntos/weeare-points/stock/StockList.vue")
      },
      {
        path: "stock-transfer",
        name: "puntos-weeare-stock-transfer",
        meta: { title: "Stock Transfer", icon: "box", sidebar: false },
        component: () =>
          import("../views/puntos/weeare-points/stock/StockTransfer.vue")
      },
      {
        path: "catalogo",
        name: "puntos-weeare-catalogo",
        meta: { title: "Catálogo", icon: "box", sidebar: true },
        component: () =>
          import("../views/puntos/weeare-points/catalogue/CatalogueList.vue")
      }
      // {
      //   path: "redenciones",
      //   name: "puntos-weeare-rendenciones",
      //   meta: { title: "Redenciones", icon: "box", sidebar: true },
      //   component: () =>
      //     import("../views/puntos/weeare-points/redemption/RedemptionList.vue")
      // }
    ]
  },
  {
    path: `${BASE_URL}/redenciones`,
    name: "puntos-rendenciones",
    meta: { title: "Redenciones", icon: "clipboard-list", sidebar: true },
    component: () => import("../views/puntos/redemption/RedemptionList.vue")
  },
  {
    path: `${BASE_URL}/clientes`,
    name: "puntos-clientes",
    component: () => import("../views/puntos/customer/CustomerList.vue"),
    meta: { title: "Clientes", icon: "users", sidebar: true }
  },
  {
    path: `${BASE_URL}/clientes/crear`,
    name: "puntos-clientes-crear",
    component: () => import("../views/puntos/customer/CustomerCreate.vue"),
    meta: { title: "Clientes", icon: "users", sidebar: false }
  },
  {
    path: `${BASE_URL}/clientes/actualizar/:id`,
    name: "puntos-clientes-actualizar",
    component: () => import("../views/puntos/customer/CustomerUpdate.vue"),
    meta: { title: "Clientes", icon: "users", sidebar: false }
  },
  {
    path: `${BASE_URL}/pages`,
    name: "page_point_list",
    meta: { title: "Páginas", icon: "copy", sidebar: true },
    component: () => import("../views/puntos/pages/PageList.vue")
  },
  {
    path: `${BASE_URL}/pages/add`,
    name: "page_point_add",
    meta: { title: "Añadir Página", icon: "copy", sidebar: false },
    component: () => import("../views/puntos/pages/PageCreate.vue")
  },
  {
    path: `${BASE_URL}/pages/:id`,
    name: "page_point_detail",
    meta: { title: "Detalle Página", icon: "copy", sidebar: false },
    component: () => import("../views/puntos/pages/PageUpdate.vue")
  },
  {
    path: `${BASE_URL}/configuracion`,
    name: "puntos-configuracion",
    meta: { title: "Configuración", icon: "cog", sidebar: true },
    component: () => import("../views/puntos/company/CompanyInfo.vue")
  }
];

export default routesPuntos;
