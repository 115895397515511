import { API_CODIGO as API } from "@/api";
import axios from "axios";
class LoginService {
  login(email, password) {
    const response = axios.post(API.login, {
      email: email,
      password: password
    });
    return response;
  }
}

const instance = new LoginService();
export default instance;
