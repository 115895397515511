<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div class="header-container">
          <div class="is-size-4 has-text-weight-bold">Delivery</div>
          <div class="button-container">
            <Button
              :spinner="isLoading"
              variant="main"
              @click.native="downloadDeliveryPdf"
              >Descargar Rotulado</Button
            >
            <Button
              :spinner="isLoading"
              variant="main-outline"
              @click.native="downloadDeliveryCsv"
              >Descargar Excel</Button
            >
          </div>
        </div>
      </template>
      <template slot="content">
        <div class="filterContainer">
          <div class="column px-0">
            <b-field label="Local:">
              <b-select
                placeholder="Seleccione una opción"
                v-model="query.local"
                icon="home"
                size="is-regular"
                @input="filterDeliveryList"
              >
                <option
                  v-for="local in locals"
                  :key="local.id"
                  :value="local.id"
                  >{{ local.name }}</option
                >
              </b-select>
            </b-field>
          </div>
          <div class="column px-0">
            <b-field label="Estado:">
              <b-select
                placeholder="Elige una opción"
                expanded
                v-model="query.status"
                @input="filterDeliveryList"
              >
                <option :value="null">Todos</option>
                <option v-for="s in status" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column px-0">
            <b-field label="Buscar">
              <b-input
                placeholder="Buscar"
                v-model="query.search"
                @keyup.native="search"
              />
            </b-field>
          </div>
        </div>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          checkable
          :data="results"
          :header-checkable="false"
          :checked-rows.sync="checkedRows"
          :is-row-checkable="row => row.status === 'CF' || row.status === 'EN'"
        >
          <b-table-column
            label="N° Pedido"
            field="code"
            sortable
            v-slot="props"
          >
            <router-link
              class="has-text-weight-bold has-text-primary"
              :to="{ name: 'delivery-detail', params: { id: props.row.id } }"
            >
              {{ props.row.code }}
            </router-link>
          </b-table-column>
          <b-table-column label="Local" field="local.name" v-slot="props">
            {{ props.row.order.local.name }}
          </b-table-column>
          <b-table-column label="Fecha" field="order.creation" v-slot="props">
            {{ props.row.order.creation }}
          </b-table-column>
          <b-table-column label="Nombre" field="full_name" v-slot="props">
            {{ props.row.full_name }}
          </b-table-column>
          <b-table-column label="Teléfono" field="telephone" v-slot="props">
            {{ props.row.telephone }}
          </b-table-column>
          <b-table-column
            label="N° Documento"
            field="order.num_document"
            v-slot="props"
          >
            {{ props.row.order.num_document }}
          </b-table-column>
          <b-table-column label="Monto Pagado" field="total" v-slot="props">
            S/. {{ props.row.order.total }}
          </b-table-column>
          <b-table-column
            sortable
            centered
            label="Estado"
            field="status"
            v-slot="props"
          >
            <b-tag :type="getStatusClass(props.row.status)">
              {{ getStatus(props.row.status) }}
            </b-tag>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Pages
          :links="links"
          :pages="pages"
          :per_page="50"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Pages>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import LocalService from "@/services/validador/LocalService";
import DeliveryService from "@/services/validador/DeliveryService";
import LoadingTable from "@/components/base/LoadingTable";
import Button from "@/components/form/Button";
import Pages from "@/components/Pages";
import formatDate from "@/mixins/formatDate";
export default {
  name: "DeliveryList",
  components: {
    Layout,
    Card,
    LoadingTable,
    Button,
    Pages
  },
  mixins: [formatDate],
  data() {
    return {
      locals: [],
      isLoading: false,
      results: [],
      links: {},
      pages: {},
      checkedRows: [],
      query: {
        local: null,
        status: null,
        search: ""
      },
      status: [
        { id: "PE", name: "Pendiente" },
        { id: "CF", name: "Confirmado" },
        { id: "EN", name: "Enviado" },
        { id: "CP", name: "Completado" },
        { id: "CN", name: "Cancelado" }
      ]
    };
  },
  created() {
    this.getLocals();
  },
  methods: {
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async getLocals() {
      this.isLoading = true;
      try {
        const response = await LocalService.getLocalList();
        this.locals = response.data;
        const localUrl = this.$route.query.local;
        const statusUrl = this.$route.query.status;
        const searchUrl = this.$route.query.search;

        if (localUrl) {
          this.query.local = localUrl;
        } else {
          this.query.local = this.locals[0].id;
        }
        if (statusUrl) {
          this.query.status = statusUrl;
        }
        if (searchUrl) {
          this.query.search = searchUrl;
        }
        await this.getDeliveryList({ ...this.query });
      } catch (error) {
        this.showMessage("D", "No se pudo obtener la lista de locales");
      } finally {
        this.isLoading = false;
      }
    },
    getStatus(status) {
      switch (status) {
        case "PE":
          return "Pendiente";
        case "CF":
          return "Confirmado";
        case "EN":
          return "Enviado";
        case "CP":
          return "Completado";
        case "CN":
          return "Cancelado";
        default:
          return "";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "PE":
          return "is-warning";
        case "CF":
          return "is-twitter is-light";
        case "EN":
          return "is-info";
        case "CP":
          return "is-primary";
        case "CN":
          return "is-danger";
        default:
          return "";
      }
    },
    search() {
      if (this.query.search != null) {
        if (this.query.search.length >= 3) {
          this.filterDeliveryList();
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.filterDeliveryList();
        }
      }
    },
    filterDeliveryList() {
      const newQuery = {};
      if (this.query.local) newQuery.local = this.query.local;
      if (this.query.status) newQuery.status = this.query.status;
      if (this.query.search) newQuery.search = this.query.search;
      this.$router.push({
        query: newQuery
      });
      this.getDeliveryList({ ...this.query });
    },
    async getDeliveryList(params) {
      try {
        this.isLoading = true;
        const response = await DeliveryService.getDeliveryList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.showMessage();
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getDeliveryList({});
    },
    setPage(page) {
      this.getDeliveryList({ ...this.query, page: page });
    },
    async downloadDeliveryPdf() {
      if (this.checkedRows.length === 0) {
        this.showMessage(
          "D",
          "Seleccione uno o varios elementos para descargar."
        );
        return;
      }
      try {
        this.isLoading = true;
        const idList = this.checkedRows.map(item => item.id);
        const response = await DeliveryService.downloadPdf(idList);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Rotulado-Delivery-${fecha.getDate()}/${fecha.getMonth() +
            1}/${fecha.getFullYear()}.pdf`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.checkedRows = [];
      } catch (error) {
        this.showMessage();
      }
      this.isLoading = false;
    },
    async downloadDeliveryCsv() {
      try {
        this.isLoading = true;
        const response = await DeliveryService.downloadCsv({
          local: this.query.local
        });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Order-Delivery-${fecha.getDate()}/${fecha.getMonth() +
            1}/${fecha.getFullYear()}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (error) {
        this.showMessage();
      }
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.b-table .table th.is-sortable {
  border-color: rgba(34, 43, 59, 0.05);
}
</style>
