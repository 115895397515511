<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Nuevo Consumo</div>
        </div>
      </template>
      <template slot="content">
        <CompanyProviderList v-if="step === 0" @update="updateView" />
        <DocumentForm
          v-else-if="step === 1"
          :option="option"
          @goBack="goBack"
          @validateDocument="validateDocument"
          :logo="logo"
        />
        <UnregisteredUser
          v-else-if="step === 2"
          :document="document"
          @goBack="goBack"
        />
        <RegisterUser
          v-else-if="step === 3"
          :option="option"
          :document="document"
          @goBack="goBack"
          :logo="logo"
        />
        <ProductList
          v-else-if="step === 4"
          :option="option"
          :document="document"
          :local="local"
          @goBack="goBack"
          @validateProduct="validateProduct"
        />
        <ValidateForm
          v-else-if="step === 5"
          :option="option"
          :document="document"
          :local="local"
          :idProduct="idProduct"
          @goBack="goBack"
        />
        <ThanksValidate
          v-else-if="step === 6"
          :document="document"
          @goBack="goBack"
        />
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import CompanyProviderList from "./components/CompanyProviderList";
import DocumentForm from "./components/DocumentForm";
import UnregisteredUser from "./components/UnregisteredUser";
import ProductList from "./components/ProductList";
import ValidateForm from "./components/ValidateForm";
import ThanksValidate from "./components/ThanksValidate";
import RegisterUser from "./components/RegisterUser";
export default {
  name: "HomeView",
  components: {
    Layout,
    Card,
    CompanyProviderList,
    DocumentForm,
    UnregisteredUser,
    ProductList,
    ValidateForm,
    ThanksValidate,
    RegisterUser
  },
  data() {
    return {
      isLoading: false,
      document: null,
      step: 0,
      option: null,
      idProduct: null,
      logo: null,
      local: null
    };
  },
  methods: {
    goBack(step) {
      this.step = step;
    },
    updateView(data) {
      this.step = data.step;
      this.option = data.option;
      this.logo = data.logo;
      this.local = data.local;
    },
    validateProduct(data) {
      this.step = data.step;
      this.idProduct = data.idProduct;
    },
    validateDocument(data) {
      this.document = data.document;
      if (data.response === "new") {
        this.step = 2;
      } else if (data.response === "include_register") {
        this.step = 3;
      } else if (data.response === "success") {
        this.step = 4;
      }
    }
  }
};
</script>
