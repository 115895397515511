<template>
  <section class="is-half column border p-5" style="margin: 0 auto;">
    <div>
      <div class="has-text-centered py-2 mb-3 is-full">
        <img :src="logo" alt="logo" class="" />
      </div>
      <h3 class="mb-3 has-text-weight-bold">
        <font-awesome-icon
          :icon="['fas', 'credit-card']"
          custom-size="default"
          class="mr-1"
        />
        Ingrese su N° Documento
      </h3>
      <hr />
      <b-field
        label="DNI"
        :type="{ 'is-danger': $v.document.$error }"
        :message="[
          {
            'Requerido. Ingresa tu N° Documento':
              !$v.document.required && $v.document.$error
          },
          {
            'Incorrecto. Ingresa tu N° Documento':
              !$v.document.numeric && $v.document.$error
          }
        ]"
      >
        <b-input
          v-model="document"
          placeholder="N° Documento"
          name="document"
          @keyup.enter.native="validateDocument"
        />
      </b-field>
      <hr />
      <Button :spinner="isLoading" variant="gray" @click.native="goBack">
        Regresar
      </Button>
      <Button
        variant="main"
        class="ml-2"
        :spinner="isLoading"
        @click.native="validateDocument"
      >
        Continuar
      </Button>
    </div>
  </section>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import ValidateService from "@/services/validador/ValidateService";
import Button from "@/components/form/Button";
export default {
  props: {
    option: Number,
    logo: String
  },
  components: {
    Button
  },
  data() {
    return {
      isLoading: false,
      document: null
    };
  },
  validations: {
    document: { required, numeric }
  },
  methods: {
    goBack() {
      this.$emit("goBack", 0);
    },
    async validateDocument() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true;
          const response = await ValidateService.getValidateDocument({
            num_document: this.document,
            company_provider_id: this.option
          });
          this.$emit("validateDocument", {
            response: response.data.response,
            document: this.document
          });
        } catch (error) {
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: error.response.data.error
          });
        }
        this.isLoading = false;
      }
    }
  }
};
</script>
