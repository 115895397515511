<template>
  <div class="border p-5">
    <h3 class="mb-5 has-text-weight-bold">
      <font-awesome-icon
        :icon="['fas', 'box']"
        custom-size="default"
        class="mr-1"
      />
      Productos
    </h3>
    <hr />
    <div class="has-text-right mb-3">
      <Button variant="gray" @click.native="goBack">
        Regresar
      </Button>
    </div>
    <product-skeleton v-if="isLoading" />
    <div
      v-else
      v-for="product in products"
      :key="product.id"
      class="border p-5 mb-5"
    >
      <div class="is-flex-tablet is-align-items-center" style="gap: 2rem">
        <div>
          <div style="width: 190px">
            <img
              :src="product.image_crop"
              :alt="product.id"
              class="card"
              width="190px"
            />
          </div>
        </div>
        <div style="width: 100%;">
          <h3 class="has-text-weight-medium is-size-4 mb-3">
            {{ product.name }}
          </h3>
          <div v-html="product.description"></div>
          <div
            class="mt-3 is-flex is-justify-content-space-between is-align-items-center"
          >
            <p>
              Disponible:
              {{ getStock(product.stock_client, product.has_stock) }}
            </p>
            <Button variant="main" @click.native="goValidate(product.id)">
              Validar
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="locals.length > 0">
      <h5>Este local no cuenta con Descuentos.</h5>
      <h5>Locales con descuento:</h5>
      <ul>
        <li v-for="(local, index) in locals" :key="index">
          {{ local.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ProductSkeleton from "./ProductSkeleton";
import ValidateService from "@/services/validador/ValidateService";
import Button from "@/components/form/Button";
export default {
  components: {
    ProductSkeleton,
    Button
  },
  props: {
    document: String,
    option: Number,
    local: String
  },
  data() {
    return {
      products: [],
      locals: [],
      isLoading: false,
      stock: ""
    };
  },
  created() {
    this.getProductList();
  },
  methods: {
    goBack() {
      this.$emit("goBack", 0);
    },
    async getProductList() {
      this.isLoading = true;
      try {
        const response = await ValidateService.getProductList({
          num_document: this.document,
          company_provider_id: this.option,
          local_id: this.local
        });
        if (!response.data.products.length) {
          this.$emit("goBack", 2);
          return;
        }
        this.products = response.data.products;
        this.locals = response.data.locals;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error al obtener los productos."
        });
        this.$emit("goBack", 0);
      }
      this.isLoading = false;
    },
    getStock(stockClient, hasStock) {
      return `${stockClient - hasStock} de ${stockClient}`;
    },
    goValidate(id) {
      this.$emit("validateProduct", {
        step: 5,
        idProduct: id
      });
    }
  }
};
</script>
