<template>
  <div>
    <div>
      <img class="productImg" :src="product.image_url" :alt="product.name" />
      <div class="productText">
        <p>
          <strong>Código de integración: {{ product.id }}</strong>
        </p>
        <small>{{ product.name }}</small>
        <small>Stock: {{ product.stock }}</small>
        <small>
          Tipo:
          {{
            product.product.type_service === "D" ? "Delivery" : "Recojo Tienda"
          }}
        </small>
        <small> Segmento: {{ formatSegments(product.product.segment) }} </small>
        <Button
          variant="main"
          class="mt-5"
          :block="true"
          @click.native="detailModal = true"
        >
          Detalles
        </Button>
      </div>
    </div>
    <b-modal v-model="detailModal" :width="600" scroll="keep">
      <div class="card">
        <div>
          <header class="modal-card-head">
            <img
              class="companyImg"
              :src="product.product.company_service.image"
              :alt="product.product.company_service.business_name"
            />
            <p class="modal-card-title">
              {{ product.product.company_service.business_name }}
            </p>
          </header>
          <section class="modal-card-body">
            <span v-html="product.product.terms_conditions"></span>
          </section>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Button from "@/components/form/Button";
export default {
  components: {
    Button
  },
  props: {
    product: {
      type: Object,
      default: () => []
    }
  },
  data() {
    return {
      detailModal: false
    };
  },
  methods: {
    formatSegments(params) {
      let segments = [];
      for (let i = 0; i < params.length; i++) {
        segments.push(params[i].name);
      }
      return segments.join();
    }
  }
};
</script>
