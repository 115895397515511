import { API_CODIGO as API } from "@/api";
import axios from "axios";
class CodeService {
  getList(params) {
    const response = axios.get(API.customer_list, {
      params: params
    });
    return response;
  }

  getProducts(id, params) {
    const response = axios.get(API.customer_products(id), {
      params: params
    });
    return response;
  }

  getCodes(id, params) {
    const response = axios.get(API.customer_codes(id), {
      params: params
    });
    return response;
  }
}

const instance = new CodeService();
export default instance;
