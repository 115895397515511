import { API_PUNTOS as API } from "@/api";
import axios from "axios";

class ClientPointService {
  getList(params = {}) {
    const response = axios.get(API.clients_point, { params });
    return response;
  }

  postClient(data) {
    const response = axios.post(API.clients_point, data);
    return response;
  }

  getClient(id) {
    const response = axios.get(API.clients_point + id + "/");
    return response;
  }

  putClient(id, data) {
    const response = axios.put(API.clients_point + id + "/", data);
    return response;
  }

  validateDocument(num_document) {
    const response = axios.get(API.clients_point_validate_document, {
      params: { num_document }
    });
    return response;
  }
}

const instance = new ClientPointService();
export default instance;
