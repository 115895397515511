import { API_VALIDADOR as API } from "@/api";
import axios from "axios";

class BillingService {
  getBillingList(params = {}) {
    const response = axios.get(API.billing_list, {
      params
    });
    return response;
  }

  downloadBillingPdf(params = {}) {
    const response = axios.get(API.billing_pdf, {
      responseType: "blob",
      params
    });
    return response;
  }

  putBillingInvoice(id, data) {
    const response = axios.put(API.billing_update(id), data);
    return response;
  }
}

const instance = new BillingService();
export default instance;
