<template>
  <div>
    <h4 class="has-text-centered is-size-6 has-text-weight-semibold">
      Categorías más redimidas
    </h4>
    <div v-if="mountGraphic">
      <BarChart
        :information="restaurants"
        :values="values"
        label="Redenciones"
      />
    </div>
    <div v-else class="has-text-centered mt-5">
      Cargando gráfico de categorías más redimidas ...
    </div>
    <hr />
  </div>
</template>

<script>
import BarChart from "@/components/graphics/BarChart";
import GraphicService from "@/services/GraphicService";
export default {
  components: {
    BarChart
  },
  data() {
    return {
      results: [],
      restaurants: [],
      values: [],
      mountGraphic: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const response = await GraphicService.getCategory();
        this.results = response.data;
        for (let i = 0; i < this.results.length; i++) {
          this.restaurants.push(this.results[i].name);
          this.values.push(this.results[i].total);
        }
        setTimeout(() => {
          this.mountGraphic = true;
        }, 500);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
