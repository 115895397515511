var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"is-flex"},[_c('Card',{staticClass:"w-full"},[_c('template',{slot:"header"},[_c('div',{},[_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v("Configuración")])])]),_c('template',{slot:"content"},[_c('section',[_c('b-tabs',{attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("General")])]},proxy:true}])},[_c('div',{},[_c('b-field',{attrs:{"label":"Razon Social","type":{ 'is-danger': _vm.$v.data.business_name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.business_name.required &&
                        _vm.$v.data.business_name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.business_name),callback:function ($$v) {_vm.$set(_vm.data, "business_name", $$v)},expression:"data.business_name"}})],1),_c('b-field',{attrs:{"label":"Nombre","type":{ 'is-danger': _vm.$v.data.name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.name.required && _vm.$v.data.name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('b-field',{attrs:{"label":"RUC","type":{ 'is-danger': _vm.$v.data.ruc.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.ruc.required && _vm.$v.data.ruc.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.ruc),callback:function ($$v) {_vm.$set(_vm.data, "ruc", $$v)},expression:"data.ruc"}})],1),_c('b-field',{attrs:{"label":"Logo"}},[_c('Upload',{attrs:{"image":_vm.data.logo},on:{"change":_vm.updateLogo}})],1)],1)])],1)],1)])],2),_c('Aside',[_c('Button',{attrs:{"variant":"main","spinner":_vm.loading,"block":""},nativeOn:{"click":function($event){return _vm.updateInfo()}}},[_vm._v("Guardar")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }