import { API_PUNTOS as API } from "@/api";
import axios from "axios";

class PointService {
  getInfo() {
    const response = axios.get(API.point_company);
    return response;
  }

  updateInfo(data) {
    const response = axios.post(API.point_company, data);
    return response;
  }

  getPointBagList() {
    const response = axios.get(API.point_bag_list);
    return response;
  }

  getMovementsPoint() {
    const response = axios.get(API.point_movements);
    return response;
  }

  getClientListFilter(params) {
    const response = axios.get(API.clients_point_transfer, { params });
    return response;
  }

  postTransferPoints(data) {
    const response = axios.post(API.point_transfer, data);
    return response;
  }

  getRedemptionsList(params) {
    const response = axios.get(API.order_points, { params });
    return response;
  }
}

const instance = new PointService();
export default instance;
