var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login__container container"},[_c('div',{staticClass:"columns"},[_vm._m(0),_c('div',{staticClass:"column"},[_c('div',{staticClass:"has-text-left form"},[_c('h3',{staticClass:"title login__title"},[_vm._v("¡Bienvenido!")]),_c('p',[_vm._v(" Escribe tu correo electronico y contraseña para ingresar al panel. ")]),_c('form',{staticClass:"mt-5",attrs:{"action":""}},[(_vm.message !== '')?_c('b-field',{attrs:{"type":"is-danger","message":_vm.message}}):_vm._e(),_c('b-field',{attrs:{"label":"Email","type":{ 'is-danger': _vm.$v.email.$error },"message":[
                { 'Campo requerido': !_vm.$v.email.required && _vm.$v.email.$error },
                {
                  'Formato incorrecto': !_vm.$v.email.email && _vm.$v.email.$error
                }
              ]}},[_c('b-input',{attrs:{"placeholder":"Ingresa tu correo"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1),_c('div',{}),_c('b-field',{attrs:{"label":"Password","type":{ 'is-danger': _vm.$v.password.$error },"message":[
                {
                  'Campo requerido':
                    !_vm.$v.password.required && _vm.$v.password.$error
                }
              ]}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}})],1),_c('Button',{staticClass:"login__button mt-3",attrs:{"spinner":_vm.loading,"variant":"main"},nativeOn:{"click":function($event){return _vm.sendLogin($event)}}},[_vm._v("Ingresar")])],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('img',{attrs:{"src":"/ilu.png","alt":""}})])}]

export { render, staticRenderFns }