const BASE_URL = "/admin/provider";

const routesAdmin = [
  {
    path: `${BASE_URL}/mi-empresa`,
    name: "company-admin",
    meta: { title: "Mi Empresa", icon: "industry", sidebar: true },
    component: () => import("../views/admin/company/CompanyInfo.vue")
  },
  {
    path: `${BASE_URL}/clientes`,
    name: "clients",
    meta: { title: "Clientes", icon: "user-tie", sidebar: true },
    component: () => import("../views/admin/clients/ClientList.vue")
  },
  {
    path: `${BASE_URL}/clientes/crear`,
    name: "client_create",
    meta: { title: "Crear Cliente", icon: "user-tie", sidebar: false },
    component: () => import("../views/admin/clients/ClientCreate.vue")
  },
  {
    path: `${BASE_URL}/clientes/editar/:id`,
    name: "client_update",
    meta: { title: "Editar Cliente", icon: "user-tie", sidebar: false },
    component: () => import("../views/admin/clients/ClientUpdate.vue")
  }
];

export default routesAdmin;
