<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Clientes</div>
        </div>
      </template>
      <template slot="content">
        <div class="filterContainer">
          <b-field label="Buscar: ">
            <b-input
              placeholder="N° Documento ..."
              class="inputSearch"
              v-model="query.search"
              type="search"
              icon="search"
              @keyup.native="searchInput"
            >
            </b-input>
          </b-field>
        </div>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          detail-key="id"
          icon-pack="fas"
        >
          <b-table-column field="document" label="N° Documento" v-slot="props">
            <router-link
              :to="{
                name: 'customer_detail',
                params: { id: props.row.num_document }
              }"
              class="has-text-weight-semibold"
              >{{ props.row.num_document }}</router-link
            >
          </b-table-column>
          <b-table-column field="name" label="Nombre" v-slot="props">
            <span>{{ props.row.first_name }} {{ props.row.last_name }}</span>
          </b-table-column>
          <b-table-column field="email" label="Email" v-slot="props">
            <span>{{ props.row.email }}</span>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import CustomerService from "@/services/CustomerService";
import Page from "@/components/Pages";
import LoadingTable from "@/components/base/LoadingTable";
export default {
  name: "CodeList",
  components: {
    Layout,
    Card,
    Page,
    LoadingTable
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true,
      query: {
        search: ""
      }
    };
  },
  created() {
    this.getDataAPI({});
  },
  methods: {
    async getDataAPI(params) {
      this.isLoading = true;
      try {
        const response = await CustomerService.getList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getDataAPI({});
    },
    setPage(page) {
      this.getDataAPI({ page: page });
    },
    searchInput() {
      if (this.query.search !== null) {
        if (this.query.search.length >= 3) {
          this.getDataAPI({ ...this.query });
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.getDataAPI({ ...this.query });
        }
      }
    }
  }
};
</script>
