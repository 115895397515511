import { API_CODIGO as API } from "@/api";
import axios from "axios";
class GraphicService {
  getOrder(params) {
    const response = axios.get(API.graphic_order, { params });
    return response;
  }

  getCategory() {
    const response = axios.get(API.graphic_category);
    return response;
  }

  getCompany() {
    const response = axios.get(API.graphic_company);
    return response;
  }

  getCodes() {
    const response = axios.get(API.graphic_codes);
    return response;
  }
}

const instance = new GraphicService();
export default instance;
