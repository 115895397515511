<template>
  <b-modal v-model="modalConfirm" :width="468" scroll="keep">
    <div class="card">
      <div class="card-content">
        <h3 class="is-size-4 has-text-weight-bold my-5">
          Confirmar Pedido
        </h3>
        <hr />
        <section>
          <b-field
            label="Fecha"
            :type="{ 'is-danger': $v.date.$error }"
            :message="[
              {
                'Requerido. Selecciona una fecha':
                  !$v.date.required && $v.date.$error
              }
            ]"
          >
            <b-datepicker
              v-model="date"
              placeholder="Elige una fecha"
              :append-to-body="true"
              :min-date="currentDate"
              @input="validateDate"
            >
            </b-datepicker>
          </b-field>
          <b-field label="Horario" v-if="type_date">
            <b-select
              v-if="type_date == 'today'"
              placeholder="Elige una opción"
              v-model="form.arrive_minute"
              expanded
            >
              <option
                v-for="item in arrive_minutes"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </b-select>
            <b-select
              v-if="type_date == 'out_day'"
              placeholder="Elige una opción"
              v-model="form.arrive_hour"
              expanded
            >
              <option
                v-for="item in arrive_hours"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </b-select>
          </b-field>
        </section>
        <hr />
        <div
          class="is-flex is-justify-content-flex-end w-full is-align-items-center"
        >
          <div>
            <Button variant="gray" @click.native="modalConfirm = false"
              >Cancelar</Button
            >
          </div>
          <div class="ml-3">
            <Button
              variant="main"
              :spinner="isLoading"
              @click.native="postChangeStatus"
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Button from "@/components/form/Button";
import formatDate from "@/mixins/formatDate";
import DeliveryService from "@/services/validador/DeliveryService";
export default {
  props: {
    currentDate: Date
  },
  mixins: [formatDate],
  components: {
    Button
  },
  data() {
    return {
      modalConfirm: true,
      isLoading: false,
      date: [],
      type_date: "",
      form: {
        arrive_date: "",
        arrive_hour: "",
        arrive_minute: "",
        today: null
      },
      arrive_minutes: [
        { value: 1, name: "30 min" },
        { value: 2, name: "45 min" },
        { value: 3, name: "1 hora" },
        { value: 4, name: "1 hora 15 min" },
        { value: 5, name: "1 hora 30 min" },
        { value: 6, name: "2 horas " }
      ],
      arrive_hours: [{ value: 1, name: "9 am - 9 pm" }]
    };
  },
  validations: {
    date: { required }
  },
  watch: {
    modalConfirm() {
      this.$emit("hidden");
    }
  },
  methods: {
    async postChangeStatus() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true;
          await DeliveryService.postChangeStatus(
            this.$route.params.id,
            this.form
          );
          await this.$emit("reload");
          this.modalConfirm = false;
        } catch (error) {
          this.isLoading = false;
          const message = "Selecciona un horario de entrega";
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: message
          });
        }
      }
    },
    validateDate() {
      this.form.arrive_minute = "";
      this.form.arrive_hour = "";
      this.form.arrive_date = this.formatDateReverse(this.date);
      if (this.form.arrive_date === this.formatDateReverse(this.currentDate)) {
        this.type_date = "today";
        this.form.today = true;
      } else {
        this.type_date = "out_day";
        this.form.today = false;
      }
    }
  }
};
</script>
