<template>
  <div>
    <label class="upload control w-full">
      <div class="upload-draggable is-primary w-full">
        <section class="upload-content">
          <div class="content has-text-centered">
            <img v-if="baseImage" class="upload-image" :src="baseImage" alt />
            <p v-else class="upload-icon">
              <span class="icon is-large is-size-3">
                <font-awesome-icon
                  :icon="['fas', 'upload']"
                  class="is-size-2"
                />
              </span>
            </p>
            <p class="upload-help">
              Haz clic para seleccionar una imagen
            </p>
          </div>
        </section>
      </div>
      <input type="file" ref="fileInput" @change="uploadImage($event)" />
    </label>
  </div>
</template>

<script>
export default {
  name: "Upload",
  props: {
    image: String
  },
  data() {
    return {
      baseImage: this.image
    };
  },
  watch: {
    image() {
      this.baseImage = this.image;
    }
  },
  methods: {
    uploadImage() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.baseImage = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("change", files[0]);
      }
    }
  }
};
</script>

<style lang="scss">
.upload {
  &-content {
    padding: 10px 40px 10px 30px;
  }
  &-icon {
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-help {
    margin-top: 10px;
  }
  &-image {
    max-height: 260px;
    object-fit: cover;
  }
}
</style>
