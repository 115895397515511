<template>
  <Layout>
    <div style="display: grid;">
      <Card class="w-full">
        <template slot="header">
          <div
            class="is-flex is-justify-content-space-between w-full is-align-items-center"
          >
            <div class="is-size-4 has-text-weight-bold">Clientes</div>
            <Button
              tag="router-link"
              :to="{ name: 'puntos-clientes-crear' }"
              variant="main"
            >
              Crear
            </Button>
          </div>
        </template>
        <template slot="content">
          <div class="filterContainer">
            <b-field label="Buscar: ">
              <b-input
                placeholder="Buscar cliente ..."
                class="inputSearch"
                v-model="query.search"
                type="search"
                icon="search"
                @keyup.native="searchInput"
              >
              </b-input>
            </b-field>
            <b-field label="Estado:">
              <b-select
                placeholder="Seleccione una opción"
                v-model="query.is_active"
                icon="check"
                size="is-regular"
                @input="getList"
              >
                <option :value="null">Todos</option>
                <option :value="true">Activo</option>
                <option :value="false">Inactivo</option>
              </b-select>
            </b-field>
          </div>
          <b-table
            :loading="isLoading"
            hoverable
            striped
            :data="results"
            detail-key="id"
            icon-pack="fas"
          >
            <b-table-column field="name" label="Nombre" v-slot="props">
              <router-link
                :to="{
                  name: 'puntos-clientes-actualizar',
                  params: { id: props.row.id }
                }"
                class="has-text-weight-semibold"
                >{{ props.row.client.first_name }}</router-link
              >
            </b-table-column>
            <b-table-column field="last_name" label="Apellidos" v-slot="props">
              <span>{{ props.row.client.last_name }}</span>
            </b-table-column>
            <b-table-column field="email" label="Email" v-slot="props">
              <span>{{ props.row.client.email }}</span>
            </b-table-column>
            <b-table-column
              field="num_document"
              label="Documento"
              v-slot="props"
            >
              <span>{{ props.row.client.num_document }}</span>
            </b-table-column>
            <b-table-column field="stars" label="Puntos Propios" v-slot="props">
              <span>{{ props.row.stars }}</span>
            </b-table-column>
            <b-table-column field="points" label="Puntos Weeare" v-slot="props">
              <span>{{ props.row.points }}</span>
            </b-table-column>
            <b-table-column field="is_active" label="Estado" v-slot="props">
              <font-awesome-icon
                v-if="props.row.client.is_active"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </b-table-column>
            <LoadingTable slot="empty" :isLoading="isLoading" />
          </b-table>
          <hr style="margin-top: 0;" />
          <Page
            :links="links"
            :pages="pages"
            v-if="pages"
            @set-link="setLink"
            @set-page="setPage"
          ></Page>
        </template>
      </Card>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import ClientPointService from "@/services/puntos/ClientPointService";
import Page from "@/components/Pages";
import LoadingTable from "@/components/base/LoadingTable";
import Button from "@/components/form/Button";
export default {
  name: "CustomerList",
  components: {
    Layout,
    Card,
    Page,
    LoadingTable,
    Button
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true,
      query: {
        is_active: null,
        search: null,
        page: 1
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      try {
        const response = await ClientPointService.getList(this.query);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getList();
    },
    setPage(page) {
      this.query.page = page;
      this.getList();
    },
    searchInput() {
      if (this.query.search !== null) {
        if (this.query.search.length >= 3) {
          this.getList();
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.getList();
        }
      }
    }
  }
};
</script>
