<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Páginas</div>
          <Button
            tag="router-link"
            :to="{
              name: 'page_add'
            }"
            variant="main"
            >Crear</Button
          >
        </div>
      </template>
      <template slot="content">
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          detail-key="id"
          icon-pack="fas"
        >
          <b-table-column field="logo" label="Logo" v-slot="props">
            <img width="60" :src="props.row.logo_company" alt="Logo Empresa" />
          </b-table-column>
          <b-table-column field="document" label="Nombre" v-slot="props">
            <router-link
              :to="{
                name: 'page_detail',
                params: { id: props.row.id }
              }"
              class="has-text-weight-semibold"
              >{{ props.row.name }}</router-link
            >
          </b-table-column>
          <b-table-column field="Subdomain" label="Subdominio" v-slot="props">
            <span>{{ props.row.subdomain || "---" }}</span>
          </b-table-column>
          <b-table-column field="is_active" label="Activo" v-slot="props">
            <font-awesome-icon
              v-if="props.row.is_active"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-else
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import PageService from "@/services/PageService";
import Page from "@/components/Pages";
import Button from "@/components/form/Button";
import LoadingTable from "@/components/base/LoadingTable";
export default {
  name: "CodeList",
  components: {
    Layout,
    Card,
    Page,
    Button,
    LoadingTable
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true,
      query: {
        search: ""
      }
    };
  },
  created() {
    this.getDataAPI();
  },
  methods: {
    async getDataAPI() {
      this.isLoading = true;
      try {
        const response = await PageService.getList();
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error."
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getDataAPI({});
    },
    setPage(page) {
      this.getDataAPI({ page: page });
    }
  }
};
</script>
