<template>
  <b-modal v-model="showModal" :width="550" scroll="keep">
    <div class="card">
      <div class="card-content py-5">
        <h3 class="is-size-4 has-text-weight-bold mb-5">
          Seleccione un lote de código
        </h3>
        <hr />
        <div>
          <b-table
            class="codeList"
            :data="isEmpty ? [] : codeList"
            :loading="loading"
          >
            <b-table-column
              field="description"
              label="Descripción"
              v-slot="props"
            >
              <span>{{ props.row.name }} </span>
            </b-table-column>
            <b-table-column field="button" label="" v-slot="props">
              <div class="has-text-right">
                <b-button
                  type="is-primary"
                  size="is-small"
                  @click="selectCode(props.row)"
                >
                  Seleccionar
                </b-button>
              </div>
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">Sin datos</div>
            </template>
          </b-table>
          <Pages
            :links="links"
            :pages="pages"
            :per_page="5"
            v-if="pages"
            @set-link="setLink"
            @set-page="setPage"
          ></Pages>
          <hr />
          <div
            class="is-flex is-justify-content-flex-end w-full is-align-items-center"
          >
            <Button variant="gray" @click.native="showModal = false">
              Cerrar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from "@/components/form/Button";
import MessageService from "@/services/MessageService";
import Pages from "@/components/Pages";
export default {
  components: {
    Button,
    Pages
  },
  data() {
    return {
      showModal: true,
      loading: false,
      codeList: [],
      isEmpty: false,
      links: {},
      pages: {}
    };
  },
  watch: {
    showModal() {
      this.$emit("hidden");
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo(params) {
      try {
        const response = await MessageService.getListCodes(params);
        this.codeList = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
        this.pages.per_page = 5;
        this.isEmpty = this.codeList.length === 0;
      } catch (error) {
        this.loading = false;
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error."
        });
        this.showModal = false;
      }
    },
    setLink() {
      this.getInfo({});
    },
    setPage(page) {
      this.getInfo({ page: page });
    },
    selectCode(data) {
      this.$emit("getCode", data);
      this.showModal = false;
    }
  }
};
</script>
