<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">Agregar Sitio</h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>General</span>
                </template>
                <div>
                  <b-field label="Activo">
                    <b-switch
                      v-model="data.is_active"
                      type="is-success"
                    ></b-switch>
                  </b-field>
                  <b-field
                    label="Nombre"
                    :type="{ 'is-danger': $v.data.name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.name.required && $v.data.name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.name"></b-input>
                  </b-field>
                  <b-field
                    label="Título SEO"
                    :type="{ 'is-danger': $v.data.meta_title.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.meta_title.required &&
                          $v.data.meta_title.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.meta_title"></b-input>
                  </b-field>
                  <b-field
                    label="Descripción SEO"
                    :type="{
                      'is-danger': $v.data.meta_description.$error
                    }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.meta_description.required &&
                          $v.data.meta_description.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.meta_description"></b-input>
                  </b-field>
                  <b-field label="ID Google Analytics (Opcional)">
                    <b-input v-model="data.analytics"></b-input>
                  </b-field>
                  <b-field
                    label="Subdominio"
                    :type="{ 'is-danger': $v.data.subdomain.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.subdomain.required &&
                          $v.data.subdomain.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.subdomain"></b-input>
                  </b-field>
                  <!-- <b-field label="Dominio">
                    <div
                      v-for="(item, index) in data.domains"
                      :key="index"
                      style="display: flex;"
                      class="mb-5"
                    >
                      <b-input
                        style="width: 100%;"
                        v-model="data.domains[index]"
                      />
                      <div style="width: 50px;" v-if="index != 0">
                        <b-button
                          type="is-danger"
                          style="height: 50px;"
                          @click="deleteDomain(index)"
                        >
                          <font-awesome-icon :icon="['fas', 'trash']" />
                        </b-button>
                      </div>
                    </div>
                  </b-field>
                  <b-button
                    type="is-primary"
                    size="is-small"
                    @click="addDomain"
                    :disabled="data.domains[0] == ''"
                  >
                    Agregar Dominio
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </b-button> -->
                </div>
              </b-tab-item>
              <b-tab-item>
                <template #header>
                  <span>Apariencia</span>
                </template>
                <div>
                  <b-field label="Tema">
                    <b-select
                      placeholder="Seleccione una opción"
                      v-model="data.theme"
                      expanded
                      class="selectForm"
                    >
                      <option value="L">Claro (Light)</option>
                      <option value="D">Oscuro (Dark)</option>
                    </b-select>
                  </b-field>
                  <b-field label="Color Corporativo">
                    <b-input
                      style="width: 150px"
                      type="color"
                      v-model="data.color"
                    />
                    <b-input style="width: 100%" v-model="data.color"></b-input>
                  </b-field>
                  <b-field
                    label="Logo"
                    :custom-class="
                      $v.data.logo_company.$error ? 'has-text-danger' : ''
                    "
                    :type="{ 'is-danger': $v.data.logo_company.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.logo_company.required &&
                          $v.data.logo_company.$error
                      }
                    ]"
                  >
                    <Upload
                      :image="data.logo_company"
                      :class="$v.data.logo_company.$error ? 'required' : ''"
                      @change="uploadFileLogo"
                    ></Upload>
                  </b-field>
                  <b-field label="Favicon">
                    <Upload
                      :image="data.icon_company"
                      @change="uploadFileIcon"
                    ></Upload>
                  </b-field>
                  <b-field
                    label="Imagen Login Desktop"
                    :custom-class="
                      $v.data.banner.$error ? 'has-text-danger' : ''
                    "
                    :type="{ 'is-danger': $v.data.banner.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.banner.required && $v.data.banner.$error
                      }
                    ]"
                  >
                    <Upload
                      :image="data.banner"
                      :class="$v.data.banner.$error ? 'required' : ''"
                      @change="uploadFileBanner"
                    ></Upload>
                  </b-field>
                  <b-field
                    label="Imagen Login Mobile"
                    :custom-class="
                      $v.data.banner_mobile.$error ? 'has-text-danger' : ''
                    "
                    :type="{ 'is-danger': $v.data.banner_mobile.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.banner_mobile.required &&
                          $v.data.banner_mobile.$error
                      }
                    ]"
                  >
                    <Upload
                      :image="data.banner_mobile"
                      :class="$v.data.banner_mobile.$error ? 'required' : ''"
                      @change="uploadFileBannerMobile"
                    ></Upload>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          class="mb-2"
          :spinner="loading"
          @click.native="postPage()"
          block
        >
          Guardar
        </Button>
        <Button
          tag="router-link"
          :to="{ name: 'page_list' }"
          variant="gray"
          block
        >
          Cancelar
        </Button>
      </Aside>
    </div>
  </Layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import PageService from "@/services/PageService";
import Upload from "@/components/base/Upload";
export default {
  name: "CompanyInfo",
  components: {
    Layout,
    Card,
    Aside,
    Button,
    Upload
  },
  data() {
    return {
      activeTab: 0,
      data: {
        is_active: true,
        name: "",
        meta_title: "",
        meta_description: "",
        theme: "L",
        subdomain: "",
        domains: [""],
        color: "#00b16c",
        logo_company: "",
        icon_company: "",
        banner: "",
        banner_mobile: "",
        analytics: ""
      },
      formData: new FormData(),
      loading: false
    };
  },
  validations: {
    data: {
      name: { required },
      meta_title: { required },
      meta_description: { required },
      logo_company: { required },
      banner: { required },
      banner_mobile: { required },
      subdomain: { required }
    }
  },
  methods: {
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    addDomain() {
      if (this.data.domains.length > 0) {
        const item = this.data.domains.length - 1;
        if (this.data.domains[item] !== "") {
          this.data.domains.push("");
        }
      }
    },
    deleteDomain(index) {
      this.data.domains.splice(index, 1);
    },
    async postPage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          this.formData.append("is_active", this.data.is_active);
          this.formData.append("name", this.data.name);
          this.formData.append("analytics", this.data.analytics);
          this.formData.append("meta_title", this.data.meta_title);
          this.formData.append("meta_description", this.data.meta_description);
          this.formData.append("theme", this.data.theme);
          this.formData.append("subdomain", this.data.subdomain);
          for (let i = 0; i < this.data.domains.length; i++) {
            this.formData.append(`domains[${i}]`, this.data.domains[i]);
          }
          this.formData.append("color", this.data.color);
          await PageService.postPage(this.formData);
          this.loading = false;
          this.showMessage("S", "Se guardó correctamente");
          this.$router.push({ name: "page_list" });
        } catch (error) {
          this.loading = false;
          this.showMessage();
        }
      } else {
        this.showMessage();
      }
    },
    uploadFileLogo(file) {
      if (file) {
        this.formData.append("logo_company", file);
        this.data.logo_company = "ok";
      }
    },
    uploadFileIcon(file) {
      if (file) {
        this.formData.append("icon_company", file);
        this.data.icon_company = "ok";
      }
    },
    uploadFileBanner(file) {
      if (file) {
        this.formData.append("banner", file);
        this.data.banner = "ok";
      }
    },
    uploadFileBannerMobile(file) {
      if (file) {
        this.formData.append("banner_mobile", file);
        this.data.banner_mobile = "ok";
      }
    }
  }
};
</script>

<style lang="scss">
.scroll-container {
  max-height: 250px;
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(183, 192, 207, 0.7);
  border-radius: 5px;
  overflow-y: auto;
}
.required {
  border-color: $color-red;
  .upload-draggable {
    border-color: $color-red;
  }
}
</style>
