<template>
  <div>
    <div class="border p-3 mb-5">
      <div class="column is-one-third">
        <h3 class="mb-5 has-text-weight-bold">
          <font-awesome-icon
            :icon="['fas', 'store']"
            custom-size="default"
            class="mr-1"
          />
          Selecciona un local
        </h3>
        <b-field>
          <b-select
            expanded
            placeholder="Elige una opción"
            v-model="local"
            @input="() => $localStorage.set('local', local)"
          >
            <option
              v-for="localItem in localList"
              :value="localItem.id"
              :key="localItem.id"
            >
              {{ localItem.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div>
      <div class="border p-5">
        <h3 class="mb-5 has-text-weight-bold">
          <font-awesome-icon
            :icon="['fas', 'list']"
            custom-size="default"
            class="mr-1"
          />
          Selecciona un programa de beneficios
        </h3>
        <CompanyProviderSkeleton v-if="isLoading" />
        <div class="company-list" v-else>
          <div
            class="w-full border p-5"
            v-for="company in results"
            :key="company.id"
          >
            <div class="is-clickable" @click="chooseOption(company)">
              <div class="columns is-align-items-center">
                <div class="column">
                  <figure class="image is-64x64">
                    <img
                      :src="company.image_logo_crop"
                      class="card"
                      alt="company"
                    />
                  </figure>
                </div>
                <div class="column">
                  <span class="has-text-weight-bold">
                    {{ company.name.toUpperCase() }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyService from "@/services/validador/CompanyService";
import LocalService from "@/services/validador/LocalService";
import CompanyProviderSkeleton from "./CompanyProviderSkeleton";
export default {
  components: {
    CompanyProviderSkeleton
  },
  data() {
    return {
      option: null,
      results: [],
      isLoading: false,
      local: null,
      localList: []
    };
  },
  created() {
    this.getCompanyList();
    this.getLocals();
  },
  methods: {
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async getLocals() {
      this.isLoading = true;
      try {
        const response = await LocalService.getLocalList();
        this.localList = response.data;
        const isLocalSaved = this.$localStorage.get("local");

        if (isLocalSaved) {
          this.local = isLocalSaved;
        } else {
          this.local = this.localList[0].id;
          this.$localStorage.set("local", this.local);
        }
      } catch (error) {
        this.showMessage();
      } finally {
        this.isLoading = false;
      }
    },
    async getCompanyList() {
      this.isLoading = true;
      try {
        const response = await CompanyService.getCompanyList();
        this.results = response.data;
      } catch (error) {
        this.showMessage();
      } finally {
        this.isLoading = false;
      }
    },
    chooseOption(company) {
      this.$emit("update", {
        step: 1,
        option: company.id,
        logo: company.image_logo_crop,
        local: this.local
      });
    }
  }
};
</script>
<style lang="scss">
.company-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
}
</style>
