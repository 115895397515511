<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">
              Enviar Mensajes
            </h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>Información</span>
                </template>
                <div>
                  <b-field label="Tipo">
                    <b-select
                      placeholder="Seleccione una opción"
                      v-model="data.type"
                      expanded
                      class="selectForm"
                      @input="deleteTemplateSelected"
                    >
                      <option value="SMS">Mensaje de Texto</option>
                      <option value="WP" v-if="enableWhatsapp">
                        Whatsapp
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Lote de Códigos">
                    <div
                      class="custom-border is-clickable"
                      :class="{
                        'is-danger': $v.code.id.$error && !$v.code.id.required
                      }"
                      @click="codeMessageList = true"
                    >
                      <small>
                        {{ code.name || "Seleccione una opción" }}
                      </small>
                      <b-tag type="is-success">
                        {{ code.name ? "Cambiar" : "Elegir" }}
                      </b-tag>
                    </div>
                  </b-field>
                  <b-field label="Plantilla de mensaje">
                    <div
                      class="custom-border is-clickable"
                      :class="{
                        'is-danger':
                          $v.template.id.$error && !$v.template.id.required
                      }"
                      @click="templateMessageList = true"
                    >
                      <small>
                        {{ template.description || "Seleccione una opción" }}
                      </small>
                      <b-tag type="is-success">
                        {{ template.description ? "Cambiar" : "Elegir" }}
                      </b-tag>
                    </div>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          @click.native="sendNotification"
          class="mb-2"
          block
        >
          Enviar
        </Button>
        <Button
          variant="main-outline"
          @click.native="openModalTemplate"
          class="mb-2"
          block
        >
          Nuevo Template
        </Button>
        <Button
          tag="router-link"
          :to="{ name: 'message_list' }"
          variant="gray"
          block
          >Regresar</Button
        >
      </Aside>
      <TemplateMessageAdd
        v-if="templateMessageAdd"
        @hidden="templateMessageAdd = false"
      />
      <TemplateWhatsappAdd
        v-if="templateWhatsappAdd"
        @hidden="templateWhatsappAdd = false"
      />
      <TemplateMessageList
        v-if="templateMessageList"
        @hidden="templateMessageList = false"
        @getTemplate="updateTemplate"
        :type_template="data.type"
      />
      <CodeMessageList
        v-if="codeMessageList"
        @hidden="codeMessageList = false"
        @getCode="updateCode"
      />
    </div>
  </Layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import TemplateMessageAdd from "@/components/message/TemplateMessageAdd";
import TemplateWhatsappAdd from "@/components/message/TemplateWhatsappAdd";
import TemplateMessageList from "@/components/message/TemplateMessageList";
import CodeMessageList from "@/components/message/CodeMessageList";
import MessageService from "@/services/MessageService";
export default {
  name: "MessageCreate",
  components: {
    Layout,
    Card,
    Aside,
    Button,
    TemplateMessageAdd,
    TemplateMessageList,
    CodeMessageList,
    TemplateWhatsappAdd
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      templateMessageAdd: false,
      templateWhatsappAdd: false,
      templateMessageList: false,
      codeMessageList: false,
      code: {
        id: null,
        name: ""
      },
      template: {
        description: "",
        id: null
      },
      data: {
        generate_code: null,
        template: null,
        type: "SMS",
        status: "PR",
        total_messages_sent: 0
      },
      enableWhatsapp: undefined
    };
  },
  validations: {
    code: {
      id: { required }
    },
    template: {
      id: { required }
    }
  },
  mounted() {
    this.enableWhatsapp = process.env.VUE_APP_WHATSAPP;
    console.log(process.env.VUE_APP_WHATSAPP || "VUE_APP_WHATSAPP Not Found");
  },
  methods: {
    deleteTemplateSelected() {
      this.template.description = "";
      this.template.id = null;
    },
    openModalTemplate() {
      if (this.data.type === "SMS") {
        this.templateMessageAdd = true;
      } else {
        this.templateWhatsappAdd = true;
      }
    },
    updateTemplate(data) {
      this.template = data;
    },
    updateCode(data) {
      this.code = data;
    },
    async sendNotification() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          this.data.generate_code = this.code.id;
          this.data.template = this.template.id;
          await MessageService.postNew(this.data);
          this.$router.push({ name: "message_list" });
        } catch (error) {
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: "Ocurrió un error."
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
.custom-border {
  border: 1px solid #d1d1d1;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.3rem;
  overflow: hidden;
  gap: 1rem;
  &:hover {
    border-color: #b5b5b5;
  }
  &.is-danger {
    border-color: #f14668;
  }
}
</style>
