<template>
  <component
    :is="tag"
    v-bind="$attrs"
    class="btn"
    :class="[
      getClass,
      {
        'is-full': block
      }
    ]"
  >
    <b-loading
      v-if="spinner"
      :is-full-page="false"
      v-model="isLoading"
    ></b-loading>
    <span :class="{ 'is-invisible': spinner }"><slot></slot></span>
  </component>
</template>
<script>
export default {
  name: "Button",
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: "button"
    },
    block: Boolean,
    spinner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    getClass() {
      switch (this.variant) {
        case "main":
          return "is-main";
        case "main-outline":
          return "is-main-outline";
        case "gray":
          return "is-gray";
        case "danger":
          return "is-danger";
        default:
          return "";
      }
    }
  }
};
</script>
<style lang="scss">
.btn {
  text-transform: capitalize;
  font-size: 14px;
  padding: 12px 28px;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-border;
  transition: all 0.15s ease-in-out;
  outline: none;
  font-family: $font-bold;
  min-height: 44px;
  text-align: center;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.is-full {
    width: 100%;
    display: block;
  }
  &.is-main {
    background: $color-main;
    color: $color-white;
    border: 1px solid $color-main;
    &:hover {
      box-shadow: 0 1px 3px $color-main;
      color: $color-white;
      border: 1px solid darken($color-main, 3%);
      background: darken($color-main, 3%);
    }
  }
  &.is-main-outline {
    background: $color-white;
    color: $color-main;
    border: 2px solid $color-main;
    &:hover {
      box-shadow: 0 1px 3px $color-main;
      color: $color-white;
      border: 2px solid darken($color-main, 3%);
      background: darken($color-main, 3%);
    }
  }
  &.is-gray {
    background: $color-gray-dark;
    color: $color-white;
    border: 1px solid $color-gray;
    &:hover {
      box-shadow: 0 1px 3px $color-gray-dark;
      color: $color-white;
      border: 0.5px solid darken($color-gray-dark, 3%);
      background: darken($color-gray-dark, 3%);
    }
  }
  &.is-danger {
    background: $color-red;
    color: $color-white;
    border: 1px solid $color-red;
    &:hover {
      box-shadow: 0 1px 3px $color-red-dark;
      color: $color-white;
      border: 1px solid darken($color-red-dark, 3%);
      background: darken($color-red-dark, 3%);
    }
  }
}
</style>
