<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Mis redenciones</div>
          <Button
            :spinner="isLoading"
            variant="main"
            @click.native="descargarExcel"
            >Descargar Reporte</Button
          >
        </div>
      </template>
      <template slot="content">
        <div class="filterContainer">
          <div class="column px-0">
            <b-field label="Buscar: ">
              <b-input
                placeholder="Email, Nombre, DNI"
                class="inputSearch"
                v-model="query.search"
                type="search"
                icon="search"
                @keyup.native="searchInput"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column px-0">
            <b-field label="Estado:">
              <b-select
                placeholder="Seleccione una opción"
                v-model="query.status"
                icon="stream"
                @input="filterQuery"
                size="is-regular"
              >
                <option :value="null">Todos</option>
                <option value="PO">Por Completar</option>
                <option value="O">Completado</option>
              </b-select>
            </b-field>
          </div>
          <div class="column px-0">
            <b-field label="Fecha desde: ">
              <b-datepicker
                size="is-regular"
                v-model="created_from"
                locale="es-PE"
                placeholder="Elige una fecha"
                icon="calendar-day"
                @input="filterQuery"
              >
                <button class="button is-danger" @click="clearCreatedFrom">
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  <span class="ml-3">Limpiar</span>
                </button>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column px-0">
            <b-field label="Fecha hasta: ">
              <b-datepicker
                size="is-regular"
                v-model="created_to"
                locale="es-PE"
                placeholder="Elige una fecha"
                icon="calendar-day"
                @input="filterQuery"
              >
                <button class="button is-danger" @click="clearCreatedTo">
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                  <span class="ml-3">Limpiar</span>
                </button>
              </b-datepicker>
            </b-field>
          </div>
        </div>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          class="mt-5"
        >
          <b-table-column field="id" label="N°" v-slot="props">
            <router-link
              :to="{
                name: 'order_detail',
                params: { id: props.row.id }
              }"
              class="has-text-weight-semibold"
            >
              <span>{{ props.row.id }}</span>
            </router-link>
          </b-table-column>
          <b-table-column field="date" label="Fecha" v-slot="props">
            <span>{{ props.row.date }}</span>
          </b-table-column>
          <b-table-column field="name" label="Nombres" v-slot="props">
            <span>
              {{ props.row.customer.first_name }}
              {{ props.row.customer.last_name }}
            </span>
          </b-table-column>
          <b-table-column field="num_document" label="DNI" v-slot="props">
            <span>
              {{ props.row.customer.num_document }}
            </span>
          </b-table-column>
          <b-table-column field="id" label="Lote de Código" v-slot="props">
            <span>{{ props.row.customer_code.group_code }}</span>
          </b-table-column>
          <b-table-column field="id" label="Producto" v-slot="props">
            <span>{{ props.row.product_variant.name }}</span>
          </b-table-column>
          <b-table-column field="id" label="Estado" v-slot="props">
            <span>
              <b-tag
                :type="
                  props.row.status === 'Completado' ? 'is-primary' : 'is-danger'
                "
              >
                {{ props.row.status }}
              </b-tag>
            </span>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>
<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import OrderService from "@/services/OrderService";
import Page from "@/components/Pages";
import Button from "@/components/form/Button";
import formatDate from "@/mixins/formatDate";
import LoadingTable from "@/components/base/LoadingTable";
export default {
  name: "OrderList",
  components: {
    Layout,
    Card,
    Page,
    Button,
    LoadingTable
  },
  mixins: [formatDate],
  data() {
    return {
      title: "PRODUCTO",
      isLoading: false,
      items: [
        {
          text: "Ecommerce",
          href: "/"
        },
        {
          text: "Orders",
          active: true
        }
      ],
      results: [],
      links: {},
      pages: {},
      query: {
        created_from: null,
        created_to: null,
        search: null,
        status: null
      },
      created_from: null,
      created_to: null
    };
  },
  created() {
    this.getDataAPI({});
  },
  methods: {
    async getDataAPI(params) {
      this.isLoading = true;
      try {
        const response = await OrderService.getList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
        this.isLoading = false;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    searchInput() {
      if (this.query.search !== null) {
        if (this.query.search.length >= 3) {
          this.filterQuery();
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.filterQuery();
        }
      }
    },
    filterQuery() {
      if (this.created_to !== null) {
        const createdTo = this.basicFormatDate(this.created_to);
        this.query.created_to = createdTo;
      }
      if (this.created_from !== null) {
        const createdFrom = this.basicFormatDate(this.created_from);
        this.query.created_from = createdFrom;
      }
      this.getDataAPI({ ...this.query });
    },
    clearCreatedFrom() {
      this.created_from = null;
      this.query.created_from = null;
      this.filterQuery();
    },
    clearCreatedTo() {
      this.created_to = null;
      this.query.created_to = null;
      this.filterQuery();
    },
    setLink() {
      this.getDataAPI({});
    },
    setPage(page) {
      this.getDataAPI({ ...this.query, page: page });
    },
    async descargarExcel() {
      try {
        this.isLoading = true;
        const response = await OrderService.downloadExcel({ ...this.query });
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Reporte-pedido-
          ${fecha.getDate()}/
          ${fecha.getMonth() + 1}/
          ${fecha.getFullYear()}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.isLoading = false;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error."
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.order-detail {
  color: #3fb06f;
  span {
    color: $color-main !important;
  }
}
</style>
