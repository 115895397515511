import { API_VALIDADOR as API } from "@/api";
import axios from "axios";

class OrderService {
  getOrderList(params = {}) {
    const response = axios.get(API.order_list, {
      params
    });
    return response;
  }

  getOrderProductList() {
    const response = axios.get(API.order_products, {
      params: {
        fields: "id,name"
      }
    });
    return response;
  }
}

const instance = new OrderService();
export default instance;
