<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Notificaciones</div>
          <Button
            tag="router-link"
            :to="{ name: 'message_add' }"
            variant="main"
          >
            Enviar Mensajes
          </Button>
        </div>
      </template>
      <template slot="content">
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          detail-key="id"
          icon-pack="fas"
        >
          <b-table-column field="id" label="ID" v-slot="props">
            <span>{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            field="generate_code"
            label="Lote de códigos"
            v-slot="props"
          >
            <span>{{ props.row.generate_code.name }}</span>
          </b-table-column>
          <b-table-column field="description" label="Mensaje" v-slot="props">
            <span>{{ props.row.template.description }}</span>
          </b-table-column>
          <b-table-column
            field="total_messages_sent"
            label="Total de mensajes"
            v-slot="props"
          >
            <span>{{ props.row.total_messages_sent }}</span>
          </b-table-column>
          <b-table-column field="date_max" label="Fecha" v-slot="props">
            <span>
              {{ props.row.created.slice(0, 10) }}
            </span>
          </b-table-column>
          <b-table-column field="type" label="Tipo" v-slot="props">
            <span>{{ getType(props.row.type) }}</span>
          </b-table-column>
          <b-table-column field="status" label="Estado" v-slot="props">
            <b-tag
              :type="
                props.row.status == 'PR'
                  ? 'is-warning'
                  : props.row.status == 'NE'
                  ? 'is-danger'
                  : 'is-primary'
              "
            >
              {{ getStatus(props.row.status) }}
            </b-tag>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import MessageService from "@/services/MessageService";
import Page from "@/components/Pages";
import Button from "@/components/form/Button";
import LoadingTable from "@/components/base/LoadingTable";
export default {
  name: "MessageList",
  components: {
    Layout,
    Card,
    Page,
    Button,
    LoadingTable
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true
    };
  },
  created() {
    this.getDataAPI({});
  },
  methods: {
    getStatus(status) {
      return status == "PR"
        ? "En Progreso"
        : status == "NE"
        ? "No Enviado"
        : "Completado";
    },
    getType(type) {
      return type == "WP"
        ? "Whatsapp"
        : type == "EM"
        ? "Email"
        : "Mensaje de Texto";
    },
    async getDataAPI(params) {
      this.isLoading = true;
      try {
        const response = await MessageService.getList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getDataAPI({});
    },
    setPage(page) {
      this.getDataAPI({ page: page });
    }
  }
};
</script>
