<template>
  <div class="login">
    <div class="login__container container">
      <div class="columns">
        <div class="column">
          <img src="/ilu.png" alt="" />
        </div>
        <div class="column">
          <div class="has-text-left form">
            <h3 class="title login__title">¡Bienvenido!</h3>
            <p>
              Escribe tu correo electronico y contraseña para ingresar al panel.
            </p>
            <form action="" class="mt-5">
              <b-field
                type="is-danger"
                :message="message"
                v-if="message !== ''"
              >
              </b-field>
              <b-field
                label="Email"
                :type="{ 'is-danger': $v.email.$error }"
                :message="[
                  { 'Campo requerido': !$v.email.required && $v.email.$error },
                  {
                    'Formato incorrecto': !$v.email.email && $v.email.$error
                  }
                ]"
              >
                <b-input
                  placeholder="Ingresa tu correo"
                  v-model.trim="email"
                ></b-input>
              </b-field>
              <div class=""></div>
              <b-field
                label="Password"
                :type="{ 'is-danger': $v.password.$error }"
                :message="[
                  {
                    'Campo requerido':
                      !$v.password.required && $v.password.$error
                  }
                ]"
              >
                <b-input
                  type="password"
                  v-model.trim="password"
                  password-reveal
                >
                </b-input>
              </b-field>

              <Button
                @click.native="sendLogin($event)"
                :spinner="loading"
                variant="main"
                class="login__button mt-3"
                >Ingresar</Button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import Button from "@/components/form/Button";
import LoginService from "@/services/LoginService";
import { ROLES } from "@/utils/constants/";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      message: ""
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  components: {
    Button
  },
  methods: {
    ...mapMutations("settings", ["SET_CURRENT_ROLE", "SET_CURRENT_SUB_ROLE"]),
    async sendLogin(e) {
      e.preventDefault();
      // Reset the authError if it existed.
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const { data } = await LoginService.login(this.email, this.password);
          this.$localStorage.set("email", data.user.email);
          this.$localStorage.set("token", data.token);
          this.$localStorage.set("first_name", data.user.first_name);
          this.$localStorage.set("last_name", data.user.last_name);
          this.$localStorage.set("roles", JSON.stringify(data.user.roles));
          if (data.user.roles.includes(ROLES.COMPANY_GROUP)) {
            // Valida si tiene ROL EMPRESAS GRUPOS
            this.SET_CURRENT_ROLE(ROLES.COMPANY_GROUP);
            if (data.user.roles.includes(ROLES.CLIENT_CODE)) {
              this.SET_CURRENT_SUB_ROLE(ROLES.CLIENT_CODE);
              this.$router.push({ name: "home" });
              return;
            }
            if (data.user.roles.includes(ROLES.COMPANY_POINT)) {
              this.SET_CURRENT_SUB_ROLE(ROLES.COMPANY_POINT);
              this.$router.push({ name: "puntos-configuracion" });
              return;
            }
          } else if (data.user.roles.includes(ROLES.SERVICE_USER)) {
            // Valida si tiene ROL VALIDADOR
            this.SET_CURRENT_ROLE(ROLES.SERVICE_USER);
            this.$router.push({ name: "home-validador" });
          } else {
            this.message = "No tienes permisos para ingresar.";
          }
        } catch (error) {
          this.message = "Email o contraseña incorrecta.";
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.login {
  &__container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    .title {
      font-size: 42px;
    }
    .form {
      width: 85%;
      h3 {
        color: $color-text;
      }
      p {
        font-family: $font-light;
        font-size: 15px;
      }
      @media (max-width: 770px) {
        width: 100%;
      }
    }
  }
}
</style>
