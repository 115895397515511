<template>
  <section class="has-text-centered column">
    <div>
      <p>
        El N° Documento
        <span class="has-text-weight-bold">
          {{ document }}
        </span>
        no cuenta con beneficios.
      </p>
      <span class="has-text-weight-bold">
        Ingresa otro documento o elige otro programa de beneficios.
      </span>
      <hr />
      <Button variant="main" @click.native="goBack">
        Volver al inicio
      </Button>
    </div>
  </section>
</template>

<script>
import Button from "@/components/form/Button";
export default {
  components: {
    Button
  },
  props: {
    document: String
  },
  methods: {
    goBack() {
      this.$emit("goBack", 0);
    }
  }
};
</script>
