<template>
  <section class="column is-two-fifths border p-5" style="margin: 0 auto;">
    <div class="has-text-centered">
      <h3 class="mb-5 has-text-weight-bold">
        <font-awesome-icon
          :icon="['fas', 'check']"
          custom-size="default"
          class="mr-1"
        />
        Validación exitosa
      </h3>
      <span class="has-text-weight-bold">
        La validación se ha registrado con éxito
      </span>
      <p>
        N° Documento:
        <span class="has-text-weight-bold">
          {{ document }}
        </span>
      </p>
      <hr />
      <Button variant="main" @click.native="goBack">
        Volver al inicio
      </Button>
    </div>
  </section>
</template>

<script>
import Button from "@/components/form/Button";
export default {
  components: {
    Button
  },
  props: {
    document: String
  },
  methods: {
    goBack() {
      this.$emit("goBack", 0);
    }
  }
};
</script>
