const BASE_URL = "/validador";

const routesValidador = [
  {
    path: `${BASE_URL}/nuevo-consumo`,
    name: "home-validador",
    meta: { title: "Nuevo Consumo", icon: "book", sidebar: true },
    component: () => import("../views/validador/home/HomeView.vue")
  },
  {
    path: `${BASE_URL}/delivery`,
    name: "delivery",
    meta: { title: "Delivery", icon: "shopping-cart", sidebar: true },
    component: () => import("../views/validador/delivery/DeliveryList.vue")
  },
  {
    path: `${BASE_URL}/delivery/:id`,
    name: "delivery-detail",
    meta: { title: "Delivery Detalle", icon: "shopping-cart", sidebar: false },
    component: () => import("../views/validador/delivery/DeliveryDetail.vue")
  },
  {
    path: `${BASE_URL}/consumo`,
    name: "order",
    meta: { title: "Historial", icon: "calendar", sidebar: true },
    component: () => import("../views/validador/order/OrderList.vue")
  },
  {
    path: `${BASE_URL}/facturacion`,
    name: "billing",
    meta: { title: "Facturas", icon: "file-invoice-dollar", sidebar: true },
    component: () => import("../views/validador/billing/BillingList.vue")
  }
];

export default routesValidador;
