<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">Editar Cliente</h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>Información</span>
                </template>
                <div>
                  <b-field label="Activo">
                    <b-switch
                      v-model="data.is_active"
                      type="is-success"
                    ></b-switch>
                  </b-field>
                  <b-field
                    label="Nombre"
                    :type="{ 'is-danger': $v.data.first_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.first_name.required &&
                          $v.data.first_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.first_name"></b-input>
                  </b-field>
                  <b-field
                    label="Apellido"
                    :type="{ 'is-danger': $v.data.last_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.last_name.required &&
                          $v.data.last_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.last_name"></b-input>
                  </b-field>
                  <b-field
                    label="N° Documento"
                    :type="{ 'is-danger': $v.data.num_document.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.num_document.required &&
                          $v.data.num_document.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.num_document"></b-input>
                  </b-field>
                  <b-field label="Fecha de Cumpleaños (Opcional)">
                    <b-datepicker
                      v-model="birth_date"
                      placeholder="Elige una fecha"
                    >
                    </b-datepicker>
                  </b-field>
                  <b-field
                    label="Email (Opcional)"
                    :type="{ 'is-danger': $v.data.email.$error }"
                    :message="[
                      {
                        'Email inválido':
                          !$v.data.email.email && $v.data.email.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.email"></b-input>
                  </b-field>
                  <b-field label="Teléfono (Opcional)">
                    <b-input v-model="data.phone"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          class="mb-2"
          :spinner="loading"
          @click.native="updateClient()"
          block
        >
          Guardar
        </Button>
        <Button
          tag="router-link"
          :to="{ name: 'clients' }"
          variant="gray"
          block
        >
          Cancelar
        </Button>
      </Aside>
    </div>
  </Layout>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import ClientService from "@/services/admin/ClientService";
import formatDate from "@/mixins/formatDate";
export default {
  name: "ClientCreate",
  components: {
    Layout,
    Card,
    Aside,
    Button
  },
  mixins: [formatDate],
  data() {
    return {
      activeTab: 0,
      data: {
        is_active: true,
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        num_document: "",
        birth_date: ""
      },
      birth_date: null,
      loading: false
    };
  },
  validations: {
    data: {
      email: { email },
      first_name: { required },
      last_name: { required },
      num_document: { required }
    }
  },
  mounted() {
    this.getClient();
  },
  methods: {
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async getClient() {
      this.loading = true;
      try {
        const response = await ClientService.getDetail(this.$route.params.id);
        this.data = response.data;
        if (this.data.birth_date) {
          this.birth_date = this.formateBasicDate(response.data.birth_date);
        }
      } catch (error) {
        const message = error.response.data.message || "Ocurrió un error";
        this.showMessage("D", message);
      } finally {
        this.loading = false;
      }
    },
    async updateClient() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          if (this.birth_date) {
            this.data.birth_date = this.formatDateReverse(this.birth_date);
          }
          await ClientService.update(this.$route.params.id, this.data);
          this.loading = false;
          this.showMessage("S", "Se guardó correctamente");
          this.$router.push({ name: "clients" });
        } catch (error) {
          this.loading = false;
          const message = error.response.data.message || "Ocurrió un error";
          this.showMessage("D", message);
        }
      } else {
        this.showMessage();
      }
    }
  }
};
</script>
