<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    title: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    information: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.renderChart(
      {
        labels: this.information,
        datasets: [
          {
            label: this.label,
            data: this.values,
            backgroundColor: "transparent",
            borderColor: "#4BB578",
            pointBackgroundColor: "#f14668"
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title
        },
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0
              }
            }
          ]
        }
      }
    );
  }
};
</script>
