import { API_VALIDADOR as API } from "@/api";
import axios from "axios";

class DeliveryService {
  getDeliveryList(params = {}) {
    const response = axios.get(API.delivery_list, {
      params
    });
    return response;
  }

  getDeliveryDetail(id) {
    const response = axios.get(API.delivery_detail(id));
    return response;
  }

  postChangeStatus(id, data = {}) {
    const response = axios.post(API.delivery_change_status(id), data);
    return response;
  }

  postOrderCancelled(id) {
    const response = axios.post(API.delivery_cancelled(id));
    return response;
  }

  downloadPdf(data) {
    const response = axios({
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      url: API.delivery_pdf,
      responseType: "blob",
      data: {
        id_list: data
      }
    });
    return response;
  }

  downloadCsv(params) {
    const response = axios({
      method: "get",
      headers: {
        "Content-Type": "application/json"
      },
      url: API.delivery_csv,
      params,
      responseType: "blob"
    });
    return response;
  }
}

const instance = new DeliveryService();
export default instance;
