<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div class>
          <h3 class="is-size-4 has-text-weight-bold">Catálogo</h3>
        </div>
      </template>
      <template slot="content">
        <div class="not-found">
          Puntos Propios - Catálogo
        </div>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
export default {
  name: "CatalogueList",
  components: {
    Layout,
    Card
  }
};
</script>
