<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Facturas</div>
        </div>
      </template>
      <template slot="content">
        <div class="filterContainer">
          <div class="column px-0">
            <b-field label="Local:">
              <b-select
                placeholder="Seleccione una opción"
                v-model="query.local_id"
                icon="home"
                size="is-regular"
                @input="filterDeliveryList"
              >
                <option
                  v-for="local in locals"
                  :key="local.id"
                  :value="local.id"
                  >{{ local.name }}</option
                >
              </b-select>
            </b-field>
          </div>
        </div>
        <b-table
          :loading="isLoading"
          :data="results"
          detail-key="id"
          icon-pack="fas"
          hoverable
          striped
        >
          <b-table-column
            label="Nombre"
            field="product_variant_name"
            v-slot="props"
          >
            {{ props.row.product_variant_name }}
          </b-table-column>
          <b-table-column
            label="Asumido por Weeare"
            field="total"
            v-slot="props"
          >
            <div>
              <span class="has-text-weight-bold">
                S/. {{ props.row.total.toFixed(2) }}
              </span>
              <p>
                Del {{ props.row.fecha_start }} al {{ props.row.fecha_end }}
              </p>
            </div>
          </b-table-column>
          <b-table-column
            width="170"
            label="Archivos"
            field="file"
            centered
            v-slot="props"
          >
            <a
              class="has-text-weight-bold has-text-primary"
              @click="downloadBillingPdf(props.row.id)"
            >
              <b-icon icon="download"></b-icon>
              Reporte PDF
            </a>
          </b-table-column>
          <b-table-column
            sortable
            centered
            field="status_large"
            label="Estado"
            v-slot="props"
          >
            <b-tag :type="getStatusClass(props.row.status_large)">
              {{ props.row.status_large }}
            </b-tag>
          </b-table-column>
          <b-table-column
            centered
            label="Factura"
            field="is_send_file"
            v-slot="props"
          >
            <b-button
              :type="!props.row.is_send_file ? 'is-primary' : 'is-info'"
              @click="openModalBilling(props.row)"
            >
              {{ !props.row.is_send_file ? "Facturar" : "Actualizar" }}
            </b-button>
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
      </template>
    </Card>
    <ModalBilling
      v-if="modaBilling"
      :idBilling="billing.id"
      :numInvoice="billing.num_invoice"
      @hidden="modaBilling = false"
      @reload="getBillingList"
    />
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import LocalService from "@/services/validador/LocalService";
import BillingService from "@/services/validador/BillingService";
import LoadingTable from "@/components/base/LoadingTable";
import ModalBilling from "@/views/validador/billing/modal/ModalBilling";
export default {
  name: "BillingList",
  components: {
    Layout,
    Card,
    LoadingTable,
    ModalBilling
  },
  data() {
    return {
      locals: [],
      results: [],
      billing: {},
      modaBilling: false,
      isLoading: true,
      query: {
        local_id: null
      }
    };
  },
  created() {
    this.getLocals();
  },
  methods: {
    showMessage(type = "D", msg = "Ocurrió un error") {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async getLocals() {
      this.isLoading = true;
      try {
        const response = await LocalService.getLocalList();
        this.locals = response.data;
        this.query.local_id = this.locals[0].id;
        await this.getBillingList();
      } catch (error) {
        this.showMessage();
      } finally {
        this.isLoading = false;
      }
    },
    openModalBilling(billing) {
      this.billing = billing;
      this.modaBilling = true;
    },
    getStatusClass(status) {
      switch (status) {
        case "Pendiente de pago":
          return "is-warning";
        case "Pagado":
          return "is-primary";
        default:
          return "is-danger";
      }
    },
    filterDeliveryList() {
      this.getBillingList();
    },
    async downloadBillingPdf(idBilling) {
      try {
        const response = await BillingService.downloadBillingPdf({
          billing_id: idBilling
        });
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Reporte-factura-${fecha.getDate()}/${fecha.getMonth() +
            1}/${fecha.getFullYear()}.pdf`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.showMessage("S", "Descargando reporte PDF");
      } catch (error) {
        this.showMessage();
      }
    },
    async getBillingList() {
      this.isLoading = true;
      try {
        const response = await BillingService.getBillingList(this.query);
        this.results = response.data;
      } catch (error) {
        this.showMessage();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.b-table .table th.is-sortable {
  border-color: rgba(34, 43, 59, 0.05);
}
</style>
