<template>
  <b-modal v-model="modalDelete" :width="550" scroll="keep">
    <div class="card">
      <div class="card-content py-5">
        <h3 class="is-size-4 has-text-weight-bold mb-5">
          ¿Estás seguro de eliminar la página?
        </h3>
        <hr />
        <div
          class="is-flex is-justify-content-flex-end w-full is-align-items-center"
        >
          <div>
            <Button variant="gray" @click.native="modalDelete = false"
              >Cancelar</Button
            >
          </div>
          <div class="ml-3">
            <Button
              variant="danger"
              :spinner="loading"
              @click.native="deleteInfo"
              >Sí</Button
            >
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Button from "@/components/form/Button";
import PageService from "@/services/PageService";
export default {
  components: {
    Button
  },
  data() {
    return {
      modalDelete: true,
      loading: false
    };
  },
  watch: {
    modalDelete() {
      this.$emit("hidden");
    }
  },
  methods: {
    async deleteInfo() {
      try {
        this.loading = true;
        await PageService.deletePage(this.$route.params.id);
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "S",
          msg: "Se eliminó correctamente."
        });
        this.modalDelete = false;
        this.$router.push({ name: "page_list" });
      } catch (error) {
        this.loading = false;
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error."
        });
      }
    }
  }
};
</script>
