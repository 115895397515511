import { API_VALIDADOR as API } from "@/api";
import axios from "axios";

class LocalService {
  getLocalList(params = {}) {
    const response = axios.get(API.locals, {
      params
    });
    return response;
  }
}

const instance = new LocalService();
export default instance;
