import { API_ADMIN as API } from "@/api";
import axios from "axios";
class ClientService {
  getList(params = {}) {
    const response = axios.get(API.clients, {
      params: params
    });
    return response;
  }

  create(data) {
    const response = axios.post(API.clients, data);
    return response;
  }

  getDetail(id) {
    const response = axios.get(API.client_detail(id));
    return response;
  }

  update(id, data) {
    const response = axios.put(API.client_detail(id), data);
    return response;
  }
}

const instance = new ClientService();
export default instance;
