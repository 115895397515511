<template>
  <div>
    <h4 class="has-text-centered is-size-6 has-text-weight-semibold">
      Total de redenciones ({{ this.total }})
    </h4>
    <div v-if="mountGraphic">
      <PieChart
        :values="values"
        :free="this.results.free"
        :use="this.results.use"
      />
    </div>
    <div v-else class="has-text-centered mt-5">
      Cargando gráfico de total de redenciones ...
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/graphics/PieChart";
import GraphicService from "@/services/GraphicService";
export default {
  components: {
    PieChart
  },
  data() {
    return {
      results: [],
      total: 0,
      values: [],
      mountGraphic: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const response = await GraphicService.getCodes();
        this.results = response.data;
        this.values.push(this.results.free);
        this.values.push(this.results.use);
        this.total = this.results.free + this.results.use;
        setTimeout(() => {
          this.mountGraphic = true;
        }, 500);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
