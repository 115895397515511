var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"column is-two-fifths border p-5",staticStyle:{"margin":"0 auto"}},[_c('h3',{staticClass:"mb-5 has-text-weight-bold has-text-centered"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'user-check'],"custom-size":"default"}}),_vm._v(" "+_vm._s(_vm.title)+" ")],1),(_vm.type_discount == 1 || _vm.type_discount == 2)?_c('form',[_c('b-field',{attrs:{"label":"Monto en soles S/.","type":{ 'is-danger': _vm.$v.mount.$error },"message":[
          {
            'Requerido. Ingresa un monto válido':
              !_vm.$v.mount.required && _vm.$v.mount.$error
          },
          {
            'Incorrecto. Ingresa un monto válido':
              !_vm.$v.mount.decimal && _vm.$v.mount.$error
          }
        ]}},[_c('b-input',{attrs:{"placeholder":"0","type":"numeric","name":"mount"},nativeOn:{"keyup":function($event){return _vm.getCalculateMount($event)}},model:{value:(_vm.mount),callback:function ($$v) {_vm.mount=$$v},expression:"mount"}})],1),_c('div',{staticClass:"has-text-centered mt-5"},[_c('span',[_vm._v("Total a pagar")]),_c('p',{staticClass:"has-text-weight-bold is-size-3"},[_vm._v(" S/. "+_vm._s(_vm.discount.toFixed(2))+" ")])])],1):(_vm.type_discount == 3)?_c('form',[_c('div',{staticClass:"has-text-centered mt-3"},[_c('b-icon',{attrs:{"icon":"gift","size":"is-large"}})],1)]):(_vm.type_discount == 5)?_c('form',[_c('div',{staticClass:"has-text-centered mt-3"},[_c('b-icon',{attrs:{"icon":"cart-plus","size":"is-large"}})],1)]):_vm._e(),_c('hr'),_c('div',{staticClass:"is-flex is-justify-content-center is-align-items-center"},[_c('Button',{attrs:{"spinner":_vm.isLoading,"variant":"gray"},nativeOn:{"click":function($event){return _vm.goBack($event)}}},[_vm._v(" Regresar ")]),_c('Button',{staticClass:"ml-2",staticStyle:{"width":"115px"},attrs:{"spinner":_vm.isLoading,"variant":"main"},nativeOn:{"click":function($event){return _vm.postValidateMount($event)}}},[_vm._v(" Validar ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }