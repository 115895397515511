<template>
  <div class="header">
    <div>
      <div v-if="validateSwitch" class="switch-roles">
        <p :class="{ 'active-role': currentRole === ROLES.SERVICE_USER }">
          Validador
        </p>
        <label class="switch" @click="changeRole">
          <span
            class="slider"
            :class="currentRole === ROLES.COMPANY_GROUP ? 'right' : ''"
          />
        </label>
        <p :class="{ 'active-role': currentRole === ROLES.COMPANY_GROUP }">
          Empresas
        </p>
      </div>
    </div>
    <div class="header__user">
      <div class="header__user__name">
        <b-dropdown aria-role="list">
          <div slot="trigger" style="cursor: pointer;">
            <span>{{ first_name }} {{ last_name }}</span>
            <font-awesome-icon :icon="['fas', 'angle-down']" />
          </div>
          <b-dropdown-item
            aria-role="listitem"
            @click="logout"
            style="color: hsl(348, 100%, 61%);"
          >
            <font-awesome-icon :icon="['fas', 'power-off']" class="mr-3" />
            Cerrar Sesión
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { ROLES } from "../utils/constants";
export default {
  name: "HeaderMenu",
  data() {
    return {
      email: "",
      ROLES: ROLES
    };
  },
  created() {
    this.first_name = this.$localStorage.get("first_name");
    this.last_name = this.$localStorage.get("last_name");
  },
  computed: {
    ...mapState("settings", ["roles", "currentRole", "currentSubRole"]),
    validateSwitch() {
      const { SERVICE_USER, COMPANY_GROUP } = ROLES;
      return (
        this.roles.includes(SERVICE_USER) && this.roles.includes(COMPANY_GROUP)
      );
    }
  },
  methods: {
    ...mapMutations("settings", ["SET_CURRENT_ROLE"]),
    logout() {
      this.$localStorage.remove("email");
      this.$localStorage.remove("token");
      this.$localStorage.remove("first_name");
      this.$localStorage.remove("last_name");
      this.$localStorage.remove("company");
      this.$localStorage.remove("role");
      this.$localStorage.remove("local");
      this.$localStorage.remove("current_role");
      this.$localStorage.remove("current_sub_role");
      this.$router.push({ name: "login" });
    },
    changeRole() {
      if (this.currentRole === ROLES.COMPANY_GROUP) {
        this.SET_CURRENT_ROLE(ROLES.SERVICE_USER);
        setTimeout(() => {
          this.$router.push({ name: "home-validador" });
        }, 400);
      } else {
        this.SET_CURRENT_ROLE(ROLES.COMPANY_GROUP);
        setTimeout(() => {
          if (this.currentSubRole === ROLES.COMPANY_POINT) {
            this.$router.push({ name: "puntos-configuracion" });
          } else {
            this.$router.push({ name: "home" });
          }
        }, 400);
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.2rem;
  &__user {
    &__name {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        margin-right: 8px;
      }
    }
  }
  .switch-roles {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      font-weight: bold;
    }
    .active-role {
      color: $color-main;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 20px;
      margin: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 20px;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      bottom: 2px;
      left: 4px;
      background-color: white;
      transition: transform 0.2s ease-in;
      border-radius: 50%;
    }
    .slider.right:before {
      transform: translateX(26px);
    }
  }
}
</style>
