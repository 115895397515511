<template>
  <Layout>
    <div class="is-flex">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">
              Detalle
            </h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>Información</span>
                </template>
                <div class="pd-5">
                  <div class="columns">
                    <strong class="column is-one-quarter">
                      Código de integración:
                    </strong>
                    <p class="m-0 column">{{ data.id }}</p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Nombre: </strong>
                    <p class="m-0 column">{{ data.name }}</p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Total: </strong>
                    <p class="m-0 column">{{ data.quantity }}</p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Usado: </strong>
                    <p class="m-0 column">{{ data.use }}</p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Libre: </strong>
                    <p class="m-0 column">{{ data.free }}</p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">
                      Fecha de creación:
                    </strong>
                    <p class="m-0 column">{{ data.created }}</p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">
                      Fecha límite de uso:
                    </strong>
                    <p class="m-0 column">{{ data.date_max }}</p>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item>
                <template #header>
                  <span>Códigos</span>
                </template>
                <div class="filterContainer">
                  <b-field label="Buscar: ">
                    <b-input
                      placeholder="Código..."
                      class="inputSearch"
                      v-model="query.search"
                      type="search"
                      icon="search"
                      @keyup.native="searchInput"
                    >
                    </b-input>
                  </b-field>
                </div>
                <b-table :data="results" detail-key="id" icon-pack="fas">
                  <b-table-column field="code" label="Código" v-slot="props">
                    <span v-if="!props.row.is_use">
                      <strong
                        class="has-text-success"
                        style="cursor:pointer;"
                        @click="openModalCode(props.row.id)"
                      >
                        {{ props.row.code }}
                      </strong>
                    </span>
                    <span v-else>
                      {{ props.row.code }}
                    </span>
                  </b-table-column>
                  <b-table-column
                    field="date_activate"
                    label="Fecha de activación"
                    v-slot="props"
                  >
                    <span>{{ props.row.date_activate }}</span>
                  </b-table-column>
                  <b-table-column
                    field="customer"
                    label="Cliente"
                    v-slot="props"
                  >
                    <span>{{ props.row.customer }}</span>
                  </b-table-column>
                  <b-table-column
                    field="segment"
                    label="Segmento"
                    v-slot="props"
                  >
                    <span>
                      {{ formatSegments(props.row.segments) }}
                    </span>
                  </b-table-column>
                  <b-table-column field="document" label="DNI" v-slot="props">
                    <span>{{ props.row.document }}</span>
                  </b-table-column>
                  <b-table-column field="is_use" label="Usado" v-slot="props">
                    <font-awesome-icon
                      v-if="props.row.is_use"
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </b-table-column>
                </b-table>
                <hr style="margin-top: 0;" />
                <Page
                  :links="links"
                  :pages="pages"
                  :per_page="20"
                  v-if="pages"
                  @set-link="setLink"
                  @set-page="setPage"
                ></Page>
              </b-tab-item>
              <b-tab-item>
                <template #header>
                  <span>Productos</span>
                </template>
                <div class="products">
                  <div
                    class="products__container"
                    v-for="(p, index) in products"
                    :key="index"
                  >
                    <CardProduct :product="p" />
                  </div>
                </div>
                <hr style="margin-top: 0;" />
                <Page
                  :links="linksProducts"
                  :pages="pagesProducts"
                  v-if="pagesProducts"
                  @set-link="setLinkProducts"
                  @set-page="setPageProducts"
                ></Page>
              </b-tab-item>
            </b-tabs>
          </section>
          <b-modal v-model="detailModal" :width="600" scroll="keep">
            <div class="card">
              <div>
                <header class="modal-card-head">
                  <img
                    class="companyImg"
                    :src="item.product.company_service.image"
                    :alt="item.product.company_service.business_name"
                  />
                  <p class="modal-card-title">
                    {{ item.product.company_service.business_name }}
                  </p>
                </header>
                <section class="modal-card-body">
                  <span v-html="item.product.terms_conditions"></span>
                </section>
              </div>
            </div>
          </b-modal>
          <b-modal
            v-model="detailCode"
            class="detailCode"
            :width="400"
            scroll="keep"
          >
            <div class="card">
              <div>
                <header class="modal-card-head">
                  <h2>Código: {{ codeId }}</h2>
                </header>
                <section class="modal-card-body">
                  <b-field
                    label="N° Documento:"
                    :type="{ 'is-danger': $v.code.num_document.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.code.num_document.required &&
                          $v.code.num_document.$error
                      },
                      {
                        'Datos incorrectos':
                          !$v.code.num_document.numeric &&
                          $v.code.num_document.$error
                      }
                    ]"
                  >
                    <b-input v-model="code.num_document" type="text"></b-input>
                  </b-field>
                  <b-field label="Email">
                    <b-input v-model="code.email" type="email"></b-input>
                  </b-field>
                  <b-field
                    label="Nombre"
                    :type="{ 'is-danger': $v.code.first_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.code.first_name.required &&
                          $v.code.first_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="code.first_name" type="text"></b-input>
                  </b-field>
                  <b-field
                    label="Apellido"
                    :type="{ 'is-danger': $v.code.last_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.code.last_name.required &&
                          $v.code.last_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="code.last_name" type="text"></b-input>
                  </b-field>
                  <div class="buttons mt-5">
                    <Button variant="gray" @click.native="detailCode = false">
                      Cerrar
                    </Button>
                    <Button
                      class="ml-3"
                      variant="main"
                      :spinner="loading"
                      @click.native="postInfoCode()"
                    >
                      Guardar
                    </Button>
                  </div>
                </section>
              </div>
            </div>
          </b-modal>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          block
          class="mt-2"
          @click.native="descargarExcel"
        >
          Descargar Excel
        </Button>
        <Button
          tag="router-link"
          :to="{ name: 'code_list' }"
          variant="gray"
          block
          class="mt-2"
          >Regresar</Button
        >
      </Aside>
    </div>
  </Layout>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import CodeService from "@/services/CodeService";
import CardProduct from "@/components/base/CardProduct";
import Page from "@/components/Pages";
export default {
  name: "CodeUpdate",
  components: {
    Layout,
    Card,
    Aside,
    Button,
    CardProduct,
    Page
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      query: {
        search: ""
      },
      data: {},
      results: [],
      links: {},
      pages: {},
      products: [],
      linksProducts: {},
      pagesProducts: {},
      detailModal: false,
      detailCode: false,
      codeId: "",
      code: {
        num_document: "",
        email: "",
        first_name: "",
        last_name: ""
      },
      item: {
        name: "",
        image_url: "",
        description: "",
        product: {
          terms_conditions: "",
          company_service: {
            business_name: "",
            url: "",
            image: ""
          },
          text_button: "",
          url_button: "",
          type_service: "",
          type_discount: ""
        },
        stock: ""
      }
    };
  },
  validations: {
    code: {
      num_document: { required, numeric },
      first_name: { required },
      last_name: { required }
    }
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    formatSegments(params) {
      let segments = [];
      for (let i = 0; i < params.length; i++) {
        segments.push(params[i].name);
      }
      return segments.join();
    },
    openModal(params) {
      this.item = params;
      this.detailModal = true;
    },
    openModalCode(id) {
      this.codeId = id;
      this.detailCode = true;
    },
    async getInfo() {
      try {
        const response = await CodeService.getDetail(this.$route.params.id);
        this.getAllCodes();
        this.getAllProducts();
        this.data = response.data;
      } catch (error) {
        this.$router.push({ name: "code_list" });
      }
    },
    async getAllCodes(params) {
      try {
        const response = await CodeService.getCodes(
          this.$route.params.id,
          params
        );
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$router.push({ name: "code_list" });
      }
    },
    async getAllProducts(params) {
      try {
        const response = await CodeService.getProducts(
          this.$route.params.id,
          params
        );
        this.products = response.data.results;
        this.pagesProducts = response.data.pages;
        this.linksProducts = response.data.links;
      } catch (error) {
        this.$router.push({ name: "code_list" });
      }
    },
    async postInfoCode() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          await CodeService.addCustomer(this.codeId, this.code);
          this.loading = false;
          this.detailCode = false;
          this.getAllCodes();
        } catch (error) {
          this.loading = false;
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: "Ocurrió un error."
          });
        }
      }
    },
    setLink() {
      this.getAllCodes({});
    },
    setPage(page) {
      this.getAllCodes({ page: page });
    },
    setLinkProducts() {
      this.getAllProducts({});
    },
    setPageProducts(page) {
      this.getAllProducts({ page: page });
    },
    searchInput() {
      if (this.query.search !== null) {
        if (this.query.search.length >= 3) {
          this.getAllCodes({ ...this.query });
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.getAllCodes({ ...this.query });
        }
      }
    },
    async descargarExcel() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        const response = await CodeService.downloadExcel(id);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        const fecha = new Date();
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Lote-de-codigo-n°-${id}-${fecha.getDate()}/${fecha.getMonth() +
            1}/${fecha.getFullYear()}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loading = false;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error."
        });
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  @media (max-width: 1040px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  &__container {
    border: 1px solid $color-border;
    border-radius: 0.5rem;
    overflow: hidden;
    .productImg {
      object-fit: cover;
      width: 100%;
      height: 130px;
      @media (max-width: 1040px) {
        height: 180px;
      }
      @media (max-width: 768px) {
        height: 200px;
      }
    }
    .productText {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 260px;
    }
  }
}
.companyImg {
  width: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.detailCode {
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
