<template>
  <div class="alert-message">
    <b-message :type="alert.type === 'S' ? 'is-success' : 'is-danger'" has-icon>
      <span
        :class="alert.type === 'S' ? 'has-text-success' : 'has-text-danger'"
        >{{ alert.msg }}</span
      >
    </b-message>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "AlertMessage",
  props: {
    alert: Object
  },
  methods: {
    ...mapMutations("settings", ["SET_ALERT"])
  },
  mounted() {
    setTimeout(() => {
      this.SET_ALERT({
        isAlert: false,
        type: "",
        msg: ""
      });
    }, 2000);
  }
};
</script>

<style lang="scss">
.alert-message {
  position: fixed;
  right: 10px;
  top: 80px;
  z-index: 100;
}
.message-body {
  .icon.is-danger {
    color: $color-red;
  }
  .icon.is-success {
    color: $color-green;
  }
}
</style>
