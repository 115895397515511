<template>
  <b-modal v-model="showModal" :width="550" scroll="keep">
    <div class="card">
      <div class="card-content py-5">
        <h3 class="is-size-4 has-text-weight-bold mb-5">
          Crear Template - Whatsapp
        </h3>
        <hr />
        <b-notification type="is-info" aria-close-label="Close notification">
          <small>
            Estos templates solo están disponibles para
            <strong>Whatsapp</strong>.
          </small>
          <br />
          <small>
            Elige un template base para editar.
          </small>
        </b-notification>
        <div v-if="step === 1">
          <div v-for="(template, index) in templates" :key="index">
            <p>{{ template }}</p>
            <div class="has-text-right mt-5">
              <b-button
                type="is-primary"
                size="is-small"
                @click="selectTemplate(template)"
              >
                Seleccionar
              </b-button>
            </div>
            <hr />
          </div>
        </div>
        <div v-else>
          <p>{{ template }}</p>
          <hr />
          <b-field
            label="Primer Texto {1}"
            :type="{ 'is-danger': $v.text.first.$error }"
            :message="[
              {
                'Campo requerido':
                  !$v.text.first.required && $v.text.first.$error
              }
            ]"
          >
            <b-input
              type="text"
              v-model="text.first"
              placeholder="{full_name}, https://web.com, Mi Empresa "
            />
          </b-field>
          <b-field label="Segundo Texto {2}">
            <b-input
              type="text"
              v-model="text.second"
              placeholder="{full_name}, https://web.com, Mi Empresa "
            />
          </b-field>
          <hr />
        </div>
        <div
          class="is-flex is-justify-content-flex-end w-full is-align-items-center"
        >
          <div>
            <Button variant="gray" @click.native="showModal = false">
              Cerrar
            </Button>
          </div>
          <div class="ml-3" v-if="step === 2">
            <Button
              variant="main"
              :spinner="loading"
              @click.native="formatTemplateDescription"
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Button from "@/components/form/Button";
import MessageService from "@/services/MessageService";
export default {
  components: {
    Button
  },
  data() {
    return {
      showModal: true,
      loading: false,
      data: {
        description: "",
        type_template: "WP"
      },
      templates: [
        "Hola {first_name}, tienes un regalo de {1}, para utilizarlo ingresa a {2} con tu número de documento de identidad.",
        "Hola {first_name} ¡Se acerca el gran día! Este día tan importante es para compartirlo con tus seres queridos. Te hacemos llegar un QR que podrá ser compartido con 4 invitados a través de Whatsapp o correo electrónico. {1}",
        "Hola {first_name}\n En {1} estamos orgullosos de tus logros, se acerca el gran día.\n{2}"
      ],
      template: "",
      step: 1,
      text: {
        first: "",
        second: ""
      }
    };
  },
  validations: {
    text: {
      first: { required }
    }
  },
  watch: {
    showModal() {
      this.$emit("hidden");
    }
  },
  methods: {
    selectTemplate(template) {
      this.template = template;
      this.step = 2;
    },
    async saveInfo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          await MessageService.postNewTemplate(this.data);
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "S",
            msg: "Se guardó correctamente."
          });
          this.showModal = false;
        } catch (error) {
          this.loading = false;
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: "Ocurrió un error."
          });
        }
      }
    },
    formatTemplateDescription() {
      let template = this.template;
      template = template.replace("{1}", this.text.first);
      template = template.replace("{2}", this.text.second);
      this.data.description = template;
      this.saveInfo();
    }
  }
};
</script>
