<template>
  <div>
    <b-pagination
      :total="pages.count"
      :current="pages.current"
      :rounded="rounded"
      :range-after="4"
      :per-page="per_page || '20'"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      @change="setPage"
    >
    </b-pagination>
  </div>
</template>
<script>
export default {
  name: "Page",
  props: ["links", "pages", "per_page"],
  data() {
    return {
      rounded: true
    };
  },
  methods: {
    setLink(url) {
      if (url) {
        this.$emit("set-link", url);
      }
    },
    setPage(page) {
      if (page !== this.pages.current) {
        this.$emit("set-page", page);
      }
    }
  }
};
</script>
