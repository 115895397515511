<template>
  <Layout>
    <Card class="w-full">
      <template slot="header">
        <div
          class="is-flex is-justify-content-space-between w-full is-align-items-center"
        >
          <div class="is-size-4 has-text-weight-bold">Clientes</div>
          <Button
            tag="router-link"
            :to="{
              name: 'client_create'
            }"
            variant="main"
            >Crear</Button
          >
        </div>
      </template>
      <template slot="content">
        <div class="filterContainer">
          <b-field label="Buscar: ">
            <b-input
              placeholder="Nombre, apellido, email, documento ..."
              class="inputSearch"
              v-model="query.search"
              type="search"
              icon="search"
              @keyup.native="searchInput"
            >
            </b-input>
          </b-field>
          <b-field label="Estado: ">
            <b-select
              placeholder="Seleccione una opción"
              v-model="query.is_active"
              icon="check"
              size="is-regular"
              @input="searchStatus"
            >
              <option :value="null">Todos</option>
              <option :value="true">Activo</option>
              <option :value="false">Inactivo</option>
            </b-select>
          </b-field>
        </div>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
          detail-key="id"
          icon-pack="fas"
        >
          <b-table-column field="name" label="Nombre" v-slot="props">
            <router-link
              :to="{
                name: 'client_update',
                params: { id: props.row.id }
              }"
              class="has-text-weight-semibold"
              >{{ props.row.first_name }} {{ props.row.last_name }}</router-link
            >
          </b-table-column>
          <b-table-column field="document" label="N° Documento" v-slot="props">
            {{ props.row.num_document }}
          </b-table-column>
          <b-table-column field="phone" label="Teléfono" v-slot="props">
            <span>{{ props.row.phone }}</span>
          </b-table-column>
          <b-table-column field="email" label="Email" v-slot="props">
            <span>{{ props.row.email }}</span>
          </b-table-column>
          <b-table-column field="is_active" label="Activo" v-slot="props">
            <font-awesome-icon
              v-if="props.row.is_active"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-else
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </b-table-column>
          <LoadingTable slot="empty" :isLoading="isLoading" />
        </b-table>
        <hr style="margin-top: 0;" />
        <Page
          :links="links"
          :pages="pages"
          v-if="pages"
          @set-link="setLink"
          @set-page="setPage"
        ></Page>
      </template>
    </Card>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import ClientService from "@/services/admin/ClientService";
import Page from "@/components/Pages";
import LoadingTable from "@/components/base/LoadingTable";
import Button from "@/components/form/Button";
export default {
  name: "ClientList",
  components: {
    Layout,
    Card,
    Page,
    LoadingTable,
    Button
  },
  data() {
    return {
      results: [],
      links: {},
      pages: {},
      isLoading: true,
      query: {
        search: "",
        is_active: null
      }
    };
  },
  created() {
    this.getDataAPI({});
  },
  methods: {
    async getDataAPI(params) {
      this.isLoading = true;
      try {
        const response = await ClientService.getList(params);
        this.results = response.data.results;
        this.pages = response.data.pages;
        this.links = response.data.links;
      } catch (error) {
        this.$store.commit("settings/SET_ALERT", {
          isAlert: true,
          type: "D",
          msg: "Ocurrió un error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    setLink() {
      this.getDataAPI({});
    },
    setPage(page) {
      this.getDataAPI({ page: page });
    },
    searchStatus() {
      this.getDataAPI({ ...this.query });
    },
    searchInput() {
      if (this.query.search !== null) {
        if (this.query.search.length >= 3) {
          this.getDataAPI({ ...this.query });
        } else if (this.query.search.length === 0) {
          this.query.search = null;
          this.getDataAPI({ ...this.query });
        }
      }
    }
  }
};
</script>
