<template>
  <Layout>
    <div class="is-flex clients-point">
      <Card class="w-full">
        <template slot="header">
          <div class>
            <h3 class="is-size-4 has-text-weight-bold">Crear Cliente</h3>
          </div>
        </template>
        <template slot="content">
          <section>
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item label="Validación" :disabled="activeTab === 1">
                <div>
                  <b-field
                    label="N° documento"
                    :type="{ 'is-danger': $v.data.num_document.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.num_document.required &&
                          $v.data.num_document.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.num_document"></b-input>
                  </b-field>
                  <br />
                  <hr />
                  <div class="has-text-right mt-6">
                    <b-button
                      type="is-primary"
                      size="is-small"
                      @click="validateDocument()"
                    >
                      Validar documento
                      <font-awesome-icon
                        class="ml-1"
                        :icon="['fas', 'arrow-right']"
                      />
                    </b-button>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Información" :disabled="activeTab === 0">
                <div>
                  <b-field label="N° documento">
                    <b-input :value="data.num_document" disabled></b-input>
                  </b-field>
                  <b-field
                    label="Nombre"
                    :type="{ 'is-danger': $v.data.first_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.first_name.required &&
                          $v.data.first_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.first_name"></b-input>
                  </b-field>
                  <b-field
                    label="Apellidos"
                    :type="{ 'is-danger': $v.data.last_name.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.last_name.required &&
                          $v.data.last_name.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.last_name"></b-input>
                  </b-field>
                  <b-field label="Fecha de nacimiento">
                    <b-datepicker
                      v-model="birth_date"
                      placeholder="Seleccione una fecha"
                      size="is-regular"
                      locale="es-PE"
                      icon="calendar-day"
                      :max-date="new Date()"
                    ></b-datepicker>
                  </b-field>
                  <b-field
                    label="Email"
                    :type="{ 'is-danger': $v.data.email.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.email.required && $v.data.email.$error,
                        'Email inválido':
                          !$v.data.email.email && $v.data.email.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.email"></b-input>
                  </b-field>
                  <b-field
                    label="Teléfono"
                    :type="{ 'is-danger': $v.data.phone.$error }"
                    :message="[
                      {
                        'Campo requerido':
                          !$v.data.phone.required && $v.data.phone.$error
                      }
                    ]"
                  >
                    <b-input v-model="data.phone"></b-input>
                  </b-field>
                  <b-field label="Activo">
                    <b-switch
                      v-model="data.is_active"
                      type="is-success"
                    ></b-switch>
                  </b-field>
                  <br />
                  <hr />
                  <div class="has-text-right mt-6">
                    <b-button
                      type="is-dark"
                      size="is-small"
                      @click="activeTab = 0"
                    >
                      <font-awesome-icon
                        class="m3-1"
                        :icon="['fas', 'arrow-left']"
                      />
                      Regresar
                    </b-button>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          variant="main"
          :spinner="loading"
          @click.native="createClient()"
          block
          :disabled="activeTab === 0"
          >Guardar</Button
        >
        <Button
          tag="router-link"
          :to="{ name: 'puntos-clientes' }"
          variant="gray"
          block
          class="mt-2"
          >Cancelar</Button
        >
      </Aside>
    </div>
  </Layout>
</template>

<script>
// @ is an alias to /src
import { required, email, numeric } from "vuelidate/lib/validators";
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import ClientPointService from "@/services/puntos/ClientPointService";
import formatDate from "@/mixins/formatDate";
export default {
  name: "CompanyInfo",
  components: {
    Layout,
    Card,
    Aside,
    Button
  },
  mixins: [formatDate],
  data() {
    return {
      activeTab: 0,
      data: {
        is_active: true,
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        num_document: "",
        birth_date: null
      },
      formData: new FormData(),
      loading: false,
      birth_date: null
    };
  },
  validations: {
    data: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      phone: { required },
      num_document: { required, numeric }
    }
  },
  watch: {
    birth_date: function(val) {
      this.data.birth_date = this.formatDateToString(val);
    }
  },
  methods: {
    showAlert(type, msg) {
      this.$store.commit("settings/SET_ALERT", {
        isAlert: true,
        type,
        msg
      });
    },
    async createClient() {
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await ClientPointService.postClient(this.data);
          this.loading = false;
          this.showAlert("S", "Se guardó correctamente.");
          this.$router.push({ name: "puntos-clientes" });
        } catch (error) {
          this.loading = false;
          this.showAlert("D", "Ocurrió un error.");
        }
      } else {
        this.loading = false;
        this.showAlert("D", "Complete los campos requeridos.");
      }
    },
    async validateDocument() {
      this.$v.data.num_document.$touch();
      if (this.$v.data.num_document.$invalid) {
        this.showAlert("D", "Complete los campos requeridos.");
        return;
      }
      try {
        this.loading = true;
        const { data } = await ClientPointService.validateDocument(
          this.data.num_document
        );
        if (Object.keys(data).length > 0) {
          this.data = data;
          if (this.data.birth_date) {
            this.birth_date = this.formatStringToDate(this.data.birth_date);
          }
          this.$buefy.toast.open({
            message: "N° Documento ya se encuentra registrado.",
            type: "is-success"
          });
        } else {
          this.$buefy.toast.open({
            message:
              "N° Documento no registrado, complete los siguientes datos.",
            type: "is-warning"
          });
        }
        this.activeTab = 1;
      } catch (error) {
        const message = error?.response?.data?.error || "Ocurrió un error.";
        this.showAlert("D", message);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.clients-point span.icon.is-left {
  padding-top: 0.7rem;
}
</style>
