<template>
  <b-modal v-model="modalBilling" :width="468" scroll="keep">
    <div class="card">
      <div class="card-content">
        <h3 class="is-size-4 has-text-weight-bold my-5">
          Adjunta tus facturas
        </h3>
        <hr />
        <small style="opacity:0.8;">
          Las facturas deberán tener un máximo de 7 días de antigüedad desde la
          emisión, de lo contrario no serán reconocidas para pago.
        </small>
        <hr />
        <section>
          <b-field
            label="Número de factura"
            :type="{ 'is-danger': $v.num_invoice.$error }"
            :message="[
              {
                'Requerido. Ingresa el N° Factura':
                  !$v.num_invoice.required && $v.num_invoice.$error
              }
            ]"
          >
            <b-input v-model="num_invoice" />
          </b-field>
          <b-field label="Factura en formato PDF">
            <file
              :file="dataPdf"
              @change="uploadFilePdf"
              formato="application/PDF"
            ></file>
          </b-field>
          <b-field label="Factura en formato XML">
            <file
              :file="dataXml"
              @change="uploadFileXml"
              formato="text/XML"
            ></file>
          </b-field>
        </section>
        <hr />
        <div
          class="is-flex is-justify-content-flex-end w-full is-align-items-center"
        >
          <div>
            <Button variant="gray" @click.native="modalBilling = false"
              >Cancelar</Button
            >
          </div>
          <div class="ml-3">
            <Button
              variant="main"
              :spinner="isLoading"
              @click.native="saveBillingInvoice"
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Button from "@/components/form/Button";
import BillingService from "@/services/validador/BillingService";
import File from "@/components/base/File";
export default {
  props: {
    idBilling: Number,
    numInvoice: String
  },
  components: {
    Button,
    File
  },
  data() {
    return {
      modalBilling: true,
      dataXml: "",
      dataPdf: "",
      formData: new FormData(),
      isLoading: false,
      dataXmlValidate: false,
      dataPdfValidate: false,
      num_invoice: this.numInvoice
    };
  },
  validations: {
    num_invoice: { required }
  },
  watch: {
    modalBilling() {
      this.$emit("hidden");
    }
  },
  methods: {
    uploadFilePdf(pdf) {
      if (pdf) {
        this.formData.append("pdf", pdf);
        this.dataPdfValidate = true;
      }
    },
    uploadFileXml(xml) {
      if (xml) {
        this.formData.append("xml", xml);
        this.dataXmlValidate = true;
      }
    },
    async saveBillingInvoice() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          if (!this.dataPdfValidate || !this.dataXmlValidate) {
            this.$store.commit("settings/SET_ALERT", {
              isAlert: true,
              type: "D",
              msg: "Todos los campos son obligatorios"
            });
            return;
          }
          this.isLoading = true;
          this.formData.append("num_invoice", this.num_invoice);
          await BillingService.putBillingInvoice(this.idBilling, this.formData);
          await this.$emit("reload");
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "S",
            msg: "Se guardó correctamente"
          });
          this.modalBilling = false;
        } catch (error) {
          this.$store.commit("settings/SET_ALERT", {
            isAlert: true,
            type: "D",
            msg: "Ocurrió un error"
          });
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
};
</script>
