import { API_CODIGO as API } from "@/api";
import axios from "axios";
class OrderService {
  getList(params) {
    const response = axios.get(API.order_list, {
      params
    });
    return response;
  }

  getDetail(id) {
    const response = axios.get(API.order_detail(id));
    return response;
  }

  downloadExcel(params) {
    const response = axios.get(API.order_excel, {
      responseType: "blob",
      params
    });
    return response;
  }
}

const instance = new OrderService();
export default instance;
