<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    title: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "label"
    },
    information: {
      type: Array,
      default: () => ["January", "February"]
    },
    values: {
      type: Array,
      default: () => [15, 20]
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: this.information,
        datasets: [
          {
            label: this.label,
            backgroundColor: [
              "#3fb06f",
              "#e04747",
              "#6772e5",
              "#fcd137",
              "#1ca3dd",
              "#ff9f56"
            ],
            data: this.values
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title
        }
      }
    );
  }
};
</script>
