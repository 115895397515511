var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',{staticClass:"is-flex"},[_c('Card',{staticClass:"w-full"},[_c('template',{slot:"header"},[_c('div',[_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v("Mi Empresa")])])]),_c('template',{slot:"content"},[_c('section',[_c('b-tabs',{attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v("Información")])]},proxy:true}])},[_c('div',[_c('b-field',{attrs:{"label":"Nombre Empresa","type":{ 'is-danger': _vm.$v.data.name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.name.required && _vm.$v.data.name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('b-field',{attrs:{"label":"Razón Social","type":{ 'is-danger': _vm.$v.data.business_name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.business_name.required &&
                        _vm.$v.data.business_name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.business_name),callback:function ($$v) {_vm.$set(_vm.data, "business_name", $$v)},expression:"data.business_name"}})],1),_c('b-field',{attrs:{"label":"Teléfono (Opcional)"}},[_c('b-input',{model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1),_c('b-field',{attrs:{"label":"Nombre de la estrella","type":{ 'is-danger': _vm.$v.data.star_name.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.star_name.required &&
                        _vm.$v.data.star_name.$error
                    }
                  ]}},[_c('b-input',{model:{value:(_vm.data.star_name),callback:function ($$v) {_vm.$set(_vm.data, "star_name", $$v)},expression:"data.star_name"}})],1),_c('b-field',{attrs:{"label":"Ícono de puntos","type":{ 'is-danger': _vm.$v.data.star_icon.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.star_icon.required &&
                        _vm.$v.data.star_icon.$error
                    }
                  ]}},[_c('div',{staticClass:"selectStarIcon",class:_vm.$v.data.star_icon.$error ? 'required' : ''},_vm._l((_vm.icons),function(icon){return _c('div',{key:icon,staticClass:"iconSelect",class:_vm.data.star_icon === icon ? 'selected' : '',on:{"click":function($event){_vm.data.star_icon = icon}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', icon]}}),_c('p',[_vm._v(" "+_vm._s(icon === "coins" ? "Moneda" : icon === "trophy" ? "Trofeo" : "Estrella")+" ")])],1)}),0)]),_c('b-field',{attrs:{"label":"Logo","custom-class":_vm.$v.data.logo.$error ? 'has-text-danger' : '',"type":{ 'is-danger': _vm.$v.data.logo.$error },"message":[
                    {
                      'Campo requerido':
                        !_vm.$v.data.logo.required && _vm.$v.data.logo.$error
                    }
                  ]}},[_c('Upload',{class:_vm.$v.data.logo.$error ? 'required' : '',attrs:{"image":_vm.data.logo},on:{"change":_vm.uploadLogo}})],1)],1)])],1)],1)])],2),_c('Aside',[_c('Button',{attrs:{"variant":"main","spinner":_vm.loading,"block":""},nativeOn:{"click":function($event){return _vm.updateInfo()}}},[_vm._v("Guardar")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }