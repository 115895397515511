const BASE_URL = "/regalos";

const routesCodigo = [
  {
    path: `${BASE_URL}/`,
    name: "home",
    meta: { title: "Dashboard", icon: "tachometer-alt", sidebar: true },
    component: () => import("../views/regalos/Home.vue")
  },
  {
    path: `${BASE_URL}/customer`,
    name: "customer_list",
    meta: { title: "Cliente", icon: "user", sidebar: true },
    component: () => import("../views/regalos/customer/CustomerList.vue")
  },
  {
    path: `${BASE_URL}/customer/:id`,
    name: "customer_detail",
    meta: { title: "Detalle Cliente", icon: "user", sidebar: false },
    component: () => import("../views/regalos/customer/CustomerDetail.vue")
  },
  {
    path: `${BASE_URL}/code`,
    name: "code_list",
    meta: { title: "Código", icon: "stream", sidebar: true },
    component: () => import("../views/regalos/code/CodeList.vue")
  },
  {
    path: `${BASE_URL}/code/:id`,
    name: "code_detail",
    meta: { title: "Detalle Código", icon: "stream", sidebar: false },
    component: () => import("../views/regalos/code/CodeDetail.vue")
  },
  {
    path: `${BASE_URL}/orders`,
    name: "order_list",
    meta: { title: "Mis redenciones", icon: "clipboard-list", sidebar: true },
    component: () => import("../views/regalos/orders/OrderList.vue")
  },
  {
    path: `${BASE_URL}/order/:id`,
    name: "order_detail",
    meta: { title: "Mi redención", icon: "clipboard-list", sidebar: false },
    component: () => import("../views/regalos/orders/OrderDetail.vue")
  },
  {
    path: `${BASE_URL}/configuration`,
    name: "configuration",
    meta: { title: "Configuración", icon: "cog", sidebar: true },
    component: () => import("../views/regalos/company/CompanyInfo.vue")
  },
  {
    path: `${BASE_URL}/pages`,
    name: "page_list",
    meta: { title: "Páginas", icon: "copy", sidebar: true },
    component: () => import("../views/regalos/pages/PageList.vue")
  },
  {
    path: `${BASE_URL}/pages/add`,
    name: "page_add",
    meta: { title: "Añadir Página", icon: "copy", sidebar: false },
    component: () => import("../views/regalos/pages/PageCreate.vue")
  },
  {
    path: `${BASE_URL}/pages/:id`,
    name: "page_detail",
    meta: { title: "Detalle Página", icon: "copy", sidebar: false },
    component: () => import("../views/regalos/pages/PageUpdate.vue")
  },
  {
    path: `${BASE_URL}/messages`,
    name: "message_list",
    meta: { title: "Notificaciones", icon: "bell", sidebar: true },
    component: () => import("../views/regalos/messages/MessageList.vue")
  },
  {
    path: `${BASE_URL}/messages/add`,
    name: "message_add",
    meta: { title: "Añadir Notificación", icon: "bell", sidebar: false },
    component: () => import("../views/regalos/messages/MessageCreate.vue")
  }
];

export default routesCodigo;
