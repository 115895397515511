<template>
  <Layout>
    <div class="is-flex orderDetail">
      <Card class="w-full">
        <template slot="header">
          <div>
            <h3 class="is-size-4 has-text-weight-bold">
              Redención N° {{ results.id }}
              <b-tag
                :type="
                  results.status === 'Completado' ? 'is-primary' : 'is-danger'
                "
                class="ml-1"
              >
                {{ results.status }}
              </b-tag>
            </h3>
          </div>
        </template>
        <template slot="content">
          <section v-if="showDetail">
            <b-tabs v-model="activeTab" :animated="false">
              <b-tab-item>
                <template #header>
                  <span>Información</span>
                </template>
                <div class="py-5 m-0">
                  <div class="columns">
                    <strong class="column is-one-quarter">Fecha: </strong>
                    <p class="m-0 column">
                      {{ results.date }}
                    </p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Nombres: </strong>
                    <p class="m-0 column">
                      {{ results.customer.first_name }}
                      {{ results.customer.last_name }}
                    </p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">DNI: </strong>
                    <p class="m-0 column">
                      {{ results.customer.num_document }}
                    </p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Email: </strong>
                    <p class="m-0 column">
                      {{ results.customer.email }}
                    </p>
                  </div>
                </div>
                <hr class="my-5 mx-0" />
                <div class="pt-5">
                  <h4 class="pb-5 is-size-5 has-text-weight-bold">
                    <img
                      class="imgProduct mr-2"
                      :src="results.product_variant.image_url"
                    />
                    {{ results.product_variant.name }}
                  </h4>
                  <div class="columns">
                    <strong class="column is-one-quarter">Descripción: </strong>
                    <div
                      class="m-0 column"
                      v-html="results.product_variant.description"
                    >
                      {{ results.local.address }}
                    </div>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">Local: </strong>
                    <p class="m-0 column">
                      {{ results.company_service.business_name }}
                    </p>
                  </div>
                  <!-- <div class="columns">
                    <strong class="column is-one-quarter">Dirección: </strong>
                    <p class="m-0 column">
                      {{ results.local.address }}
                    </p>
                  </div> -->
                  <div class="columns">
                    <strong class="column is-one-quarter">
                      Lote de Código:
                    </strong>
                    <p class="m-0 column">
                      {{ results.customer_code.group_code }}
                    </p>
                  </div>
                  <div class="columns">
                    <strong class="column is-one-quarter">
                      Código:
                    </strong>
                    <p class="m-0 column">
                      {{ results.customer_code.code }}
                    </p>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </section>
        </template>
      </Card>
      <Aside>
        <Button
          tag="router-link"
          :to="{ name: 'order_list' }"
          variant="gray"
          block
          class="mt-2"
          >Regresar</Button
        >
      </Aside>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import Card from "@/components/base/Card";
import Aside from "@/components/Aside";
import Button from "@/components/form/Button";
import OrderService from "@/services/OrderService";
export default {
  name: "CodeUpdate",
  components: {
    Layout,
    Card,
    Aside,
    Button
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      results: [],
      codes: [],
      detailModal: false,
      showDetail: false
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      try {
        const response = await OrderService.getDetail(this.$route.params.id);
        this.results = response.data;
        this.showDetail = true;
      } catch (error) {
        this.$router.push({ name: "order_list" });
      }
    }
  }
};
</script>
<style lang="scss">
.orderDetail {
  h4 {
    .imgProduct {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
    display: flex;
    align-items: center;
  }
}
</style>
