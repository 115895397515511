<template>
  <div class="sidebar" :class="isActive ? 'active' : ''">
    <font-awesome-icon
      :icon="['fas', 'bars']"
      class="burger"
      @click="openMenu"
    />
    <div class="sidebar__logo">
      <img class="large" src="/logo.png" alt="Logo Weeare" />
      <img class="small" src="/logo-small.png" alt="Logo Weeare" />
    </div>
    <nav
      class="sidebar__menu"
      @mouseover="isHover ? (isActive = true) : ''"
      @mouseleave="isHover ? (isActive = false) : ''"
    >
      <div v-if="currentRole === ROLES.COMPANY_GROUP">
        <!-- Vistas del codigo -->
        <b-select
          v-model="subRole"
          expanded
          size="is-small"
          class="mb-4 mr-5"
          @input="changeSubRole"
        >
          <option
            v-if="roles.includes(ROLES.CLIENT_CODE)"
            :value="ROLES.CLIENT_CODE"
          >
            Regalos
          </option>
          <option
            v-if="roles.includes(ROLES.COMPANY_POINT)"
            :value="ROLES.COMPANY_POINT"
          >
            Puntos
          </option>
        </b-select>
        <template v-if="currentSubRole === ROLES.CLIENT_CODE">
          <router-link
            v-for="r in routesCodigo"
            :key="r.name"
            :to="{ name: r.name }"
          >
            <div class="sidebar__menu__item">
              <div class="sidebar__menu__content">
                <font-awesome-icon
                  :icon="['fas', r.icon]"
                  class="sidebar__menu__item__icon"
                />
                <div class="sidebar__menu__item__name">
                  {{ r.title }}
                </div>
              </div>
            </div>
          </router-link>
          <div class="mb-5" />
        </template>
        <template v-if="currentSubRole === ROLES.COMPANY_POINT">
          <component
            v-for="r in routesPuntos"
            :is="r.children ? 'div' : 'router-link'"
            :key="r.name"
            :to="{ name: r.name }"
          >
            <div class="sidebar__menu__item">
              <div class="sidebar__menu__content">
                <font-awesome-icon
                  :icon="['fas', r.icon]"
                  class="sidebar__menu__item__icon"
                />
                <div class="sidebar__menu__item__name">
                  {{ r.title }}
                </div>
              </div>
              <div v-if="r.children" class="sidebar__sub__menu__item">
                <div
                  v-for="c in r.children"
                  :key="c.name"
                  class="sidebar__children"
                >
                  <router-link :to="{ name: c.name }">
                    {{ c.title }}
                  </router-link>
                </div>
              </div>
            </div>
          </component>
          <div class="mb-5" />
        </template>
      </div>
      <div v-else>
        <!-- Vistas del validador -->
        <template v-if="roles.includes(ROLES.SERVICE_USER)">
          <router-link
            v-for="r in routesValidador"
            :key="r.name"
            :to="{ name: r.name }"
          >
            <div class="sidebar__menu__item">
              <div class="sidebar__menu__content">
                <font-awesome-icon
                  :icon="['fas', r.icon]"
                  class="sidebar__menu__item__icon"
                />
                <div class="sidebar__menu__item__name">
                  {{ r.title }}
                </div>
              </div>
            </div>
          </router-link>
          <div class="mb-5" />
        </template>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { ROLES } from "@/utils/constants/";
import routesValidador from "@/router/validador.router";
import routesCodigo from "@/router/codigo.router";
import routesPuntos from "@/router/puntos.router";
export default {
  name: "SidebarMenu",
  data() {
    return {
      menuActive: "",
      isActive: false,
      isHover: true,
      showValidador: false,
      showCodigo: false,
      showAdmin: false,
      routesCodigo: [],
      routesValidador: [],
      routesPuntos: [],
      ROLES: ROLES
    };
  },
  mounted() {
    if (localStorage.getItem("menu") === "true") {
      this.isActive = true;
      this.isHover = false;
    }

    routesValidador.forEach(({ name, meta }) => {
      if (meta.sidebar) {
        this.routesValidador.push({
          name,
          icon: meta.icon,
          title: meta.title
        });
      }
    });

    routesCodigo.forEach(({ name, meta }) => {
      if (meta.sidebar) {
        this.routesCodigo.push({
          name,
          icon: meta.icon,
          title: meta.title
        });
      }
    });

    routesPuntos.forEach(({ name, meta, children }) => {
      if (meta.sidebar) {
        const newRoute = {
          name,
          icon: meta.icon,
          title: meta.title
        };
        if (meta.hasChildren) {
          newRoute.children = [];
          children.forEach(({ name, meta }) => {
            if (meta.sidebar) {
              newRoute.children.push({
                name,
                icon: meta.icon,
                title: meta.title
              });
            }
          });
        }
        this.routesPuntos.push(newRoute);
      }
    });
  },
  computed: {
    ...mapState("settings", ["roles", "currentRole", "currentSubRole"]),
    subRole: {
      get() {
        return this.currentSubRole;
      },
      set(value) {
        this.SET_CURRENT_SUB_ROLE(value);
      }
    }
  },
  methods: {
    ...mapMutations("settings", ["SET_CURRENT_SUB_ROLE"]),
    openMenu() {
      this.isActive = !this.isActive;
      this.isHover = !this.isHover;
      if (localStorage.getItem("menu") === "true") {
        localStorage.setItem("menu", false);
      } else {
        localStorage.setItem("menu", true);
      }
    },
    changeSubRole(value) {
      if (value === ROLES.CLIENT_CODE) {
        this.$router.push({ name: "home" });
      } else {
        this.$router.push({ name: "puntos-configuracion" });
      }
    }
  }
};
</script>

<style lang="scss">
.sidebar {
  padding-top: 30px;
  position: relative;
  width: 60px;
  transition: width linear 0.1s;
  .burger {
    position: absolute;
    right: -1.4rem;
    font-size: 20px;
    cursor: pointer;
    top: 1.85rem;
    z-index: 2;
  }
  &__logo {
    overflow: hidden;
    height: 20px;
    .large {
      display: none;
      min-width: 141px;
    }
    .small {
      display: block;
      height: 20px;
    }
  }
  &__children {
    margin-left: 38px;
    font-size: 16px;
    font-family: $font-medium;
    color: $color-submenu;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: $color-text;
    }
  }
  &__sub__menu__item {
    opacity: 0;
  }
  &__menu {
    margin-top: 30px;
    &__content {
      display: flex;
      align-items: center;
    }
    &__item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10px;
      cursor: pointer;
      width: 200px;
      $root: &;
      &__name {
        font-family: $font-medium;
        font-size: 16px;
        color: $color-text-opacity;
        transition: 0.2s ease all;
        white-space: nowrap;
        opacity: 0;
      }
      &__icon {
        color: $color-text-opacity;
        transition: 0.2s ease all;
        padding: 7px;
        width: 34px !important;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }
      &:hover {
        #{$root}__name {
          color: $color-text;
        }
        #{$root}__icon {
          color: $color-text;
        }
      }
      &--active {
        #{$root}__name {
          color: $color-main;
        }
        #{$root}__icon {
          color: $color-main;
          background: rgba($color-text-opacity, 0.1);
        }
        &:hover {
          #{$root}__name {
            color: $color-main;
          }
          #{$root}__icon {
            color: $color-main;
          }
        }
      }
    }
  }
  &__submenu {
    margin-left: 8px;
    margin-top: 10px;
    border-left: 1.5px solid rgba(34, 43, 59, 0.1);
    padding-left: 27px;
    &__item {
      color: $color-submenu;
      padding: 4px;
      font-size: 14px;
      font-family: $font-medium;
      &:hover {
        color: $color-text;
      }
    }
  }
  a {
    color: $color-submenu;
    &:hover {
      color: $color-text;
    }
  }
  .router-link-exact-active div,
  .router-link-exact-active svg,
  .router-link-exact-active {
    color: $color-main;
  }
}
.active {
  width: 240px;
  .sidebar__logo {
    .large {
      display: block;
      width: 141px;
    }
    .small {
      display: none;
      height: 20px;
    }
  }
  .sidebar__menu {
    &__item {
      &__name {
        opacity: 1;
      }
    }
  }
  .sidebar__sub__menu__item {
    opacity: 1;
  }
}
</style>
